import queryString from 'query-string';
import pickBy from 'lodash/pickBy';
import { isArray } from 'lodash';
import { types as sdkTypes } from './sdkLoader';
import { Listing } from '../types/sharetribe/listing';

const { LatLng, LatLngBounds } = sdkTypes;

export const LISTING_PAGE_PENDING_APPROVAL_VARIANT = 'pending-approval';
export const LISTING_PAGE_DRAFT_VARIANT = 'draft';

export enum ListingPageParamType {
  New = 'new',
  Draft = 'draft',
  Edit = 'edit',
}

export enum ModalParams {
  MobileMenu = 'mobilemenu',
  MobileShoppingBag = 'mobileshoppingbag',
}

// Create slug from random texts
// From Gist thread: https://gist.github.com/mathewbyrne/1280286
export const createSlug = (str: string): string => {
  let text = str.toString().toLowerCase().trim();

  const sets = [
    { to: 'a', from: 'ÀÁÂÃÄÅÆĀĂĄẠẢẤẦẨẪẬẮẰẲẴẶ' },
    { to: 'c', from: 'ÇĆĈČ' },
    { to: 'd', from: 'ÐĎĐÞ' },
    { to: 'e', from: 'ÈÉÊËĒĔĖĘĚẸẺẼẾỀỂỄỆ' },
    { to: 'g', from: 'ĜĞĢǴ' },
    { to: 'h', from: 'ĤḦ' },
    { to: 'i', from: 'ÌÍÎÏĨĪĮİỈỊ' },
    { to: 'j', from: 'Ĵ' },
    { to: 'ij', from: 'Ĳ' },
    { to: 'k', from: 'Ķ' },
    { to: 'l', from: 'ĹĻĽŁ' },
    { to: 'm', from: 'Ḿ' },
    { to: 'n', from: 'ÑŃŅŇ' },
    { to: 'o', from: 'ÒÓÔÕÖØŌŎŐỌỎỐỒỔỖỘỚỜỞỠỢǪǬƠ' },
    { to: 'oe', from: 'Œ' },
    { to: 'p', from: 'ṕ' },
    { to: 'r', from: 'ŔŖŘ' },
    { to: 's', from: 'ßŚŜŞŠ' },
    { to: 't', from: 'ŢŤ' },
    { to: 'u', from: 'ÙÚÛÜŨŪŬŮŰŲỤỦỨỪỬỮỰƯ' },
    { to: 'w', from: 'ẂŴẀẄ' },
    { to: 'x', from: 'ẍ' },
    { to: 'y', from: 'ÝŶŸỲỴỶỸ' },
    { to: 'z', from: 'ŹŻŽ' },
    { to: '-', from: "·/_,:;'" },
  ];

  sets.forEach((set) => {
    text = text.replace(new RegExp(`[${set.from}]`, 'gi'), set.to);
  });

  const slug = encodeURIComponent(
    text
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(/[^\w-]+/g, '') // Remove all non-word chars
      .replace(/--+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, '') // Trim - from end of text
  );

  return slug.length > 0 ? slug : 'no-slug';
};

/**
 * Parse float from a string
 *
 * @param {String} str - string to parse
 *
 * @return {Number|null} number parsed from the string, null if not a number
 */
export const parseFloatNum = (str: string | (string | null)[] | null): number | null => {
  if (!str || isArray(str)) return null;

  const trimmed = str.trim();
  if (!trimmed) return null;

  const num = parseFloat(trimmed);
  const isNumber = !Number.isNaN(num);
  const isFullyParsedNum = isNumber && num.toString() === trimmed;
  return isFullyParsedNum ? num : null;
};

/**
 * Encode a location to use in a URL
 *
 * @param {LatLng} location - location instance to encode
 *
 * @return {String} location coordinates separated by a comma
 */
export const encodeLatLng = (location: typeof LatLng): string => `${location.lat},${location.lng}`;

/**
 * Decode a location from a string
 *
 * @param {String} str - string encoded with `encodeLatLng`
 *
 * @return {LatLng|null} location instance, null if could not parse
 */
export const decodeLatLng = (str: string): typeof LatLng | null => {
  const parts = str.split(',');
  if (parts.length !== 2) {
    return null;
  }
  const lat = parseFloatNum(parts[0]);
  const lng = parseFloatNum(parts[1]);
  if (lat === null || lng === null) {
    return null;
  }
  return new LatLng(lat, lng);
};

/**
 * Encode a location bounds to use in a URL
 *
 * @param {LatLngBounds} bounds - bounds instance to encode
 *
 * @return {String} bounds coordinates separated by a comma
 */
export const encodeLatLngBounds = (bounds: typeof LatLngBounds): string =>
  `${encodeLatLng(bounds.ne)},${encodeLatLng(bounds.sw)}`;

/**
 * Decode a location bounds from a string
 *
 * @param {String} str - string encoded with `encodeLatLngBounds`
 *
 * @return {LatLngBounds|null} location bounds instance, null if could not parse
 */
export const decodeLatLngBounds = (str: string): typeof LatLngBounds | null => {
  const parts = str.split(',');
  if (parts.length !== 4) {
    return null;
  }
  const ne = decodeLatLng(`${parts[0]},${parts[1]}`);
  const sw = decodeLatLng(`${parts[2]},${parts[3]}`);
  if (ne === null || sw === null) {
    return null;
  }
  return new LatLngBounds(ne, sw);
};

// Serialise SDK types in given object values into strings
const serialiseSdkTypes = (obj: any) =>
  Object.keys(obj).reduce((result: any, key: any) => {
    const val = obj[key];
    /* eslint-disable no-param-reassign */
    if (val instanceof LatLngBounds) {
      result[key] = encodeLatLngBounds(val);
    } else if (val instanceof LatLng) {
      result[key] = encodeLatLng(val);
    } else {
      result[key] = val;
    }
    /* eslint-enable no-param-reassign */
    return result;
  }, {});

/**
 * Serialise given object into a string that can be used in a
 * URL. Encode SDK types into a format that can be parsed with `parse`
 * defined below.
 *
 * @param {Object} params - object with strings/numbers/booleans or
 * SDK types as values
 *
 * @return {String} query string with sorted keys and serialised
 * values, `undefined` and `null` values are removed
 */
export const stringify = (params: any): string => {
  const serialised = serialiseSdkTypes(params);
  const cleaned = Object.keys(serialised).reduce((result: any, key: any) => {
    const val = serialised[key];
    /* eslint-disable no-param-reassign */
    if (val !== null) {
      result[key] = val;
    }
    /* eslint-enable no-param-reassign */
    return result;
  }, {});
  return queryString.stringify(cleaned);
};

/**
 * Parse a URL search query. Converts numeric values into numbers and
 * 'true' and 'false' as booleans.
 *
 * @param {String} search - query string to parse, optionally with a
 * leading '?' or '#' character
 *
 * @return {Object} key/value pairs parsed from the given String
 */
export const parse = (search: string) => {
  const params = queryString.parse(search);
  return Object.keys(params).reduce((result: any, key: any) => {
    const val = params[key];
    if (val === 'true') {
      // eslint-disable-next-line no-param-reassign
      result[key] = true;
    } else if (val === 'false') {
      // eslint-disable-next-line no-param-reassign
      result[key] = false;
    } else {
      const num = parseFloatNum(val);
      // eslint-disable-next-line no-param-reassign
      result[key] = num === null ? val : num;
    }
    return result;
  }, {});
};

/**
 * Create Twitter page url from twitterHandle
 *
 * @param {String} twitterHandle - handle is used for generating Twitter page URL
 *
 * @return {String} twitterPageURL
 */
export const twitterPageURL = (twitterHandle: string): string | null => {
  if (twitterHandle && twitterHandle.charAt(0) === '@') {
    return `https://twitter.com/${twitterHandle.substring(1)}`;
  }
  if (twitterHandle) {
    return `https://twitter.com/${twitterHandle}`;
  }
  return null;
};

/**
  Parse verification token from URL

  Returns stringified token, if the token is provided.

  Returns `null` if verification token is not provided.

  Please note that we need to explicitely stringify the token, because
  the unwanted result of the `parse` method is that it automatically
  parses the token to number.
*/
export const parseVerificationToken = (search: string): string | null => {
  const urlParams = parse(search);
  const verificationToken = urlParams.t;

  if (verificationToken) {
    return `${verificationToken}`;
  }

  return null;
};

// Preserve these search params when navigating through the app
export const PRESERVED_SEARCH_PARAMS = {
  Preview: 'preview',
  EnabledFeatures: 'enabled_features',
  DisabledFeatures: 'disabled_features',
  Email: 'email',
  EnabledCustomerExperiences: 'enabled_customer_experiences',
  ShopifyEmailOverride: 'shopify_email_override',
};

/**
 *
 * @param {Location} searchParams - Current Search Params
 * @returns {Object} Search params that we should keep when the user navigates through the app
 */
export const getSearchParamsToPreserve = (searchParams: Location) =>
  pickBy(searchParams, (value, key) => Object.values(PRESERVED_SEARCH_PARAMS).includes(key));

/**
 *
 * @param {Location} currentLocation
 * @param {String} newSearchParamsString - Query string that the user is trying to navigate to
 * @returns {String} New query string that combines the search params that we are preserving and
 * the new search params
 */
export const buildNewSearchString = (
  currentLocation: Location,
  newSearchParamsString: string
): string => {
  const currentSearchParams = parse(currentLocation.search);
  const newSearchParams = parse(newSearchParamsString);

  const searchParamsToPreserve = getSearchParamsToPreserve(currentSearchParams);

  return stringify({
    ...searchParamsToPreserve,
    ...newSearchParams,
  });
};

export const getUrlSearchParams = (): URLSearchParams => {
  const search = typeof window !== 'undefined' ? window.location?.search : {};
  return new URLSearchParams(search);
};

export const getUrlHash = (): string | undefined => {
  const hash = typeof window !== 'undefined' ? window.location?.hash : undefined;
  return hash;
};

export const getListingUrl = (canonicalRootUrl: string, listing: Listing): string => {
  const listingId = listing.id.uuid;
  const listingSlug = createSlug(listing.attributes.title);

  return `${canonicalRootUrl}/l/${listingSlug}/${listingId}`;
};
