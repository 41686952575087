export enum FrenzyFacets {
  BrandDirect = 'brand direct',
  Condition = 'condition',
  Size = 'size',
  Style = 'style',
  Category = 'category',
  Color = 'color',
  TreetCategory = 'org category',
  TreetStyle = 'org styles',
  TreetGender = 'org gender',
  TreetInseam = 'org inseam',
  TreetDecade = 'org decade',
}

export type Facet = [string, number];

// Example of a facet field object sent from Frenzy: { "size": ["36", 2] }
// where "size" is the facet name, "36" is the facet value, and 2 represents the # of
// products that have size 36.
export type FacetFields = {
  [key in FrenzyFacets]: Facet[];
};
