import { CancelReason, DisputeReason } from '../types/apollo/generated/types.generated';
import { FilterId, SearchNavBarSections } from '../types/filters/filters';

export const ITEM_AVAILABILITY_AVAILABLE = 'available';
export const ITEM_AVAILABILITY_PURCHASED = 'purchased';

export const SHIPPING_ADDRESS_FIELD_ID = 'CheckoutPageShippingAddress';
export const PAYMENT_ADDRESS_FIELD_ID = 'CheckoutPagePaymentForm';

type ClaimTypeInfo = {
  key: DisputeReason;
  label: string;
};

type TradeInDisputeReason = Extract<
  DisputeReason,
  'DAMAGED_BUT_ACCEPTABLE' | 'INAUTHENTIC_OR_EXCESSIVE_DAMAGE' | 'MISSING' | 'NOT_AS_DESCRIBED'
>;

export const CLAIM_TYPES: ClaimTypeInfo[] = [
  {
    key: DisputeReason.Inauthentic,
    label: 'Item is inauthentic',
  },
  {
    key: DisputeReason.NotAsDescribed,
    label: 'Item is not as described',
  },
  {
    key: DisputeReason.Missing,
    label: 'I never received my item',
  },
  {
    key: DisputeReason.WrongItem,
    label: 'Wrong item received',
  },
  {
    key: DisputeReason.Other,
    label: 'Other',
  },
];

export type TradeInPayoutOption =
  | {
      key: TradeInDisputeReason;
      uniqueKeyOverride?: string;
      label: string;
      payoutFixedAmount: number;
      payoutPercentage?: never;
      payoutPercentageOfOriginalPrice?: never;
    }
  | {
      key: TradeInDisputeReason;
      uniqueKeyOverride?: string;
      label: string;
      payoutPercentage: number;
      payoutFixedAmount?: never;
      payoutPercentageOfOriginalPrice?: never;
    }
  | {
      key: TradeInDisputeReason;
      uniqueKeyOverride?: string;
      label: string;
      payoutPercentage?: never;
      payoutFixedAmount?: never;
      payoutPercentageOfOriginalPrice: number;
    };

type CancelReasonInfo = {
  key: CancelReason;
  label: string;
  descriptionPlaceholder?: string;
};

export const CANCEL_REASON_TYPES: CancelReasonInfo[] = [
  {
    key: CancelReason.SoldElsewhere,
    label: 'Item(s) were sold on another platform',
    descriptionPlaceholder: 'Which platform?',
  },
  {
    key: CancelReason.KeepItem,
    label: 'I decided I wanted to keep the item(s)',
  },
  {
    key: CancelReason.Other,
    label: 'Other',
    descriptionPlaceholder: 'What is the reason for cancellation?',
  },
];

export enum PayoutOptions {
  Cash = 'cash',
  Credit = 'credit',
}

export const ADDRESS_FORM_TYPE_SHIPPING = 'shipping';
export const ADDRESS_FORM_TYPE_SHIP_FROM = 'shipFrom';
export const ADDRESS_FORM_TYPE_BILLING = 'billing';
export const ADDRESS_FORM_TYPES = [ADDRESS_FORM_TYPE_SHIPPING, ADDRESS_FORM_TYPE_BILLING];

export const GENERIC_ERROR_MESSAGE =
  'Unfortunately, something went wrong. Please contact support@treet.co.';

export const CONDITIONS = {
  NEW_WITH_TAGS: 'new_with_tags',
  NEW_WITHOUT_TAGS: 'new_without_tags',
  MINOR_DEFECT: 'minor_defect',
  EXCELLENT: 'excellent',
  GOOD: 'good',
};

// Conditions that apply to clothes
export const clothesConditions = {
  id: FilterId.Condition,
  label: 'Condition',
  type: 'SelectMultipleFilter',
  group: 'primary',
  queryParamNames: ['pub_condition'],
  searchNavBarSection: SearchNavBarSections.Filter,
  config: {
    options: [
      {
        key: CONDITIONS.NEW_WITH_TAGS,
        label: 'New With Tags',
        description:
          'Your item has never been worn and the original hanging tags are still on. For this option, you’ll need to take pictures of the retail tags.',
      },
      {
        key: CONDITIONS.NEW_WITHOUT_TAGS,
        label: 'New Without Tags',
        description:
          'Your item has never been worn (other than perhaps trying it on), but the tags have been removed. There are no quirks or issues whatsoever.',
      },
      {
        key: CONDITIONS.EXCELLENT,
        label: 'Excellent Condition',
        description:
          'Your item has only been lightly used and has been extremely well maintained. There may be some small signs of wear, but nothing major.',
      },
      {
        key: CONDITIONS.GOOD,
        label: 'Good Condition',
        description:
          'You’ve worn this item and it’s well maintained. There may be some quirks like a small stain, a button missing, or a loose thread, but it’s overall in good shape. You will need to take pictures of any quirks the item has.',
      },
    ],
  },
};

// The maximum width of the card before the normal text size overflows the card width
export const LIST_ITEM_CARD_DEFAULT_OVERFLOW_WIDTH = 150;

// Used for galia lahav
export const dressMeasurements = [
  '23"',
  '23.5"',
  '24"',
  '24.5"',
  '25"',
  '25.5"',
  '26"',
  '26.5"',
  '27"',
  '27.5"',
  '28"',
  '28.5"',
  '29"',
  '29.5"',
  '30"',
  '30.5"',
  '31"',
  '31.5"',
  '32"',
  '32.5"',
  '33"',
  '33.5"',
  '34"',
  '34.5"',
  '35"',
  '35.5"',
  '36"',
  '36.5"',
  '37"',
  '37.5"',
  '38"',
  '38.5"',
  '39"',
  '39.5"',
  '40"',
  '40.5"',
  '41"',
  '41.5"',
  '42"',
  '42.5"',
  '43"',
  '43.5"',
  '44"',
  '44.5"',
  '45"',
  '45.5"',
  '46"',
  '46.5"',
  '47"',
  '47.5"',
  '48"',
  '48.5"',
  '49"',
  '49.5"',
  '50"',
  '50.5"',
  '51"',
  '51.5"',
  '52"',
  '52.5"',
  '53"',
  '53.5"',
  '54"',
  '54.5"',
  '55"',
  '55.5"',
  '56"',
  '56.5"',
  '57"',
  '57.5"',
  '58"',
  '58.5"',
  '59"',
  '59.5"',
  '60"',
  '60.5"',
  '61"',
  '61.5"',
  '62"',
];
