import { Box } from '@material-ui/core';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Divider, IconSecure, LineItemBreakdown, TypographyWrapper } from '../../components';
import CheckoutError from '../../containers/CheckoutPage/CheckoutError';
import { CheckoutPageState } from '../../containers/CheckoutPage/CheckoutPage.duck';
import DiscountCodeInput from '../../containers/CheckoutPage/DiscountCodeInput';
import { defaultTreetStyles } from '../../shopConfig/config';
import { BundleType, Participant } from '../../types/apollo/generated/types.generated';
import { CheckoutLineItem } from '../../types/models/lineItem';
import PaymentFields from './PaymentFields';
import ShippingAddressFields from './ShippingAddressFields';
import css from './StripePaymentFormMobile.module.css';
import { CountryCodeAndName } from '../../util/countryCodes';
import StripeExpressCheckoutSection from './StripeExpressCheckoutSection';
import IncompleteAddressErrorMessage from './IncompleteAddressErrorMessage';

interface StripePaymentFormMobileProps {
  defaultPaymentMethod: any;
  error?: string;
  form: any;
  handleChangePaymentMethod: (paymentMethod: string) => void;
  handleShippingCountryChange: (value: string) => void;
  handleStripeElementRef: (el: any) => void;
  allowedShipToCountryCodes: CountryCodeAndName[];
  isCardValueValid: boolean;
  isLoading: boolean;
  isSubmitting: boolean;
  lineItems: CheckoutLineItem[];
  selectedPaymentMethod: string;
  shouldEnableSeel: boolean;
  isMultiSellerCheckout: boolean;
  isStripeExpressCheckoutEnabled: boolean;
  handleExpressCheckoutButtonClick: () => void;
  onConfirm: () => void;
  onShippingAddressChange: () => void;
  shouldDisplayIncompleteAddressErrorMessage: boolean;
}

const StripePaymentFormMobile: FC<StripePaymentFormMobileProps> = (
  props: StripePaymentFormMobileProps
) => {
  const {
    defaultPaymentMethod,
    error,
    form,
    handleChangePaymentMethod,
    handleShippingCountryChange,
    handleStripeElementRef,
    allowedShipToCountryCodes,
    isCardValueValid,
    isLoading,
    isSubmitting,
    lineItems,
    selectedPaymentMethod,
    shouldEnableSeel,
    isMultiSellerCheckout,
    isStripeExpressCheckoutEnabled,
    handleExpressCheckoutButtonClick,
    onConfirm,
    onShippingAddressChange,
    shouldDisplayIncompleteAddressErrorMessage,
  } = props;

  const { handleCardChargeError } = useSelector<any>((state) => state.stripe) as any;
  const {
    finishCheckoutError,
    initiateCheckoutError,
    submitCheckoutError,
    isExpressCheckoutError,
  } = useSelector<any>((state) => state.CheckoutPage) as CheckoutPageState;

  const hasError =
    handleCardChargeError || finishCheckoutError || initiateCheckoutError || submitCheckoutError;

  return (
    <>
      <div className={css.shipping}>
        {!isMultiSellerCheckout && (
          <StripeExpressCheckoutSection
            isStripeExpressCheckoutEnabled={isStripeExpressCheckoutEnabled}
            handleExpressCheckoutButtonClick={handleExpressCheckoutButtonClick}
            onConfirm={onConfirm}
            onShippingAddressChange={onShippingAddressChange}
            isMultiSellerCheckout={false}
          />
        )}

        <TypographyWrapper variant="h2">Ship To</TypographyWrapper>
        {shouldDisplayIncompleteAddressErrorMessage && <IncompleteAddressErrorMessage />}
        <ShippingAddressFields
          form={form}
          allowedShipToCountryCodes={allowedShipToCountryCodes}
          handleShippingCountryChange={handleShippingCountryChange}
        />
        {isMultiSellerCheckout && (
          <StripeExpressCheckoutSection
            isStripeExpressCheckoutEnabled={isStripeExpressCheckoutEnabled}
            handleExpressCheckoutButtonClick={handleExpressCheckoutButtonClick}
            onConfirm={onConfirm}
            onShippingAddressChange={onShippingAddressChange}
            isMultiSellerCheckout
          />
        )}
      </div>
      {shouldEnableSeel && (
        <Box mb={1} px={2}>
          <TypographyWrapper variant="body1">
            <div id="seel-ra-widget-root" />
          </TypographyWrapper>
        </Box>
      )}
      <div className={css.payment}>
        <Box display="flex" flexDirection="row">
          <TypographyWrapper variant="h2">Checkout With Card</TypographyWrapper>
          <Box mx={1}>
            <IconSecure width="22" height="22" />
          </Box>
        </Box>
        <PaymentFields
          defaultPaymentMethod={defaultPaymentMethod}
          error={error}
          handleChangePaymentMethod={handleChangePaymentMethod}
          handleStripeElementRef={handleStripeElementRef}
          isCardValueValid={isCardValueValid}
          isLoading={isLoading}
          isSubmitting={isSubmitting}
          selectedPaymentMethod={selectedPaymentMethod}
        />
      </div>
      <Divider className={css.divider} color={defaultTreetStyles.gray20} />
      <DiscountCodeInput />
      <Divider className={css.divider} />
      <LineItemBreakdown
        lineItems={lineItems}
        participant={Participant.Buyer}
        bundleType={BundleType.Marketplace}
        shouldCombineItemsAndShipping
      />
      <Divider className={css.divider} />
      {hasError && !isExpressCheckoutError && (
        <div className={css.error}>
          <TypographyWrapper
            variant="body1"
            typographyOverrides={{ style: { color: defaultTreetStyles.red80 } }}
          >
            <CheckoutError />
          </TypographyWrapper>
        </div>
      )}
    </>
  );
};

export default StripePaymentFormMobile;
