import { Box, Grid } from '@material-ui/core';
import classNames from 'classnames';
import isNil from 'lodash/isNil';
import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { compact } from 'lodash';
import {
  ConditionalWrapper,
  InlineTextButton,
  ListingImage,
  NamedLink,
  TypographyWrapper,
} from '..';
import { logSelectItem } from '../../analytics/ga4analytics';
import {
  getRecommendedPriceForCondition,
  ListingPageParamTab,
} from '../../containers/EditListingPage/EditListingPage.utils';
import {
  createListingDraftFromExistingListing,
  fetchOwnListing,
  updateListing,
} from '../../containers/ManagePurchasesPage/ManagePurchasesPage.duck';
import { useAppDispatch } from '../../hooks/appDispatch';
import { useShopConfig } from '../../hooks/shopConfig';
import { useBrandCountryConfig } from '../../hooks/useCountryConfig';
import { useEnabledCustomerExperiences } from '../../hooks/useEnabledCustomerExperiences';
import { useListingFlowConfig } from '../../hooks/useListingFlowConfig';
import { useRouteConfiguration } from '../../hooks/useRouteConfiguration';
import { useCurrentUserPermissions } from '../../hooks/useUserPermissions';
import { CoreBundleItem, Participant } from '../../types/apollo/generated/types.generated';
import { CheckoutLineItem } from '../../types/models/lineItem';
import { Listing, ListingItemType, ListingWithImages } from '../../types/sharetribe/listing';
import { getSharetribeAddressFromShippoAddress } from '../../util/address';
import { ITEM_AVAILABILITY_AVAILABLE } from '../../util/constants';
import { convertNumberToMoney, formatDollarAmountAsCurrency } from '../../util/currency';
import { createListingURL, FindItemMethod } from '../../util/listings/listing';
import { buildDraftListingPayloadFromExistingListing } from '../../util/listings/listingPayload';
import { createResourceLocatorString } from '../../util/routes';
import { createSlug, ListingPageParamType } from '../../util/urlHelpers';
import { useTradeInConfig } from '../../hooks/useTradeInConfig';
import IconRelistFilled from '../Icons/IconRelist/IconRelistFilled';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { useCashPayoutPercentage } from '../../hooks/useCashPayoutPercentage';
import css from './ItemizedListing.module.css';
import { useCreditPayoutPercentage } from '../../hooks/useCreditPayoutPercentage';
import CreditBoostChip from '../CreditBoostChip/CreditBoostChip';

interface TradeInCreditInfoProps {
  creditPayoutFixedAmount?: number;
  creditPayoutPercentage?: number;
  disputeDescription?: string | null;
  hideDollarAmounts?: boolean;
}

const TradeInCreditInfo: FC<TradeInCreditInfoProps> = (props) => {
  const { creditPayoutFixedAmount, creditPayoutPercentage, disputeDescription, hideDollarAmounts } =
    props;

  const intl = useIntl();
  const { currencyConfig } = useBrandCountryConfig();

  const formattedCredit =
    !isNil(creditPayoutFixedAmount) &&
    intl.formatNumber(creditPayoutFixedAmount / 100, currencyConfig);

  return (
    <>
      {!isNil(creditPayoutFixedAmount) && !hideDollarAmounts && (
        <h4>
          <TypographyWrapper variant="body1">
            {`Credit Earned: ${formattedCredit}`}
          </TypographyWrapper>
        </h4>
      )}
      {!isNil(creditPayoutPercentage) && (
        <h4>
          <TypographyWrapper variant="body1">
            {`Credit Earned: ${creditPayoutPercentage * 100}%`}
          </TypographyWrapper>
        </h4>
      )}
      {!isNil(disputeDescription) && (
        <h4>
          <TypographyWrapper variant="body1">Notes: {disputeDescription}</TypographyWrapper>
        </h4>
      )}
    </>
  );
};

interface ItemizedListingProps {
  listing: ListingWithImages;
  referrerForLogging: string;
  lineItem?: CheckoutLineItem;
  actionEl?: React.ReactElement;
  isDisputed?: boolean;
  isCanceled?: boolean;
  isVerified?: boolean;
  participant?: Participant;
  bundleItem?: CoreBundleItem;
}

const ItemizedListing: FC<ItemizedListingProps> = (props: ItemizedListingProps) => {
  const {
    listing,
    lineItem,
    actionEl,
    isDisputed = false,
    isCanceled = false,
    isVerified = false,
    referrerForLogging,
    participant,
    bundleItem,
  } = props;

  const intl = useIntl();
  const dispatch = useAppDispatch();
  const routes = useRouteConfiguration();
  const { currencyConfig } = useBrandCountryConfig();
  const {
    sizeVariantOptionName,
    additionalPayoutSettings,
    shopName,
    relistPurchasedListingActionText,
  } = useShopConfig();
  const tradeInConfig = useTradeInConfig();
  const { cashPayoutPercentageForTradeInResale } = tradeInConfig;
  const { conditionToRecommendedDiscount, pricingRestrictions } = useListingFlowConfig();
  const { isBrand, isTradeInAdmin, isTreet } = useCurrentUserPermissions();
  const { allowBuy, allowSell } = useEnabledCustomerExperiences();
  const cashPayoutPercentage = useCashPayoutPercentage();
  const creditPayoutPercentageConfig = useCreditPayoutPercentage(); // Only used to populate ReTreeted listing.
  const isPurchases = participant === Participant.Buyer;
  const isSales = participant === Participant.Seller;

  const { shopifyProductVariant, internalBrandNotes, payoutInfo, listingItemType, findItemMethod } =
    listing.attributes.publicData || {};
  const { creditPayoutFixedAmount, creditPayoutPercentage, tradeInCreditPayoutBundleAmount } =
    payoutInfo || {};
  const disputeDescription = bundleItem?.dispute?.note;
  const size = listing.attributes.publicData?.[sizeVariantOptionName];
  const condition = listing.attributes.publicData?.condition;

  const isTradeInItem = listingItemType === ListingItemType.TradeIn;
  const isMarketplaceItem = listingItemType === ListingItemType.Marketplace;
  const wasCreditIssued =
    !!creditPayoutFixedAmount ||
    !!creditPayoutPercentage ||
    // tradeInCreditPayoutBundleAmount can be set to $0 for consigned items, e.g. for tenable6.
    !isNil(tradeInCreditPayoutBundleAmount);
  // Do not allow re-selling item that was listed as a non-Treet site brand
  const isListAnyBrandItem = findItemMethod === FindItemMethod.ListAnyBrand;
  const canRelistTradeInItem = isTradeInItem && isTradeInAdmin && isVerified && wasCreditIssued;
  const canRelistMarketplaceItem = isMarketplaceItem && allowSell && isVerified;
  const canCreateResaleDraftListing =
    isPurchases &&
    allowBuy &&
    !isListAnyBrandItem &&
    (canRelistTradeInItem || canRelistMarketplaceItem);
  const createDraftListingActionCopy = relistPurchasedListingActionText || 'Relist Item';
  const viewCreatedListingActionCopy = (createdListing: Listing) =>
    createdListing.attributes.state === LISTING_STATE_DRAFT
      ? 'Complete Draft Relisting'
      : 'View Relisted Item';

  const [resaleListing, setResaleListing] = useState<Listing | null>();

  useEffect(() => {
    const fetchListings = async () => {
      const createdListingIds = listing.attributes.publicData?.createdListingId?.split(',');
      if (!createdListingIds) return;
      const listings = await Promise.all(
        createdListingIds.map(async (listingId: string) => dispatch(fetchOwnListing(listingId)))
      );
      setResaleListing(compact(listings)[0] as Listing);
    };

    fetchListings();
  }, []);

  // Dollar amts will be $0 if the brand is paying out a flat amount per trade-in bundle.
  const shouldHideLineItemTotals =
    isTradeInItem && !isNil(additionalPayoutSettings?.tradeInCreditPayoutBundleAmount);

  const getCreateListingDraftParams = () => {
    const relistedTradeInConditionToRecommendedDiscount =
      tradeInConfig?.relistedConditionToRecommendedDiscount;
    const recommendedPrice = getRecommendedPriceForCondition(
      convertNumberToMoney(listing.attributes.publicData.originalPrice, currencyConfig.currency),
      listing.attributes.publicData.condition,
      relistedTradeInConditionToRecommendedDiscount || conditionToRecommendedDiscount,
      pricingRestrictions,
      listing.attributes.publicData.tags
    );

    const defaultParams = {
      listing,
      shopName,
      price: listing.attributes.price,
      availability: ITEM_AVAILABILITY_AVAILABLE,
      listingItemType: ListingItemType.Marketplace,
      cashPayoutPercentage,
      creditPayoutPercentage: creditPayoutPercentageConfig,
      isBrand: isBrand && !isTreet,
      sourceListingId: listing.id.uuid,
      findItemMethod: FindItemMethod.RelistFromTreet,
    };
    const tradeInItemParams = {
      ...defaultParams,
      price: recommendedPrice
        ? convertNumberToMoney(recommendedPrice, currencyConfig.currency)
        : undefined,
      cashPayoutPercentage: cashPayoutPercentageForTradeInResale || cashPayoutPercentage,
      findItemMethod: FindItemMethod.TradeInUpload,
      // By default, assume relisted trade-in items will ship from the same location customers ship to.
      shipFromAddress: tradeInConfig?.tradeInShipToAddress
        ? getSharetribeAddressFromShippoAddress(tradeInConfig?.tradeInShipToAddress)
        : undefined,
    };
    return isTradeInItem ? tradeInItemParams : defaultParams;
  };

  const onViewResaleListing = async () => {
    // Open up a new (blank) tab immediately and then fill it with content later. Otherwise the
    // new tab opening could be considered a pop-up (e.g. non user triggered tab opening).
    const listingNewTab = window.open('', '_blank');
    listingNewTab?.document.write('Retrieving listing...');
    if (!resaleListing) return;
    const url = createListingURL(routes, resaleListing);
    if (listingNewTab) {
      listingNewTab.location.href = url;
    }
  };

  const onCreateResaleListing = async () => {
    // Open up a new (blank) tab immediately and then fill it with content later. Otherwise the
    // new tab opening could be considered a pop-up (e.g. non user triggered tab opening).
    const draftListingNewTab = window.open('', '_blank');
    draftListingNewTab?.document.write('Generating listing...');

    const payloadParams = getCreateListingDraftParams();
    const draftListing = await dispatch(
      createListingDraftFromExistingListing(
        buildDraftListingPayloadFromExistingListing(payloadParams)
      )
    );

    setResaleListing(draftListing);

    const { createdListingId } = listing.attributes.publicData;
    const draftListingId = draftListing?.id.uuid;
    const updatedCreatedListingId = createdListingId
      ? `${createdListingId},${draftListingId}`
      : draftListingId;

    // Update originating trade-in listing to link to new marketplace listing.
    dispatch(
      updateListing({
        id: listing.id.uuid,
        createdListingId: updatedCreatedListingId,
      })
    );

    // Open the draft marketplace listing in a new tab
    const to = createResourceLocatorString(
      'EditListingPage',
      routes,
      {
        id: draftListing?.id.uuid,
        type: ListingPageParamType.Draft,
        slug: createSlug(draftListing?.attributes.title || ''),
        tab: ListingPageParamTab.Details,
      },
      {}
    );
    if (draftListingNewTab) {
      draftListingNewTab.location.href = to;
    }
  };

  return (
    <Box
      p={2}
      className={classNames(css.itemizedListing, { [css.disabled]: isDisputed || isCanceled })}
    >
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={3}>
          <ConditionalWrapper
            condition={listingItemType === ListingItemType.Marketplace}
            wrapper={(children) => (
              <NamedLink
                name="ListingPage"
                onClick={() => logSelectItem(listing, referrerForLogging)}
                params={{ id: listing.id.uuid, slug: createSlug(listing.attributes.title) }}
              >
                {children}
              </NamedLink>
            )}
          >
            <ListingImage
              rootClassName={css.listingImage}
              currentListing={listing}
              title={listing.attributes.title}
            />
          </ConditionalWrapper>
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" flexDirection="column" alignSelf="center" ml="8px">
            <ConditionalWrapper
              condition={isMarketplaceItem}
              wrapper={(children) => (
                <NamedLink
                  name="ListingPage"
                  onClick={() => logSelectItem(listing, referrerForLogging)}
                  params={{ id: listing.id.uuid, slug: createSlug(listing.attributes.title) }}
                >
                  {children}
                </NamedLink>
              )}
            >
              <TypographyWrapper
                variant="body1"
                typographyOverrides={{
                  style: {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    fontWeight: 'bold',
                  },
                }}
              >
                {listing.attributes.title}
              </TypographyWrapper>
            </ConditionalWrapper>
            {size && <TypographyWrapper variant="body1">Size: {size}</TypographyWrapper>}
            {isBrand && shopifyProductVariant?.sku && (
              <TypographyWrapper variant="body1">
                SKU: {shopifyProductVariant.sku}
              </TypographyWrapper>
            )}
            {!isBrand && isMarketplaceItem && isSales && !isCanceled && !isDisputed && (
              <CreditBoostChip listing={listing} />
            )}
            {isTradeInAdmin && condition && (
              <TypographyWrapper variant="body1">Condition: {condition}</TypographyWrapper>
            )}
            {isBrand && internalBrandNotes && (
              <TypographyWrapper variant="body1">{internalBrandNotes}</TypographyWrapper>
            )}
            {isTradeInItem && isVerified && (
              <TradeInCreditInfo
                hideDollarAmounts={shouldHideLineItemTotals}
                creditPayoutFixedAmount={creditPayoutFixedAmount}
                creditPayoutPercentage={creditPayoutPercentage}
                disputeDescription={disputeDescription}
              />
            )}
            {isDisputed && <TypographyWrapper variant="body1">Disputed</TypographyWrapper>}
            {isCanceled && <TypographyWrapper variant="body1">Canceled</TypographyWrapper>}
            {canCreateResaleDraftListing && (
              <InlineTextButton
                onClick={resaleListing ? onViewResaleListing : onCreateResaleListing}
              >
                <Box display="flex" alignItems="center" justifyContent="center" mt={1}>
                  <IconRelistFilled className={css.icon} />
                  <Box
                    title={
                      resaleListing
                        ? 'View Listing Page for Relisted Item'
                        : 'List this item for sale'
                    }
                    style={{ textDecoration: 'underline', textUnderlineOffset: '2px' }}
                    ml="2px"
                  >
                    <TypographyWrapper variant="body1">
                      {resaleListing
                        ? viewCreatedListingActionCopy(resaleListing)
                        : createDraftListingActionCopy}
                    </TypographyWrapper>
                  </Box>
                </Box>
              </InlineTextButton>
            )}
          </Box>
        </Grid>
        <Grid item xs={3} alignContent="flex-end">
          <Box display="flex" flexDirection="row" justifyContent="flex-end">
            {!shouldHideLineItemTotals && lineItem?.listingLineItem && (
              <h4 className={css.listingPrice}>
                <TypographyWrapper variant="body1">
                  {formatDollarAmountAsCurrency(
                    intl,
                    lineItem.listingLineItem.price,
                    lineItem.listingLineItem.currency
                  )}
                </TypographyWrapper>
              </h4>
            )}
            {actionEl}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ItemizedListing;
