import { Box, Card, CardActionArea, Grid, Typography } from '@material-ui/core';
import classNames from 'classnames';
import React, { FC } from 'react';
import { IconCheckCircleFilled } from '..';
import { useShopConfigV2 } from '../../hooks/shopConfig';
import { Product as ShopifyProduct, ShopifyProductVariant } from '../../types/shopify/product';
import { getProductDisplayTitle } from '../../util/shopifyHelpers';

import css from './Shopify.module.css';
import { defaultTreetStyles } from '../../shopConfig/config';

interface TitleProps {
  product?: ShopifyProduct;
  variant?: ShopifyProductVariant;
  productTitleDisplayType: string;
  size: string;
}

const Title: FC<TitleProps> = (props) => {
  const { product, productTitleDisplayType, size, variant } = props;

  return (
    <Typography variant="body1" style={{ textAlign: 'center' }}>
      {`${getProductDisplayTitle(product, productTitleDisplayType, variant)}${
        size ? ` | ${size}` : ''
      }`}
    </Typography>
  );
};

interface ProductImageAndTitleProps {
  isSelected: boolean;
  size: string;
  product?: ShopifyProduct;
  variant?: any;
}

const ProductImageAndTitle: FC<ProductImageAndTitleProps> = (props) => {
  const { isSelected, product, size, variant } = props;

  const { imageRatio, productTitleDisplayType } = useShopConfigV2();

  return (
    <div
      className={classNames(css.product, {
        [css.selectedProductImage]: isSelected,
      })}
    >
      <div className={css.threeToTwoWrapper}>
        <div style={{ paddingBottom: `${imageRatio * 100}%` }}>
          {/* eslint-disable jsx-a11y/img-redundant-alt, jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
          <img
            className={css.productImage}
            src={product?.featuredImage?.originalSrc}
            alt="product photo"
          />
        </div>
      </div>
      {isSelected && (
        <IconCheckCircleFilled
          color={defaultTreetStyles.gray100}
          className={css.selectedCheckMark}
        />
      )}

      <Box pt={1}>
        <Title
          product={product}
          variant={variant}
          productTitleDisplayType={productTitleDisplayType}
          size={size}
        />
      </Box>
    </div>
  );
};

interface ProductTitleProps {
  isSelected: boolean;
  size: string;
  product?: ShopifyProduct;
}

const ProductTitle: FC<ProductTitleProps> = (props) => {
  const { isSelected, product, size } = props;

  const { productTitleDisplayType } = useShopConfigV2();

  return (
    <div
      className={classNames(css.productTitle, {
        [css.selectedProductImage]: isSelected,
      })}
    >
      <Card variant="outlined" style={{ height: '100%', width: '100%' }}>
        <CardActionArea style={{ padding: '22px', height: '100%', width: '100%' }}>
          <Title product={product} productTitleDisplayType={productTitleDisplayType} size={size} />
        </CardActionArea>
      </Card>
    </div>
  );
};

interface ProductProps {
  product?: ShopifyProduct;
  selectedProductId?: string;
  lineItemId?: string;
  selectedLineItemId?: string;
  variant?: any;
  onChange?: (product?: ShopifyProduct, variant?: any, lineItemId?: string) => void;
}

const Product: FC<ProductProps> = (props: ProductProps) => {
  const { product, selectedProductId, lineItemId, selectedLineItemId, variant, onChange } = props;

  const selectedOptions = variant?.selectedOptions;
  const size = selectedOptions?.find(
    (option: any) => option?.name?.toLowerCase() === 'size'
  )?.value;
  const isSelected =
    product?.id === selectedProductId && (!selectedLineItemId || lineItemId === selectedLineItemId);

  const handleClick = () => {
    if (onChange) {
      onChange(product, variant, lineItemId);
    }
  };

  return (
    <Grid onClick={handleClick} style={{ marginBottom: 8 }} item xs={6} md={3} key={product?.id}>
      {product?.featuredImage?.originalSrc ? (
        <ProductImageAndTitle
          isSelected={isSelected}
          product={product}
          variant={variant}
          size={size}
        />
      ) : (
        <ProductTitle isSelected={isSelected} product={product} size={size} />
      )}
    </Grid>
  );
};

export default Product;
