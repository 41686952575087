// TODO (anniew|TREET-2191) Share Uploadcare code in server and src

export enum ImageSource {
  Uploadcare = 'UPLOADCARE',
}

export const isUploadcareImage = (src: string) => src.includes('ucarecdn.com/');

export const getUploadcareImagePreviewUrl = (cdnUrl?: string): string | null => {
  if (!cdnUrl) return null;
  return isUploadcareImage(cdnUrl) ? cdnUrl.concat('-/resize/500x/') : cdnUrl;
};

export const getLargeUploadcareImagePreviewUrl = (cdnUrl?: string): string | null => {
  if (!cdnUrl) return null;
  return isUploadcareImage(cdnUrl)
    ? cdnUrl.concat('-/resize/2000x/-/quality/lightest/-/progressive/yes/')
    : cdnUrl;
};

// Return Uploadcare image in the original file format (png or jpg).
// Non-optimized. Only for use in sending URLs to partners,
// should not be used for rendering.
export const getUploadcarePreserveFormatUrl = (cdnUrl?: string): string | null => {
  if (!cdnUrl) return null;
  return isUploadcareImage(cdnUrl) ? cdnUrl.concat('-/format/preserve/') : cdnUrl;
};
