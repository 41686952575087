import { getDefaultShopConfig, getDefaultTradeInShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { Conditions, getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';

const cozyearthSizes = [
  'Twin',
  'Twin/Twin XL',
  'Full',
  'Queen',
  'King',
  'California King',
  'Split King',
  'XS',
  'S',
  'M',
  'L',
  'XL',
  'XXL',
  'XXXL',
  'Standard',
  'Throw',
  'Oversized Throw',
];

const cozyearthConditionOptions = [
  {
    key: Conditions.NewWithoutTags,
    label: 'New With Tags',
    description: 'Your item has never been used and is still in the original packaging.',
  },
  {
    key: Conditions.Excellent,
    label: 'Excellent Condition',
    description:
      'Your item has only been lightly used and has been extremely well maintained. There may be some small signs of wear, but nothing major.',
  },
  {
    key: Conditions.Good,
    label: 'Good Condition',
    description:
      'You’ve used this item and it’s well maintained. There may be some quirks like a small stain, or a loose thread, but it’s overall in good shape. You will need to take pictures of any quirks the item has.',
  },
];

export const cozyearthShopConfig = {
  ...getDefaultShopConfig(),
  ...getDefaultTradeInShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig({ options: cozyearthConditionOptions }),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: cozyearthSizes }),
  ],
};
