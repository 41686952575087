import moment from 'moment';

export enum AdminPageTab {
  MarketplaceDashboard = 'marketplace-dashboard',
  TradeInDashboard = 'trade-in-dashboard',
  Disbursements = 'disbursements',
  Reports = 'reports',
  InventoryUpload = 'inventory-upload',
  Feedback = 'give-feedback',
  SchedulePromotion = 'schedule-promotion',
  Billing = 'billing',
}

export const formatDisbursementDate = (date: Date, dateFormat?: string) =>
  moment.utc(date).format(dateFormat || 'YYYY-MM-DD');
