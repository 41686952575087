import { ListingState } from '@prisma/client';
import { ThunkDispatch } from 'redux-thunk';
import { merge, omit } from 'lodash';
import moment from 'moment';
import { ListingPageParamType } from '../../util/urlHelpers';
import { RequestStatus } from '../../types/requestStatus';
import { addMarketplaceEntities, getOwnListingsById } from '../../ducks/marketplaceData.duck';
import { fetchStripeAccount } from '../../ducks/stripeConnectAccount.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { StorableError } from '../../types/error';
import { storableError } from '../../util/errors';
import { Feature, isFeatureEnabled } from '../../util/featureFlags';
import * as log from '../../util/log';
import { types as sdkTypes } from '../../util/sdkLoader';
import { OwnListing, OwnListingWithImages } from '../../types/sharetribe/listing';
import { denormalisedResponseEntities } from '../../util/data';
import { ImageFileData } from '../../types/photos/photos';
import {
  DRAFT_ID,
  getRemoteValueStore,
  ListingPageParamTab,
  updateRemoteValueStore,
} from './EditListingPage.utils';
import { approveListings as approveListingApiRequest, setInventory } from '../../util/api';
import { handle } from '../../util/helpers';
import { apolloClient } from '../../apollo';
import {
  GetPriceDropConfigBySharetribeListingIdResponse,
  UpsertListingDocument,
  UpsertPriceDropConfigDocument,
} from '../../types/apollo/generated/types.generated';
import { ST_LISTING_STATE_TO_LISTING_STATUS } from '../../types/models/listing';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { shouldAutoApproveListing as shouldAutoApproveListingFn } from '../../util/listings/listing';

const { UUID } = sdkTypes;

export interface EditListingPageParams {
  slug: string;
  id: string;
  type: ListingPageParamType;
  tab: ListingPageParamTab;
}

export interface ImageUploadParams {
  id: string;
  file: File;
}

// ================ Action types ================ //

const RESET_INITIAL_STATE = 'app/EditListingPage/RESET_INITIAL_STATE';

const FETCH_LISTING_REQUEST = 'app/EditListingPage/FETCH_LISTING_REQUEST';
const FETCH_LISTING_SUCCESS = 'app/EditListingPage/FETCH_LISTING_SUCCESS';
const FETCH_LISTING_ERROR = 'app/EditListingPage/FETCH_LISTING_ERROR';

const CREATE_LISTING_DRAFT_REQUEST = 'app/EditListingPage/CREATE_LISTING_DRAFT_REQUEST';
const CREATE_LISTING_DRAFT_SUCCESS = 'app/EditListingPage/CREATE_LISTING_DRAFT_SUCCESS';
const CREATE_LISTING_DRAFT_ERROR = 'app/EditListingPage/CREATE_LISTING_DRAFT_ERROR';

const SET_STOCK_REQUEST = 'app/EditListingPage/SET_STOCK_REQUEST';
const SET_STOCK_SUCCESS = 'app/EditListingPage/SET_STOCK_SUCCESS';
const SET_STOCK_ERROR = 'app/EditListingPage/SET_STOCK_ERROR';

const UPDATE_LISTING_REQUEST = 'app/EditListingPage/UPDATE_LISTING_REQUEST';
const UPDATE_LISTING_SUCCESS = 'app/EditListingPage/UPDATE_LISTING_SUCCESS';
const UPDATE_LISTING_ERROR = 'app/EditListingPage/UPDATE_LISTING_ERROR';

const PUBLISH_LISTING_REQUEST = 'app/EditListingPage/PUBLISH_LISTING_REQUEST';
const PUBLISH_LISTING_SUCCESS = 'app/EditListingPage/PUBLISH_LISTING_SUCCESS';
const PUBLISH_LISTING_ERROR = 'app/EditListingPage/PUBLISH_LISTING_ERROR';

const APPROVE_LISTING_REQUEST = 'app/EditListingPage/APPROVE_LISTING_REQUEST';
const APPROVE_LISTING_SUCCESS = 'app/EditListingPage/APPROVE_LISTING_SUCCESS';
const APPROVE_LISTING_ERROR = 'app/EditListingPage/APPROVE_LISTING_ERROR';

const UPLOAD_IMAGE_REQUEST = 'app/EditListingPage/UPLOAD_IMAGE_REQUEST';
const UPLOAD_IMAGE_SUCCESS = 'app/EditListingPage/UPLOAD_IMAGE_SUCCESS';
const UPLOAD_IMAGE_ERROR = 'app/EditListingPage/UPLOAD_IMAGE_ERROR';

const SET_IMAGES_API_ERROR = 'app/EditListingPage/SET_IMAGES_API_ERROR';

const SET_GUEST_LISTING_VALUES = 'app/EditListingPage/SET_GUEST_LISTING_VALUES';

interface ResetInitialState {
  type: typeof RESET_INITIAL_STATE;
  params: EditListingPageParams;
}

interface FetchListingRequest {
  type: typeof FETCH_LISTING_REQUEST;
}

interface FetchListingSuccess {
  type: typeof FETCH_LISTING_SUCCESS;
}

interface FetchListingError {
  type: typeof FETCH_LISTING_ERROR;
  error: StorableError;
}

interface CreateListingDraftRequest {
  type: typeof CREATE_LISTING_DRAFT_REQUEST;
}

interface CreateListingDraftSuccess {
  type: typeof CREATE_LISTING_DRAFT_SUCCESS;
  listingDraft: Partial<OwnListing>;
}

interface CreateListingDraftError {
  type: typeof CREATE_LISTING_DRAFT_ERROR;
  error: StorableError;
}

interface SetStockRequest {
  type: typeof SET_STOCK_REQUEST;
}

interface SetStockSuccess {
  type: typeof SET_STOCK_SUCCESS;
}

interface SetStockError {
  type: typeof SET_STOCK_ERROR;
  error: StorableError;
}

interface UpdateListingRequest {
  type: typeof UPDATE_LISTING_REQUEST;
}

interface UpdateListingSuccess {
  type: typeof UPDATE_LISTING_SUCCESS;
}

interface UpdateListingError {
  type: typeof UPDATE_LISTING_ERROR;
  error: StorableError;
}

interface PublishListingRequest {
  type: typeof PUBLISH_LISTING_REQUEST;
}

interface PublishListingSuccess {
  type: typeof PUBLISH_LISTING_SUCCESS;
}

interface PublishListingError {
  type: typeof PUBLISH_LISTING_ERROR;
  error: StorableError;
}

interface ApproveTradeInListingRequest {
  type: typeof APPROVE_LISTING_REQUEST;
}

interface ApproveTradeInListingSuccess {
  type: typeof APPROVE_LISTING_SUCCESS;
}

interface ApproveTradeInListingError {
  type: typeof APPROVE_LISTING_ERROR;
  error: StorableError;
}

interface UploadImageRequest {
  type: typeof UPLOAD_IMAGE_REQUEST;
  imageData: ImageFileData;
}

interface UploadImageSuccess {
  type: typeof UPLOAD_IMAGE_SUCCESS;
  imageData: ImageFileData;
}

interface UploadImageError {
  type: typeof UPLOAD_IMAGE_ERROR;
  id: string;
  error: StorableError;
}

interface SetImagesApiError {
  type: typeof SET_IMAGES_API_ERROR;
  error: Error | null;
}

interface SetUnsavedListingValues {
  type: typeof SET_GUEST_LISTING_VALUES;
  unsavedListingValues: Partial<OwnListingWithImages['attributes']> | null;
}

type EditListingPageActionType =
  | ResetInitialState
  | FetchListingRequest
  | FetchListingSuccess
  | FetchListingError
  | CreateListingDraftRequest
  | CreateListingDraftSuccess
  | CreateListingDraftError
  | SetStockRequest
  | SetStockSuccess
  | SetStockError
  | UpdateListingRequest
  | UpdateListingSuccess
  | UpdateListingError
  | PublishListingRequest
  | PublishListingSuccess
  | PublishListingError
  | ApproveTradeInListingRequest
  | ApproveTradeInListingSuccess
  | ApproveTradeInListingError
  | UploadImageRequest
  | UploadImageSuccess
  | UploadImageError
  | SetImagesApiError
  | SetUnsavedListingValues;

export interface EditListingPageState {
  params: EditListingPageParams | null;
  fetchListingStatus: RequestStatus;
  fetchListingError: StorableError | null;
  isSearchActive: boolean;
  createListingDraftStatus: RequestStatus;
  createListingDraftError: StorableError | null;
  setStockStatus: RequestStatus;
  setStockError: StorableError | null;
  updateListingStatus: RequestStatus;
  updateListingError: StorableError | null;
  publishListingStatus: RequestStatus;
  publishListingError: StorableError | null;
  approveListingStatus: RequestStatus;
  approveListingError: StorableError | null;
  listingDraft: Partial<OwnListing> | null;
  uploadImageRequestCount: number;
  uploadImageError: StorableError | null;
  imagesApiError: Error | null;
  images: { [id: string]: ImageFileData };
  unsavedListingValues: Partial<OwnListingWithImages['attributes']> | null;
}

const initialState: EditListingPageState = {
  params: null,
  fetchListingStatus: RequestStatus.Ready,
  fetchListingError: null,
  isSearchActive: false,
  createListingDraftStatus: RequestStatus.Ready,
  createListingDraftError: null,
  setStockStatus: RequestStatus.Ready,
  setStockError: null,
  updateListingStatus: RequestStatus.Ready,
  updateListingError: null,
  publishListingStatus: RequestStatus.Ready,
  publishListingError: null,
  approveListingStatus: RequestStatus.Ready,
  approveListingError: null,
  listingDraft: null,
  uploadImageRequestCount: 0,
  uploadImageError: null,
  imagesApiError: null,
  // TODO (anniew|TREET-1908) Clean up 'images' state
  images: {},
  unsavedListingValues: null,
};

export default function editListingPageReducer(
  state: EditListingPageState = initialState,
  action: EditListingPageActionType
): EditListingPageState {
  switch (action.type) {
    case RESET_INITIAL_STATE:
      return { ...initialState, params: action.params };
    case FETCH_LISTING_REQUEST: {
      return { ...state, fetchListingStatus: RequestStatus.Pending, fetchListingError: null };
    }
    case FETCH_LISTING_SUCCESS: {
      return { ...state, fetchListingStatus: RequestStatus.Success };
    }
    case FETCH_LISTING_ERROR: {
      return { ...state, fetchListingStatus: RequestStatus.Error, fetchListingError: action.error };
    }
    case CREATE_LISTING_DRAFT_REQUEST: {
      return {
        ...state,
        createListingDraftStatus: RequestStatus.Pending,
        createListingDraftError: null,
      };
    }
    case CREATE_LISTING_DRAFT_SUCCESS: {
      return {
        ...state,
        createListingDraftStatus: RequestStatus.Success,
        listingDraft: action.listingDraft,
      };
    }
    case CREATE_LISTING_DRAFT_ERROR: {
      return {
        ...state,
        createListingDraftStatus: RequestStatus.Error,
        createListingDraftError: action.error,
      };
    }
    case SET_STOCK_REQUEST: {
      return {
        ...state,
        setStockStatus: RequestStatus.Pending,
        setStockError: null,
      };
    }
    case SET_STOCK_SUCCESS: {
      return {
        ...state,
        setStockStatus: RequestStatus.Success,
      };
    }
    case SET_STOCK_ERROR: {
      return {
        ...state,
        setStockStatus: RequestStatus.Error,
        setStockError: action.error,
      };
    }
    case UPDATE_LISTING_REQUEST: {
      return {
        ...state,
        updateListingStatus: RequestStatus.Pending,
        updateListingError: null,
      };
    }
    case UPDATE_LISTING_SUCCESS: {
      return {
        ...state,
        updateListingStatus: RequestStatus.Success,
      };
    }
    case UPDATE_LISTING_ERROR: {
      return {
        ...state,
        updateListingStatus: RequestStatus.Error,
        updateListingError: action.error,
      };
    }
    case PUBLISH_LISTING_REQUEST: {
      return {
        ...state,
        publishListingStatus: RequestStatus.Pending,
        publishListingError: null,
      };
    }
    case PUBLISH_LISTING_SUCCESS: {
      return {
        ...state,
        images: {},
        publishListingStatus: RequestStatus.Success,
      };
    }
    case PUBLISH_LISTING_ERROR: {
      return {
        ...state,
        publishListingStatus: RequestStatus.Error,
        publishListingError: action.error,
      };
    }
    case APPROVE_LISTING_REQUEST: {
      return {
        ...state,
        approveListingStatus: RequestStatus.Pending,
        approveListingError: null,
      };
    }
    case APPROVE_LISTING_SUCCESS: {
      return {
        ...state,
        approveListingStatus: RequestStatus.Success,
      };
    }
    case APPROVE_LISTING_ERROR: {
      return {
        ...state,
        approveListingStatus: RequestStatus.Error,
        approveListingError: action.error,
      };
    }
    case UPLOAD_IMAGE_REQUEST: {
      const images = {
        ...state.images,
        [action.imageData.id]: { ...action.imageData },
      };
      return {
        ...state,
        images,
        uploadImageRequestCount: state.uploadImageRequestCount + 1,
        uploadImageError: null,
        imagesApiError: null,
      };
    }
    case UPLOAD_IMAGE_SUCCESS: {
      const { id, imageId } = action.imageData;
      const { file } = state.images[id];
      const images = { ...state.images, [id]: { id, imageId, file } };
      return {
        ...state,
        images,
        uploadImageRequestCount: state.uploadImageRequestCount - 1,
      };
    }
    case UPLOAD_IMAGE_ERROR: {
      const { id, error } = action;
      const images = omit(state.images, id);
      return {
        ...state,
        images,
        uploadImageRequestCount: state.uploadImageRequestCount - 1,
        uploadImageError: error,
      };
    }
    case SET_IMAGES_API_ERROR: {
      return { ...state, imagesApiError: action.error };
    }
    case SET_GUEST_LISTING_VALUES: {
      return { ...state, unsavedListingValues: action.unsavedListingValues };
    }
    default:
      return state;
  }
}

// ================ Action creators ================ //

const resetInitialState = (params: EditListingPageParams) => ({
  type: RESET_INITIAL_STATE,
  params,
});

const fetchListingRequest = () => ({ type: FETCH_LISTING_REQUEST });
const fetchListingSuccess = () => ({ type: FETCH_LISTING_SUCCESS });
const fetchListingError = (error: StorableError) => ({ type: FETCH_LISTING_ERROR, error });

const createListingDraftRequest = () => ({ type: CREATE_LISTING_DRAFT_REQUEST });
const createListingDraftSuccess = (listingDraft: Partial<OwnListing>) => ({
  type: CREATE_LISTING_DRAFT_SUCCESS,
  listingDraft,
});
const createListingDraftError = (error: StorableError) => ({
  type: CREATE_LISTING_DRAFT_ERROR,
  error,
});

const setStockRequest = () => ({ type: SET_STOCK_REQUEST });
const setStockSuccess = () => ({ type: SET_STOCK_SUCCESS });
const setStockError = (error: StorableError) => ({ type: CREATE_LISTING_DRAFT_ERROR, error });

const updateListingRequest = () => ({ type: UPDATE_LISTING_REQUEST });
const updateListingSuccess = () => ({ type: UPDATE_LISTING_SUCCESS });
const updateListingError = (error: StorableError) => ({ type: UPDATE_LISTING_ERROR, error });

const publishListingRequest = () => ({ type: PUBLISH_LISTING_REQUEST });
const publishListingSuccess = () => ({ type: PUBLISH_LISTING_SUCCESS });
const publishListingError = (error: StorableError) => ({ type: PUBLISH_LISTING_ERROR, error });

const approveListingRequest = () => ({ type: APPROVE_LISTING_REQUEST });
const approveListingSuccess = () => ({ type: APPROVE_LISTING_SUCCESS });
const approveListingError = (error: StorableError) => ({
  type: APPROVE_LISTING_ERROR,
  error,
});

const uploadImageRequest = (imageData: ImageFileData) => ({
  type: UPLOAD_IMAGE_REQUEST,
  imageData,
});
const uploadImageSuccess = (imageData: ImageFileData) => ({
  type: UPLOAD_IMAGE_SUCCESS,
  imageData,
});
const uploadImageError = (id: string, error: StorableError) => ({
  type: UPLOAD_IMAGE_ERROR,
  id,
  error,
});

export const setImagesApiError = (error: Error | string | null) => ({
  type: SET_IMAGES_API_ERROR,
  error,
});

export const setUnsavedListingValues = (unsavedListingValues: any | null) => ({
  type: SET_GUEST_LISTING_VALUES,
  unsavedListingValues,
});

// ================ Thunks ================ //

export const uploadImage =
  (params: ImageUploadParams) =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => any, sdk: any) => {
    const { id, file } = params;
    dispatch(uploadImageRequest({ id, file }));

    try {
      const imageResponse = await sdk.images.upload({ image: file });
      const imageFileData = { id, imageId: imageResponse.data.data.id };
      dispatch(uploadImageSuccess(imageFileData));
      return { ...imageFileData, file };
    } catch (e) {
      dispatch(uploadImageError(id, storableError(e)));
      throw e;
    }
  };

const clearValuesFromUrl = () => async (dispatch: ThunkDispatch<any, any, any>) => {
  if (typeof window !== 'undefined') {
    // Use native `replaceState` as to not trigger a rerender with react-router.
    // Remove the hash values.
    const { search, pathname } = window.location;
    window.history.replaceState({}, '', `${pathname}${search}`);
  }

  dispatch(setUnsavedListingValues(null));
};

/**
 * Use this function to completely erase any existing listing attribute values that
 * are being held in state/remote store, and save the new values to the store. Helpful when
 * critical data changes on the form and the listing needs to be 'reset'.
 *
 * @param newValues
 * @returns void
 */
export const resetUnsavedListingWithNewValues =
  (newValues: Partial<OwnListing['attributes']>) =>
  async (dispatch: ThunkDispatch<any, any, any>) => {
    // Add new values to remote value store, don't pass existing values to merge with.
    const { formValues } = await updateRemoteValueStore({ formValues: newValues }, {}, true);

    // Ensure remote values stay in sync with state values
    dispatch(setUnsavedListingValues(formValues));
  };

export const fetchListing =
  (id: string) => async (dispatch: ThunkDispatch<any, any, any>, getState: () => any, sdk: any) => {
    dispatch(fetchListingRequest());

    let listingResponse;
    try {
      listingResponse = await sdk.ownListings.show({
        id: new UUID(id),
        include: ['author', 'images', 'privateData'],
        'fields.image': ['variants.default'],
      });
      dispatch(addMarketplaceEntities(listingResponse));
      dispatch(fetchListingSuccess());
    } catch (e) {
      dispatch(fetchListingError(storableError(e)));
    }
    return listingResponse;
  };

// Set stock to 1
export const setStock = (listingId: string) => async (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(setStockRequest());

  const oldTotal = null;
  const newTotal = 1;
  try {
    const response = await setInventory({ listingId, oldTotal, newTotal, forceUpdate: true });
    dispatch(addMarketplaceEntities(response));
    dispatch(setStockSuccess());
    return response;
  } catch (e) {
    return dispatch(setStockError(storableError(e)));
  }
};

export const createListingDraft =
  (params: any) =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => any, sdk: any) => {
    dispatch(createListingDraftRequest());

    const queryParams = {
      expand: true,
      include: ['author', 'images'],
      'fields.image': ['variants.default'],
    };

    try {
      const listingDraftResponse = await sdk.ownListings.createDraft(params, queryParams);
      dispatch(addMarketplaceEntities(listingDraftResponse));
      const listingDraft = denormalisedResponseEntities(listingDraftResponse)[0];
      dispatch(createListingDraftSuccess(listingDraft));
      if (listingDraft.id?.uuid) {
        dispatch(setStock(listingDraft.id.uuid));
      }
      dispatch(resetUnsavedListingWithNewValues(listingDraft.attributes));
      return listingDraft;
    } catch (e) {
      log.error(e, 'create-listing-draft-failed', params);
      return dispatch(createListingDraftError(storableError(e)));
    }
  };

export const updateListing =
  (params: any) =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => any, sdk: any) => {
    const { id } = params;
    dispatch(updateListingRequest());

    try {
      const ownListingsResponse = await sdk.ownListings.update(
        { ...params, id: new UUID(id) },
        { expand: true, include: ['images'] }
      );
      dispatch(addMarketplaceEntities(ownListingsResponse));
      dispatch(updateListingSuccess());
      const savedListing = denormalisedResponseEntities(ownListingsResponse)[0];
      dispatch(resetUnsavedListingWithNewValues(savedListing.attributes));
      return savedListing;
    } catch (e) {
      log.error(e, 'update-listing-failed', params);
      return dispatch(updateListingError(storableError(e)));
    }
  };

export const approveListing =
  (listingId: string) => async (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(approveListingRequest());
    try {
      const response = await approveListingApiRequest({ listingId });
      const listingsResponse = response.data;
      dispatch(approveListingSuccess());
      dispatch(addMarketplaceEntities(listingsResponse));
      const listing = denormalisedResponseEntities(listingsResponse)[0];
      return listing;
    } catch (e) {
      console.error(e);
      log.error(e, 'approve-listing-failed', { listingId, e });
      return dispatch(approveListingError(storableError(e)));
    }
  };

export const publishListing =
  (listingId: string) =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => any, sdk: any) => {
    dispatch(publishListingRequest());
    try {
      const rootState = getState();
      const { treetId } = rootState.initial;
      let sharetribeListing = getOwnListingsById(rootState, [new UUID(listingId)])[0];

      const shouldAutoApproveListing = shouldAutoApproveListingFn(sharetribeListing);

      // Only complete these actions when the listing moves from Draft -> Pending Approval
      if (sharetribeListing?.attributes?.state === LISTING_STATE_DRAFT) {
        const requiresBrandApproval =
          isFeatureEnabled(Feature.BrandListingApprovalTool, treetId) && !shouldAutoApproveListing;
        await dispatch(
          updateListing({
            id: listingId,
            publicData: {
              submittedAt: Date.now(),
              ...(requiresBrandApproval ? { isPendingBrandApproval: true } : {}),
            },
          })
        );
        const response = await sdk.ownListings.publishDraft({ id: listingId }, { expand: true });
        dispatch(addMarketplaceEntities(response));

        [sharetribeListing] = denormalisedResponseEntities(response);
      }

      if (shouldAutoApproveListing) {
        sharetribeListing = await dispatch(approveListing(listingId));
      }

      const {
        state: sharetribeListingState,
        publicData,
        title,
        price,
      } = sharetribeListing?.attributes || {};
      const {
        listingItemType,
        isBrandDirect,
        shopifyProductVariant,
        shipFromCountry,
        userGeneratedBrandName,
        sourceListingId,
      } = publicData || {};

      const { currentUser } = rootState.user;
      const { shopId } = rootState.initial;
      const state =
        (sharetribeListingState && ST_LISTING_STATE_TO_LISTING_STATUS[sharetribeListingState]) ||
        ListingState.PENDING_APPROVAL;

      const postgresUpsertListingInput = {
        sharetribeListingId: sharetribeListing?.id?.uuid,
        title,
        currency: price?.currency,
        price: price?.amount,
        state,
        itemType: listingItemType,
        isBrandDirect: isBrandDirect || false,
        sharetribePublicData: publicData,
        availableInventory: 1, // Users are only able to upload listings with inventory 1
        externalSku: shopifyProductVariant?.sku,
        shopId,
        sharetribeSellerId: currentUser?.id?.uuid,
        shipFromCountry,
        itemBrand: userGeneratedBrandName,
        sharetribeSourceListingId: sourceListingId,
      };
      const [postgresListing, postgresListingError] = await handle(
        apolloClient.mutate({
          mutation: UpsertListingDocument,
          variables: {
            input: postgresUpsertListingInput,
          },
        })
      );

      if (postgresListingError) {
        // Log error to sentry only without disrupting the listing flow.
        // (TODO|TREET-3578): Once Listing data is fully migrated off of Sharetribe, start throwing error
        // if creating PG Listing fails.
        log.error(postgresListingError, 'postgres-create-listing-failed', {
          ...postgresUpsertListingInput,
          sharetribeListing,
        });
      }

      dispatch(clearValuesFromUrl());
      dispatch(publishListingSuccess());
      return {
        publishedListing: sharetribeListing,
        pgListingId: postgresListing?.data?.upsertListing?.listing?.id,
        state,
      };
    } catch (e) {
      return dispatch(publishListingError(storableError(e)));
    }
  };

export const upsertPriceDropConfig =
  (
    pgListingId: string,
    listingState: string,
    updatedAutomaticPriceDropInfo: {
      enabled: boolean;
      minPrice: number | undefined;
      percentageDrop: number;
      anchorPrice: number | undefined;
    },
    existingPriceDropConfig:
      | GetPriceDropConfigBySharetribeListingIdResponse['priceDropConfig']
      | undefined
      | null
  ) =>
  async () => {
    const {
      enabled: isActive,
      minPrice,
      percentageDrop,
      anchorPrice,
    } = updatedAutomaticPriceDropInfo;

    const daysBetweenPriceDrops = 7;
    const nextScheduledDrop =
      listingState === ListingState.OPEN
        ? moment().add(daysBetweenPriceDrops, 'days').toISOString()
        : null;

    const postgresUpsertPriceDropConfigInput = {
      listingId: pgListingId,
      // minPrice, anchorPrice, and percentageDrop are required/nonNull inputs,
      // but we don't want to update the values if price drop is turned off,
      // so pass existing values.
      minPrice: minPrice ? Math.round(minPrice * 100) : existingPriceDropConfig?.minPrice,
      anchorPrice: anchorPrice
        ? Math.round(anchorPrice * 100)
        : existingPriceDropConfig?.anchorPrice,
      percentageDrop: percentageDrop || existingPriceDropConfig?.percentageDrop,
      daysBetweenPriceDrops,
      nextScheduledDrop,
      isActive,
    };

    const [postgresPriceDropConfig, postgresPriceDropConfigError] = await handle(
      apolloClient.mutate({
        mutation: UpsertPriceDropConfigDocument,
        variables: {
          input: postgresUpsertPriceDropConfigInput,
        },
      })
    );

    if (postgresPriceDropConfigError) {
      log.error(postgresPriceDropConfigError, 'postgres-create-price-drop-config-failed', {
        ...postgresUpsertPriceDropConfigInput,
      });
    }
    return postgresPriceDropConfig;
  };

export const updateUnsavedListingValues =
  (updatedValues: Partial<OwnListing['attributes']>, shouldUpdateImmediately?: boolean) =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => any) => {
    if (!updatedValues) return;
    const { unsavedListingValues: existingValues } = getState().EditListingPage;

    // Add new values to remote value store
    const { formValues } = await updateRemoteValueStore(
      { formValues: updatedValues },
      { formValues: existingValues },
      shouldUpdateImmediately
    );

    // Ensure remote values stay in sync with state values
    dispatch(setUnsavedListingValues(formValues));
  };

export const loadData =
  (params: EditListingPageParams) =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: () => any
    // eslint-disable-next-line consistent-return
  ) => {
    const { id } = params;
    dispatch(resetInitialState(params));

    // Prevent SSR 302 redirect for logged out users editing a saved draft or published listing
    if (typeof window === 'undefined') {
      return;
    }
    // Rehydrate state with values from remote store
    const { formValues: remoteValues } = await getRemoteValueStore();
    if (remoteValues) dispatch(setUnsavedListingValues(remoteValues));

    if (id !== DRAFT_ID) {
      const user = await dispatch(fetchCurrentUser());
      if ((user as any)?.attributes || !remoteValues) {
        const listing = await dispatch(fetchListing(id));
        const sharetribeValues = listing && denormalisedResponseEntities(listing)[0]?.attributes;
        await dispatch(updateUnsavedListingValues(merge(sharetribeValues, remoteValues), true));
      }
      const { currentUser } = getState().user;

      if (
        currentUser?.stripeAccount ||
        currentUser?.attributes.profile.protectedData?.stripeAccountId
      ) {
        dispatch(fetchStripeAccount());
      }
    }
  };
