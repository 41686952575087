import React, { FC } from 'react';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Box, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useBrandCountryConfig } from '../../hooks/useCountryConfig';
import { useShopConfig } from '../../hooks/shopConfig';
import { isGiftCardOption } from '../../shopConfig/shops/default';
import { FAQ, FAQObject, FontConfigSection } from '../../types/shopConfig/shopConfigV2';
import { ShipDeadline } from '../../util/shipping';
import ExternalLink from '../ExternalLink/ExternalLink';
import { StyledAccordion, TypographyWrapper } from '..';
import { usePageTitles } from '../../hooks/usePageTitles';
import { useActionCopy } from '../../hooks/useActionCopy';
import css from './SellerFAQ.module.css';
import { CountryCode } from '../../types/apollo/generated/types.generated';
import { useYotpo } from '../../hooks/useYotpo';
import MarkdownText from '../MarkdownText/MarkdownText';

interface FAQTextProps {
  children: string;
}

const FAQTitle: FC<FAQTextProps> = ({ children }) => {
  const { fontConfig } = useShopConfig();
  const typographyVariant = fontConfig[FontConfigSection.AboutPageFAQTitle];

  return (
    <TypographyWrapper
      variant={typographyVariant}
      typographyOverrides={{ style: { fontWeight: 'bold' } }}
    >
      {children}
    </TypographyWrapper>
  );
};

const FAQDescription: FC<FAQTextProps> = ({ children }) => (
  <TypographyWrapper variant="body1" typographyOverrides={{ style: { whiteSpace: 'pre-wrap' } }}>
    <MarkdownText markdownText={children} />
  </TypographyWrapper>
);

const SellRequirements: FC = () => {
  const {
    copy: { faqCopy },
  } = useShopConfig();

  return (
    <StyledAccordion name="sell-requirements">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel5a-content"
        id="panel5a-header"
      >
        <p>
          <FAQTitle>{faqCopy[FAQ.SellRequirements].title}</FAQTitle>
        </p>
      </AccordionSummary>
      <AccordionDetails>
        <span>
          <p>
            <FAQDescription>{faqCopy[FAQ.SellRequirements].description}</FAQDescription>
          </p>
        </span>
      </AccordionDetails>
    </StyledAccordion>
  );
};

const WhySell: FC = () => {
  const {
    treetShopName,
    copy: { faqCopy },
  } = useShopConfig();

  return (
    <StyledAccordion name="why-sell">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel5a-content"
        id="panel5a-header"
      >
        <p>
          <FAQTitle>{faqCopy[FAQ.WhySell]?.title || 'Why should I sell my items here?'}</FAQTitle>
        </p>
      </AccordionSummary>
      <AccordionDetails>
        <span>
          <p>
            <FAQDescription>
              {faqCopy[FAQ.WhySell]?.description ||
                `${treetShopName} is the official destination for buying and selling our secondhand items.` +
                  ` This is where our secondhand community lives, which means you’ll get the best value for your` +
                  ` item and be able to sell it fast. Plus, you can easily list items by finding them in your order history with us.`}
            </FAQDescription>
          </p>
        </span>
      </AccordionDetails>
    </StyledAccordion>
  );
};

const HowToSell: FC = () => {
  const {
    shopName,
    copy: { faqCopy },
  } = useShopConfig();
  const { listActionCopy } = useActionCopy();

  return (
    <StyledAccordion name="how-to-sell">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel6a-content"
        id="panel6a-header"
      >
        <p>
          <FAQTitle>{faqCopy[FAQ.HowToSell]?.title || 'How do I list an item for sale?'}</FAQTitle>
        </p>
      </AccordionSummary>
      <AccordionDetails>
        <span>
          {faqCopy[FAQ.HowToSell]?.description ? (
            <p>
              <FAQDescription>{faqCopy[FAQ.HowToSell].description}</FAQDescription>
            </p>
          ) : (
            <>
              <p>
                <TypographyWrapper variant="body1">
                  Listing your item is easy! Simply click “{listActionCopy}” in the upper right-hand
                  corner, create an account (tip: use the same email you’ve used to purchase from{' '}
                  {shopName} so you can see your order history), and go through the listing process.
                </TypographyWrapper>
              </p>
              <p>
                <TypographyWrapper variant="body1">
                  Once you’ve added all of the applicable information, click “Submit” for the post
                  to be reviewed within 24 hours. After it’s reviewed and approved, it will go live.
                  If there are any issues, you’ll receive an email asking to make changes before it
                  can be accepted.
                </TypographyWrapper>
              </p>
            </>
          )}
        </span>
      </AccordionDetails>
    </StyledAccordion>
  );
};

const HowToShip: FC = () => {
  const {
    copy: { faqCopy },
  } = useShopConfig();

  return (
    <StyledAccordion name="how-to-ship">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel8a-content"
        id="panel8a-header"
      >
        <p>
          <FAQTitle>
            {faqCopy[FAQ.HowToShip]?.title || 'How do I ship my item after someone purchases it?'}
          </FAQTitle>
        </p>
      </AccordionSummary>
      <AccordionDetails>
        <p>
          <FAQDescription>
            {faqCopy[FAQ.HowToShip]?.description ||
              `After someone purchases your item, you’ll receive an email that includes a prepaid shipping label for you to print and use to ship your item within ${ShipDeadline.Default} days. The cost of the shipping label is paid for by the Buyer.`}
          </FAQDescription>
        </p>
      </AccordionDetails>
    </StyledAccordion>
  );
};

const ListingReviewProcess: FC = () => {
  const {
    copy: { faqCopy },
  } = useShopConfig();

  return (
    <StyledAccordion name="listing-review-process">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel7a-content"
        id="panel7a-header"
      >
        <p>
          <FAQTitle>
            {faqCopy[FAQ.ListingReviewProcess]?.title ||
              'How does the listing review process work?'}
          </FAQTitle>
        </p>
      </AccordionSummary>
      <AccordionDetails>
        {faqCopy[FAQ.ListingReviewProcess]?.description ? (
          <FAQDescription>{faqCopy[FAQ.ListingReviewProcess]?.description}</FAQDescription>
        ) : (
          <span>
            <p>
              <TypographyWrapper variant="body1">
                The Treet Team reviews every item before it goes live. They’re verifying several
                things about your post:
              </TypographyWrapper>
            </p>
            <ul>
              <li>
                <TypographyWrapper variant="body1">
                  Your pictures are accurate and high quality
                </TypographyWrapper>
              </li>
              <li>
                <TypographyWrapper variant="body1">
                  Any quirks are accurately described
                </TypographyWrapper>
              </li>
              <li>
                <TypographyWrapper variant="body1">
                  The condition you selected accurately reflects your image
                </TypographyWrapper>
              </li>
              <li>
                <TypographyWrapper variant="body1">
                  The product is an authentic piece
                </TypographyWrapper>
              </li>
            </ul>
          </span>
        )}
      </AccordionDetails>
    </StyledAccordion>
  );
};

const SellingFees: FC = () => {
  const { cashPayoutPercentage, creditPayoutPercentage, treetShopName, shopName, mainSite } =
    useShopConfig();
  const intl = useIntl();
  const { currencyConfig } = useBrandCountryConfig();

  const creditPayoutBonusText =
    creditPayoutPercentage > 1.0
      ? `In fact, ${shopName} will give you a bonus ${Math.round(
          (creditPayoutPercentage - 1.0) * 100
        )}% in store credit. `
      : '';
  const shopNameHyperlinked = (
    <ExternalLink className={css.externalLink} href={mainSite}>
      <TypographyWrapper variant="body1" typographyOverrides={{ display: 'inline' }}>
        {shopName}
      </TypographyWrapper>
    </ExternalLink>
  );

  return (
    <StyledAccordion name="selling-fees">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel12a-content"
        id="panel12a-header"
      >
        <p>
          <FAQTitle>What are the fees for selling my items here?</FAQTitle>
        </p>
      </AccordionSummary>
      <AccordionDetails>
        {!!creditPayoutPercentage && !!cashPayoutPercentage && (
          <Box>
            <p>
              <TypographyWrapper variant="body1">
                As a Seller on {treetShopName}, you have{' '}
                <TypographyWrapper
                  variant="body1"
                  typographyOverrides={{ style: { fontWeight: 'bold' }, display: 'inline' }}
                >
                  two
                </TypographyWrapper>{' '}
                choices for receiving your funds: cash or credit back to {shopName}.
              </TypographyWrapper>
            </p>
            <p>
              <TypographyWrapper variant="body1">
                <>
                  If you choose store credit, there is{' '}
                  <TypographyWrapper
                    variant="body1"
                    typographyOverrides={{ style: { fontWeight: 'bold' }, display: 'inline' }}
                  >
                    no fee
                  </TypographyWrapper>
                  ! {creditPayoutBonusText}(i.e. Selling an item for{' '}
                  {intl.formatNumber(100, currencyConfig)} gives you{' '}
                  {intl.formatNumber(Math.round(100 * creditPayoutPercentage), currencyConfig)}{' '}
                  credit back to {shopNameHyperlinked}.)
                </>
              </TypographyWrapper>
            </p>
            <p>
              <TypographyWrapper variant="body1">
                If you choose cash, the fee is{' '}
                <TypographyWrapper
                  variant="body1"
                  typographyOverrides={{ style: { fontWeight: 'bold' }, display: 'inline' }}
                >
                  <>{Math.round((1.0 - cashPayoutPercentage) * 100)}%</>
                </TypographyWrapper>
                . (i.e. Selling an item for {intl.formatNumber(100, currencyConfig)} gives you{' '}
                {intl.formatNumber(Math.round(100 * cashPayoutPercentage), currencyConfig)} cash.)
                This fee is paid directly to Treet.
              </TypographyWrapper>
            </p>
          </Box>
        )}
        {!creditPayoutPercentage && !!cashPayoutPercentage && (
          <p>
            <TypographyWrapper variant="body1">
              As a Seller on {treetShopName}, the fee is{' '}
              <TypographyWrapper
                variant="body1"
                typographyOverrides={{ style: { fontWeight: 'bold' }, display: 'inline' }}
              >
                <>{Math.round((1.0 - cashPayoutPercentage) * 100)}%</>
              </TypographyWrapper>
              . (i.e. Selling an item for {intl.formatNumber(100, currencyConfig)} gives you{' '}
              {intl.formatNumber(Math.round(100 * cashPayoutPercentage), currencyConfig)} cash.)
              This fee is paid directly to Treet.
            </TypographyWrapper>
          </p>
        )}
        {!!creditPayoutPercentage && !cashPayoutPercentage && (
          <p>
            <TypographyWrapper variant="body1">
              As a Seller on {treetShopName}, there is{' '}
              <TypographyWrapper
                variant="body1"
                typographyOverrides={{ style: { fontWeight: 'bold' }, display: 'inline' }}
              >
                no fee
              </TypographyWrapper>
              ! {creditPayoutBonusText}(i.e. Selling an item for{' '}
              {intl.formatNumber(100, currencyConfig)} gives you{' '}
              {intl.formatNumber(Math.round(100 * creditPayoutPercentage), currencyConfig)} credit
              back to {shopNameHyperlinked}.)
            </TypographyWrapper>
          </p>
        )}
      </AccordionDetails>
    </StyledAccordion>
  );
};

const CashPayout: FC = () => {
  const {
    internationalConfig: { allowedOriginToDestinationCountries },
  } = useShopConfig();

  const allowedOriginCountries = Object.keys(allowedOriginToDestinationCountries);
  const isSingleOriginCountryAllowed = allowedOriginCountries.length === 1;
  const isSellingEnabledForUS = !!allowedOriginCountries.includes(CountryCode.Us);
  const isSellingEnabledForAU = !!allowedOriginCountries.includes(CountryCode.Au);
  const hasCaveatForCountry = isSellingEnabledForUS || isSellingEnabledForAU;

  return (
    <>
      <p>
        <TypographyWrapper variant="body1">
          To redeem your funds as cash, you’ll be prompted to enter your bank account details
          {hasCaveatForCountry ? '*' : ''} and a direct deposit will be sent within 7 business days
          via our payment partner,{' '}
          <ExternalLink className={css.externalLink} href="https://stripe.com/">
            <TypographyWrapper variant="body1" typographyOverrides={{ display: 'inline' }}>
              Stripe
            </TypographyWrapper>
          </ExternalLink>
          **.
        </TypographyWrapper>
      </p>
      {hasCaveatForCountry && (
        <p>
          <TypographyWrapper variant="body1">
            <>
              *Please note, if you have recently changed your name, or in the rare case that Stripe
              requests additional information for account verification, you may be asked to
              {isSellingEnabledForAU &&
                ` upload identification documentation${isSingleOriginCountryAllowed ? ' ' : ', '}`}
              {isSellingEnabledForUS && (
                <>
                  {' '}
                  enter the last 4 digits of your{' '}
                  <ExternalLink
                    className={css.externalLink}
                    href="https://support.stripe.com/questions/date-of-birth-and-social-security-number-(ssn)-requirement-for-us-stripe-accounts"
                  >
                    <TypographyWrapper variant="body1" typographyOverrides={{ display: 'inline' }}>
                      SSN
                    </TypographyWrapper>
                  </ExternalLink>
                  {isSingleOriginCountryAllowed ? ' ' : ', '}
                </>
              )}
              and/or enter your date of birth. Please reach out to support@treet.co if you have any
              trouble or questions.
            </>
          </TypographyWrapper>
        </p>
      )}
      <p>
        <TypographyWrapper variant="body1">
          **Stripe has a PCI Service Provider Level 1 Certification - the highest level in the
          payments industry.
        </TypographyWrapper>
      </p>
    </>
  );
};

const HowToGetPaid: FC = () => {
  const { shopName, mainSite, creditPayoutOption, creditPayoutPercentage, cashPayoutPercentage } =
    useShopConfig();
  const { listingsTitle } = usePageTitles();

  const isPayoutGiftCard = isGiftCardOption(creditPayoutOption);
  const { doesBrandUseLoyaltyPoints, linkToBrandLoyaltyPointsPage } = useYotpo();
  const shopNameHyperlinked = (
    <ExternalLink className={css.externalLink} href={mainSite}>
      <TypographyWrapper variant="body1" typographyOverrides={{ display: 'inline' }}>
        {shopName}
      </TypographyWrapper>
    </ExternalLink>
  );
  const hasPayoutOptions = creditPayoutPercentage && cashPayoutPercentage;
  const payoutEmailInstructionsHelperText = hasPayoutOptions
    ? `asking how you’d like to redeem your funds: cash or credit. You can make your preferred selection by logging into your account and going to ‘${listingsTitle}’.`
    : 'with instructions on how to redeem your funds.';

  return (
    <StyledAccordion name="get-paid">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel14a-content"
        id="panel4a-header"
      >
        <p>
          <FAQTitle>How and when do I get paid?</FAQTitle>
        </p>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          <p>
            <TypographyWrapper variant="body1">
              Once your item is delivered and verified (manually by the buyer or auto-verified after
              72 hours — whichever is first), you’ll receive an email{' '}
              {payoutEmailInstructionsHelperText}
            </TypographyWrapper>
          </p>
          {!!creditPayoutPercentage && !doesBrandUseLoyaltyPoints && (
            <p>
              <TypographyWrapper variant="body1">
                To redeem your funds as credit, you’ll receive an email immediately upon
                verification with your {isPayoutGiftCard ? 'gift card' : 'discount code'} for use on{' '}
                {shopNameHyperlinked}.
              </TypographyWrapper>
            </p>
          )}
          {!!creditPayoutPercentage && doesBrandUseLoyaltyPoints && (
            <p>
              <TypographyWrapper variant="body1">
                To redeem your funds as credit in the form of loyalty points, your {shopName}{' '}
                rewards account will be credited and you will receive an email upon verification
                with details of your earned Yotpo loyalty points for use on {shopNameHyperlinked}.
                Learn more about the {shopName} loyalty program{' '}
                <ExternalLink className={css.externalLink} href={linkToBrandLoyaltyPointsPage}>
                  <TypographyWrapper variant="body1" typographyOverrides={{ display: 'inline' }}>
                    here.
                  </TypographyWrapper>
                </ExternalLink>
              </TypographyWrapper>
            </p>
          )}
          {!!cashPayoutPercentage && <CashPayout />}
        </Box>
      </AccordionDetails>
    </StyledAccordion>
  );
};

const StoreCredit: FC = () => {
  const { shopName, mainSite, creditPayoutOption, creditPayoutPercentage, cashPayoutPercentage } =
    useShopConfig();

  if (!creditPayoutPercentage) return null;

  const isPayoutGiftCard = isGiftCardOption(creditPayoutOption);
  const { doesBrandUseLoyaltyPoints, linkToBrandLoyaltyPointsPage } = useYotpo();
  const isCashOptionAvailable = !!cashPayoutPercentage;
  const shopNameHyperlinked = (
    <ExternalLink className={css.externalLink} href={mainSite}>
      <TypographyWrapper variant="body1" typographyOverrides={{ display: 'inline' }}>
        {shopName}
      </TypographyWrapper>
    </ExternalLink>
  );

  return (
    <StyledAccordion name="store-credit">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel9a-content"
        id="panel9a-header"
      >
        <p>
          <FAQTitle>How does the store credit work?</FAQTitle>
        </p>
      </AccordionSummary>
      <AccordionDetails>
        {doesBrandUseLoyaltyPoints && (
          <Box>
            <TypographyWrapper variant="body1">
              We issue store credit as loyalty points to be redeemed on {shopNameHyperlinked}. Your{' '}
              {shopName} rewards account will be credited{' '}
              {isCashOptionAvailable
                ? 'upon selecting the credit option. '
                : 'automatically once your item has been verified. '}
              Learn more about the {shopName} loyalty program{' '}
              <ExternalLink className={css.externalLink} href={linkToBrandLoyaltyPointsPage}>
                <TypographyWrapper variant="body1" typographyOverrides={{ display: 'inline' }}>
                  here.
                </TypographyWrapper>
              </ExternalLink>
            </TypographyWrapper>
          </Box>
        )}
        {!doesBrandUseLoyaltyPoints && (
          <Box>
            <p>
              <TypographyWrapper variant="body1">
                {isPayoutGiftCard ? (
                  <>
                    We issue store credit as gift cards to be redeemed on {shopNameHyperlinked}.
                    You’ll receive the gift card code via email{' '}
                    {isCashOptionAvailable
                      ? 'upon selecting the credit option.'
                      : 'automatically once your item has been verified.'}
                  </>
                ) : (
                  <>
                    We issue store credit as discount codes to be redeemed on {shopNameHyperlinked}.
                    You’ll receive the code via email{' '}
                    {isCashOptionAvailable
                      ? 'upon selecting the credit option'
                      : 'automatically once your item has been verified'}
                    . This credit will be valid for a single checkout on {shopNameHyperlinked}.
                    While you can buy multiple items at once with your credit, you will not be able
                    to use the same code again. Make sure to use all of it within one purchase.
                    Discounts cannot be stacked. Credit does not expire.
                  </>
                )}
              </TypographyWrapper>
            </p>
            {!isPayoutGiftCard && (
              <p>
                <TypographyWrapper variant="body1">
                  <>
                    If you’ve earned multiple discount codes and would like them combined, feel free
                    to contact us at{' '}
                    <a href="mailto:support@treet.co">
                      <Typography
                        variant="body1"
                        className={css.underlineText}
                        style={{ display: 'inline' }}
                      >
                        support@treet.co
                      </Typography>
                    </a>{' '}
                    — we’re happy to help!
                  </>
                </TypographyWrapper>
              </p>
            )}
          </Box>
        )}
      </AccordionDetails>
    </StyledAccordion>
  );
};

const MisrepresentationClaim: FC = () => {
  const {
    copy: { faqCopy },
  } = useShopConfig();

  return (
    <StyledAccordion name="misrepresentation-claim">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel10a-content"
        id="panel10a-header"
      >
        <p>
          <FAQTitle>
            {faqCopy[FAQ.MisrepresentationClaim]?.title ||
              'What happens if someone submits a Misrepresentation Claim?'}
          </FAQTitle>
        </p>
      </AccordionSummary>
      <AccordionDetails>
        <span>
          <p>
            <FAQDescription>
              {faqCopy[FAQ.MisrepresentationClaim]?.description ||
                `If your Buyer submits a Claim, we will notify you immediately and will review it in accordance with our Treet Protection Policy. Your funds will be on hold until a decision has been reached. If the Claim is accepted, we provide the Buyer with a prepaid shipping label to print and ship within 72 hours. If the Claim is rejected, we will automatically disburse your funds.`}
            </FAQDescription>
          </p>
        </span>
      </AccordionDetails>
    </StyledAccordion>
  );
};

const SellerProtection: FC = () => (
  <StyledAccordion name="seller-protection">
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel10a-content"
      id="panel10a-header"
    >
      <p>
        <FAQTitle>Am I protected if my item gets lost or stolen?</FAQTitle>
      </p>
    </AccordionSummary>
    <AccordionDetails>
      <span>
        <p>
          <TypographyWrapper variant="body1">
            As long as the item is initially scanned in by the post office, you’ll be paid in full
            if it were to get lost or stolen anywhere on its journey.
          </TypographyWrapper>
        </p>
      </span>
    </AccordionDetails>
  </StyledAccordion>
);

const SellerAdditional: FC = () => {
  const {
    copy: { sellerAdditionalFaq },
  } = useShopConfig();

  const buildFaqNameForAnchorLink = (title: string, index: number) =>
    `${title || `seller-additional-${index}`}`;

  return (
    <>
      {sellerAdditionalFaq.map((faq: FAQObject, index: number) => (
        <StyledAccordion
          key={buildFaqNameForAnchorLink(faq.title, index)}
          name={buildFaqNameForAnchorLink(faq.title, index)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel10a-content"
            id="panel10a-header"
          >
            <p>
              <FAQTitle>{faq.title}</FAQTitle>
            </p>
          </AccordionSummary>
          <AccordionDetails>
            <span>
              <p>
                <FAQDescription>{faq.description}</FAQDescription>
              </p>
            </span>
          </AccordionDetails>
        </StyledAccordion>
      ))}
    </>
  );
};

const SellerFAQ: FC = () => {
  // TODO (SY|TREET-1066): Once all fields are moved to
  // contentful, refactor this to use shopconfigV2
  const {
    copy: { sellerAdditionalFaq, faqCopy },
  } = useShopConfig();

  return (
    <div className={css.root}>
      {faqCopy[FAQ.SellRequirements] && <SellRequirements />}
      <WhySell />
      <HowToSell />
      <ListingReviewProcess />
      <HowToShip />
      <SellingFees />
      <HowToGetPaid />
      <StoreCredit />
      <MisrepresentationClaim />
      <SellerProtection />
      {sellerAdditionalFaq && <SellerAdditional />}
    </div>
  );
};

export default SellerFAQ;
