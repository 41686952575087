import {
  BundleItemHistoricalStatus,
  CoreBundleItem,
} from '../types/apollo/generated/types.generated';

const hasTransitionedFn =
  (transition: BundleItemHistoricalStatus) => (bundleItem: CoreBundleItem) =>
    bundleItem.historicalStatuses.includes(transition);

export const itemHasBeenDelivered = hasTransitionedFn(BundleItemHistoricalStatus.Delivered);
export const itemHasBeenVerified = hasTransitionedFn(BundleItemHistoricalStatus.Verified);
export const itemHasBeenCanceled = hasTransitionedFn(BundleItemHistoricalStatus.Canceled);
export const itemHasBeenDisputed = hasTransitionedFn(BundleItemHistoricalStatus.Disputed);
export const itemHasBeenDisputeResolved = hasTransitionedFn(
  BundleItemHistoricalStatus.DisputeResolved
);
export const itemHasBeenPaidOut = hasTransitionedFn(BundleItemHistoricalStatus.PaidOut);

export const itemHasBeenDisputedAndNotVerified = (item: CoreBundleItem) =>
  itemHasBeenDisputed(item) && !itemHasBeenVerified(item);

export const findItemForSharetribeListing = (
  bundleItems: CoreBundleItem[],
  sharetribeListingId: string
): CoreBundleItem | undefined =>
  bundleItems.find((bundleItem) => bundleItem.listing.sharetribeListingId === sharetribeListingId);
