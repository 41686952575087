/* eslint-disable import/prefer-default-export */
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';

const raveivalSizes = [
  'XXS',
  'XS',
  'S',
  'M',
  'L',
  'XL',
  'XXL',
  '1X',
  '2X',
  '3X',
  '4X',
  '5X',
  'One Size',
];

export const raveivalShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: raveivalSizes }),
  ],
  relistPurchasedListingActionText: 'reRave It',
  profileBioInfoCopy:
    'Introduce yourself to the community, talk about your festival style, and include anything else you want to share ◡̈',
};
