import React from 'react';
import { useIconColor } from '../../../hooks/useIconColor';
import { CustomIconType, useCustomIcon } from '../../../hooks/useCustomIcon';
import CustomIcon from '../../CustomIcon/CustomIcon';

interface IconUserFilled {
  className?: string;
  color?: string;
}

const IconUser: React.FC<IconUserFilled> = (props) => {
  const { className, color } = props;

  const { iconColor } = useIconColor();
  const customUserIcon = useCustomIcon(CustomIconType.User);

  if (customUserIcon) {
    return <CustomIcon url={customUserIcon.icon.url} altText="user" className={className} />;
  }

  const fillColor = color || iconColor;

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        fill={fillColor}
        d="M16 15C18.7614 15 21 12.7614 21 10C21 7.23858 18.7614 5 16 5C13.2386 5 11 7.23858 11 10C11 12.7614 13.2386 15 16 15Z"
      />
      <path
        fill={fillColor}
        d="M7.76479 20.2477C6.74507 21.1955 6.74507 22.8045 7.76479 23.7523C9.93489 25.7693 12.8259 27 16 27C19.1741 27 22.0651 25.7693 24.2352 23.7523C25.2549 22.8045 25.2549 21.1955 24.2352 20.2477C22.0651 18.2307 19.1741 17 16 17C12.8259 17 9.93489 18.2307 7.76479 20.2477Z"
      />
    </svg>
  );
};

export default IconUser;
