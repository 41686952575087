// THIS FILE IS GENERATED, DO NOT EDIT!
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  Json: any;
};

export enum Actor {
  Admin = 'ADMIN',
  Buyer = 'BUYER',
  Seller = 'SELLER',
}

export type Address = {
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  billingOrder?: Maybe<Order>;
  city: Scalars['String'];
  country: Scalars['String'];
  createdAt: Scalars['DateTime'];
  fullName: Scalars['String'];
  id: Scalars['ID'];
  phone?: Maybe<Scalars['String']>;
  shippingOrder?: Maybe<Order>;
  state: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  zip: Scalars['String'];
};

export type AddressPayload = {
  address: Address;
};

export type AdminUpdateBundleInput = {
  id: Scalars['ID'];
  verifyAt?: Maybe<Scalars['DateTime']>;
};

export type Bundle = {
  bundleItems: Array<BundleItem>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  creditCode?: Maybe<CreditCode>;
  currency: Currency;
  expireAt?: Maybe<Scalars['DateTime']>;
  fulfillment?: Maybe<Fulfillment>;
  id: Scalars['ID'];
  isBrandDirect: Scalars['Boolean'];
  isMarkedFulfilled?: Maybe<Scalars['Boolean']>;
  lineItems: Array<LineItem>;
  nextAutoTransition?: Maybe<BundleAutomaticTransition>;
  order: Order;
  payoutOption?: Maybe<PayoutType>;
  refunds: Array<Refund>;
  returnFulfillment?: Maybe<Fulfillment>;
  sharetribeSellerId: Scalars['String'];
  sharetribeTransactionIds: Array<Scalars['String']>;
  status: BundleStatus;
  type?: Maybe<BundleType>;
  updatedAt: Scalars['DateTime'];
  verifyAt?: Maybe<Scalars['DateTime']>;
};

export type BundleAutomaticTransition = {
  nextStatus: BundleStatus;
  transition: BundleTransition;
  transitionAt: Scalars['DateTime'];
};

export type BundleItem = {
  bundle: Bundle;
  createdAt: Scalars['DateTime'];
  dispute?: Maybe<Dispute>;
  historicalStatuses: Array<BundleItemHistoricalStatus>;
  id: Scalars['ID'];
  listing: Listing;
  status?: Maybe<ItemStatus>;
  updatedAt: Scalars['DateTime'];
};

export enum BundleItemHistoricalStatus {
  Canceled = 'CANCELED',
  Delivered = 'DELIVERED',
  Disputed = 'DISPUTED',
  DisputeResolved = 'DISPUTE_RESOLVED',
  PaidOut = 'PAID_OUT',
  Verified = 'VERIFIED',
}

export type BundleItemPayload = {
  bundleItem: BundleItem;
};

export type BundlePayload = {
  bundle: Bundle;
};

export enum BundleStatus {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Delivered = 'DELIVERED',
  Disputed = 'DISPUTED',
  Initiated = 'INITIATED',
  Open = 'OPEN',
  Resolved = 'RESOLVED',
  Shipped = 'SHIPPED',
}

export enum BundleTransition {
  ExpireBundle = 'EXPIRE_BUNDLE',
  ExpireDispute = 'EXPIRE_DISPUTE',
  VerifyBundle = 'VERIFY_BUNDLE',
}

export enum BundleType {
  Marketplace = 'MARKETPLACE',
  TradeIn = 'TRADE_IN',
}

export enum Cadence {
  Immediately = 'IMMEDIATELY',
  Never = 'NEVER',
  Weekly = 'WEEKLY',
}

export enum CancelReason {
  CanceledFromShopify = 'CANCELED_FROM_SHOPIFY',
  KeepItem = 'KEEP_ITEM',
  Other = 'OTHER',
  ShippingExpired = 'SHIPPING_EXPIRED',
  SoldElsewhere = 'SOLD_ELSEWHERE',
}

export type Cancellation = {
  bundle: Bundle;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  initiatedBy: Actor;
  note?: Maybe<Scalars['String']>;
  reason: CancelReason;
  updatedAt: Scalars['DateTime'];
};

export type CancellationPayload = {
  cancellation: Cancellation;
};

export type CartItem = {
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  listing: Listing;
  listingId: Scalars['String'];
  quantity: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
};

export type CartItemDeletePayload = {
  count: Scalars['Int'];
};

export type CartItemPayload = {
  cartItem: CartItem;
};

export enum CountryCode {
  Au = 'AU',
  Ca = 'CA',
  Gb = 'GB',
  Us = 'US',
}

export type CreateCancellationInput = {
  bundleId: Scalars['String'];
  bundleItemIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  initiatedBy: Actor;
  note?: Maybe<Scalars['String']>;
  reason: CancelReason;
};

export type CreateCartItemInput = {
  sharetribeListingId: Scalars['String'];
};

export type CreateFavoritedItemInput = {
  sharetribeListingId: Scalars['String'];
};

export type CreateFulfillmentInput = {
  bundleId: Scalars['ID'];
  isReturn?: Maybe<Scalars['Boolean']>;
  trackingNumber: Scalars['String'];
  trackingURL: Scalars['String'];
};

export type CreateListingFeedbackInput = {
  /** Corresponds to the feedback categories displayed in the frontend. */
  category: Scalars['String'];
  email: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  sharetribeListingId: Scalars['String'];
  sharetribeUserId?: Maybe<Scalars['String']>;
};

export type CreateRefundInput = {
  bundleId: Scalars['String'];
  bundleItemIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  currency: Currency;
  note?: Maybe<Scalars['String']>;
  price: Scalars['Int'];
  refundedLineItemIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  type: RefundType;
};

export type CreateUserInput = {
  createdFromShopId: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  sharetribeUserId: Scalars['String'];
};

export type CreditCode = {
  amount: Scalars['Int'];
  bundle: Bundle;
  code?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  currency: Currency;
  id: Scalars['ID'];
  /** Codes that were combined and no longer active */
  inactiveCodes: Array<Scalars['String']>;
  provider: CreditCodeProvider;
  type: CreditCodeType;
  updatedAt: Scalars['DateTime'];
};

export enum CreditCodeProvider {
  Bigcommerce = 'BIGCOMMERCE',
  Dopple = 'DOPPLE',
  RiseAi = 'RISE_AI',
  Shopify = 'SHOPIFY',
  TeamworkCommerce = 'TEAMWORK_COMMERCE',
  Yotpo = 'YOTPO',
}

export enum CreditCodeType {
  DiscountCode = 'DISCOUNT_CODE',
  GiftCard = 'GIFT_CARD',
  LoyaltyPoints = 'LOYALTY_POINTS',
}

export enum Currency {
  Aud = 'AUD',
  Cad = 'CAD',
  Gbp = 'GBP',
  Usd = 'USD',
}

export type Disbursement = {
  /** Stored in smallest unit */
  additionalCostsTotal: Scalars['Int'];
  additionalLineItems?: Maybe<Scalars['Json']>;
  /** Stored in smallest unit */
  brandCoveredDiscountsTotal?: Maybe<Scalars['Int']>;
  /** Stored in smallest unit */
  brandCoveredShippingCostTotal?: Maybe<Scalars['Int']>;
  /** Stored in smallest unit */
  brandDirectSalesGMVTotal?: Maybe<Scalars['Int']>;
  /** Stored in smallest unit */
  brandDirectSalesPayoutTotal: Scalars['Int'];
  bundles: Array<Bundle>;
  /** Stored in smallest unit */
  ccProcessingTotal: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  currency: Currency;
  /** Stored in smallest unit */
  customerPaidTotal: Scalars['Int'];
  disbursedToEmail?: Maybe<Scalars['String']>;
  /** Stored in smallest unit */
  discountsTotal?: Maybe<Scalars['Int']>;
  /** Stored in smallest unit */
  earningsTotal: Scalars['Int'];
  endDate: Scalars['DateTime'];
  /** Stored in smallest unit */
  finalDepositTotal?: Maybe<Scalars['Int']>;
  /** Stored in smallest unit */
  grossMarginTotal: Scalars['Int'];
  /** Stored in smallest unit */
  grossMerchandiseValueTotal: Scalars['Int'];
  id: Scalars['ID'];
  /** Stored in smallest unit */
  peerCashSalesGMVTotal?: Maybe<Scalars['Int']>;
  /** Stored in smallest unit */
  peerCreditSalesGMVTotal?: Maybe<Scalars['Int']>;
  /** Stored in smallest unit */
  peerSellerPayoutTotal?: Maybe<Scalars['Int']>;
  /** Stored in smallest unit */
  previouslyDepositedTotal?: Maybe<Scalars['Int']>;
  /** Stored in smallest unit */
  reimbursedShippingCostTotal?: Maybe<Scalars['Int']>;
  /** Stored in smallest unit */
  reimbursementsTotal: Scalars['Int'];
  /** Stored in smallest unit */
  relistedTradeInSalesGMVTotal?: Maybe<Scalars['Int']>;
  /** Stored in smallest unit */
  relistedTradeInSalesPayoutTotal: Scalars['Int'];
  /** Stored in smallest unit */
  returnInsuranceTotal?: Maybe<Scalars['Int']>;
  /** Stored in smallest unit */
  returnShippingCostTotal?: Maybe<Scalars['Int']>;
  /** Stored in smallest unit */
  salesCostTotal: Scalars['Int'];
  /** Stored in smallest unit */
  shippingTotal?: Maybe<Scalars['Int']>;
  shop: Shop;
  startDate: Scalars['DateTime'];
  status: DisbursementStatus;
  /** Stored in smallest unit */
  taxTotal?: Maybe<Scalars['Int']>;
  /** Stored in smallest unit */
  treetTakeTotal: Scalars['Int'];
  type: DisbursementType;
  updatedAt: Scalars['DateTime'];
};

export enum DisbursementStatus {
  AwaitingBankInfo = 'AWAITING_BANK_INFO',
  Failure = 'FAILURE',
  Initiated = 'INITIATED',
  PaidOut = 'PAID_OUT',
}

export enum DisbursementType {
  Disbursement = 'DISBURSEMENT',
  Report = 'REPORT',
}

export type DiscountLineItem = {
  createdAt: Scalars['DateTime'];
  currency: Currency;
  id: Scalars['ID'];
  lineItem: LineItem;
  metadata: Scalars['Json'];
  target: DiscountTarget;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  value: Scalars['Float'];
  valueType: DiscountValueType;
};

export enum DiscountTarget {
  All = 'ALL',
}

export enum DiscountValueType {
  FixedAmount = 'FIXED_AMOUNT',
  Percentage = 'PERCENTAGE',
}

export type Dispute = {
  bundle: Bundle;
  bundleItem: BundleItem;
  createdAt: Scalars['DateTime'];
  expireAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  reason: DisputeReason;
  status: DisputeStatus;
  updatedAt: Scalars['DateTime'];
};

export type DisputePayload = {
  dispute: Dispute;
};

export enum DisputeReason {
  ConditionAdjustment = 'CONDITION_ADJUSTMENT',
  DamagedButAcceptable = 'DAMAGED_BUT_ACCEPTABLE',
  Inauthentic = 'INAUTHENTIC',
  InauthenticOrExcessiveDamage = 'INAUTHENTIC_OR_EXCESSIVE_DAMAGE',
  Missing = 'MISSING',
  None = 'NONE',
  NotAcceptable = 'NOT_ACCEPTABLE',
  NotAsDescribed = 'NOT_AS_DESCRIBED',
  Other = 'OTHER',
  WrongItem = 'WRONG_ITEM',
}

export enum DisputeStatus {
  Accepted = 'ACCEPTED',
  Expired = 'EXPIRED',
  Open = 'OPEN',
  Rejected = 'REJECTED',
}

export type DisputesPayload = {
  disputes: Array<Dispute>;
};

export enum EmailSubscribeSource {
  Checkout = 'CHECKOUT',
  Footer = 'FOOTER',
  LandingPageModal = 'LANDING_PAGE_MODAL',
  ListingPageIso = 'LISTING_PAGE_ISO',
  NotificationSettings = 'NOTIFICATION_SETTINGS',
  ProfileDropdown = 'PROFILE_DROPDOWN',
  ProfileDropdownIso = 'PROFILE_DROPDOWN_ISO',
  SearchResultsBanner = 'SEARCH_RESULTS_BANNER',
  SearchResultsBannerIso = 'SEARCH_RESULTS_BANNER_ISO',
  TreetShopFooter = 'TREET_SHOP_FOOTER',
}

export type ExtendBundleShippingInput = {
  extendedShipDate: Scalars['DateTime'];
  id: Scalars['ID'];
};

export type ExtendDisputesExpirationDateInput = {
  bundleId: Scalars['String'];
  extendedExpirationDate: Scalars['DateTime'];
};

export type FavoritedItem = {
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  listing: Listing;
  listingId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
};

export type FavoritedItemPayload = {
  favoritedItem: FavoritedItem;
};

export type Fulfillment = {
  bundle?: Maybe<Bundle>;
  createdAt: Scalars['DateTime'];
  deliveredAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isBrandFulfilled?: Maybe<Scalars['Boolean']>;
  labelURL?: Maybe<Scalars['String']>;
  method?: Maybe<FulfillmentMethod>;
  returnBundle?: Maybe<Bundle>;
  shippingLabelProvider?: Maybe<ShippingLabelProvider>;
  shippoObjectId?: Maybe<Scalars['String']>;
  trackingNumber: Scalars['String'];
  trackingURL: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  weight: Scalars['Float'];
  weightUnit: WeightUnit;
};

export enum FulfillmentMethod {
  RetailReworks = 'RETAIL_REWORKS',
  Shopify = 'SHOPIFY',
  Treet = 'TREET',
}

export enum ItemStatus {
  Canceled = 'CANCELED',
  DisputeAccepted = 'DISPUTE_ACCEPTED',
  DisputeOpen = 'DISPUTE_OPEN',
  DisputeResolved = 'DISPUTE_RESOLVED',
  PaidOut = 'PAID_OUT',
  Refunded = 'REFUNDED',
  ReturnInitiated = 'RETURN_INITIATED',
  Verified = 'VERIFIED',
}

export type LineItem = {
  bundle?: Maybe<Bundle>;
  code: LineItemCode;
  createdAt: Scalars['DateTime'];
  discountLineItem?: Maybe<DiscountLineItem>;
  id: Scalars['ID'];
  includeFor: Array<Participant>;
  listingLineItem?: Maybe<ListingLineItem>;
  name: Scalars['String'];
  order: Order;
  returnInsuranceLineItem?: Maybe<ReturnInsuranceLineItem>;
  shippingLineItem?: Maybe<ShippingLineItem>;
  taxLineItem?: Maybe<TaxLineItem>;
  updatedAt: Scalars['DateTime'];
};

export enum LineItemCode {
  Discount = 'DISCOUNT',
  Listing = 'LISTING',
  ReturnInsurance = 'RETURN_INSURANCE',
  Shipping = 'SHIPPING',
  Tax = 'TAX',
}

export type Listing = {
  availableInventory?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  currency: Currency;
  /** Sourced from Shopify Product Variant */
  externalSku?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isBrandDirect?: Maybe<Scalars['Boolean']>;
  /** Brand of item if it differs from shop's brand */
  itemBrand?: Maybe<Scalars['String']>;
  itemType?: Maybe<ListingItemType>;
  price: Scalars['Int'];
  seller: User;
  sharetribeListingId: Scalars['String'];
  sharetribePublicData?: Maybe<Scalars['Json']>;
  shipFromCountry?: Maybe<CountryCode>;
  shop: Shop;
  sourceListing?: Maybe<Listing>;
  state?: Maybe<ListingState>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ListingFeedback = {
  /** Corresponds to the feedback categories displayed in the frontend. */
  category: Scalars['String'];
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  feedbackAuthor?: Maybe<User>;
  id: Scalars['ID'];
  listing?: Maybe<Listing>;
  note?: Maybe<Scalars['String']>;
  sharetribeListingId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ListingFeedbackPayload = {
  listingFeedback: ListingFeedback;
};

export enum ListingItemType {
  Marketplace = 'MARKETPLACE',
  TradeIn = 'TRADE_IN',
}

export type ListingLineItem = {
  createdAt: Scalars['DateTime'];
  currency: Currency;
  id: Scalars['ID'];
  lineItem: LineItem;
  price: Scalars['Int'];
  quantity: Scalars['Int'];
  sharetribeListingId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ListingPayload = {
  listing: Listing;
};

export enum ListingState {
  Closed = 'CLOSED',
  Open = 'OPEN',
  PendingApproval = 'PENDING_APPROVAL',
}

export enum Locale {
  EnAu = 'EN_AU',
  EnCa = 'EN_CA',
  EnGb = 'EN_GB',
  EnUs = 'EN_US',
  FrCa = 'FR_CA',
}

export type Mutation = {
  adminUpdateBundle: BundlePayload;
  createCancellation: CancellationPayload;
  createCartItem: Array<CartItem>;
  createFavoritedItem: FavoritedItemPayload;
  createFulfillment: Fulfillment;
  createListingFeedback: ListingFeedbackPayload;
  createUser: UserPayload;
  extendBundleShipping: BundlePayload;
  extendDisputesExpirationDate: DisputesPayload;
  removeCartItem: Array<CartItem>;
  removeFavoritedItem: FavoritedItemPayload;
  transitionBundleItemToCanceled: BundleItemPayload;
  transitionBundleItemToDisputeAccepted: BundleItemPayload;
  transitionBundleItemToDisputeRejected: BundleItemPayload;
  transitionBundleItemToPaidOut: BundleItemPayload;
  transitionBundleItemToRefunded: BundleItemPayload;
  transitionBundleItemToVerified: BundleItemPayload;
  transitionBundleToDelivered: BundlePayload;
  transitionBundleToShipped: BundlePayload;
  updateAddress: AddressPayload;
  updateBundle: BundlePayload;
  updateBundleItem: BundleItemPayload;
  updateUser: UserPayload;
  upsertDispute: DisputePayload;
  upsertGeneralSavedSearch: SavedSearchPayload;
  upsertISOSavedSearch: SavedSearchPayload;
  upsertListing: ListingPayload;
  upsertPriceDropConfig: PriceDropConfigPayload;
  verifyBundle: BundlePayload;
};

export type MutationAdminUpdateBundleArgs = {
  input: AdminUpdateBundleInput;
};

export type MutationCreateCancellationArgs = {
  input: CreateCancellationInput;
};

export type MutationCreateCartItemArgs = {
  input: CreateCartItemInput;
};

export type MutationCreateFavoritedItemArgs = {
  input: CreateFavoritedItemInput;
};

export type MutationCreateFulfillmentArgs = {
  input: CreateFulfillmentInput;
};

export type MutationCreateListingFeedbackArgs = {
  input: CreateListingFeedbackInput;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationExtendBundleShippingArgs = {
  input: ExtendBundleShippingInput;
};

export type MutationExtendDisputesExpirationDateArgs = {
  input: ExtendDisputesExpirationDateInput;
};

export type MutationRemoveCartItemArgs = {
  input: RemoveCartItemsInput;
};

export type MutationRemoveFavoritedItemArgs = {
  input: RemoveFavoritedItemInput;
};

export type MutationTransitionBundleItemToCanceledArgs = {
  input: TransitionBundleItemToCanceledInput;
};

export type MutationTransitionBundleItemToDisputeAcceptedArgs = {
  input: TransitionDisputedBundleItemInput;
};

export type MutationTransitionBundleItemToDisputeRejectedArgs = {
  input: TransitionDisputedBundleItemInput;
};

export type MutationTransitionBundleItemToPaidOutArgs = {
  input: TransitionBundleItemToPaidOutInput;
};

export type MutationTransitionBundleItemToRefundedArgs = {
  input: TransitionBundleItemToRefundedInput;
};

export type MutationTransitionBundleItemToVerifiedArgs = {
  input: TransitionBundleItemToVerifiedInput;
};

export type MutationTransitionBundleToDeliveredArgs = {
  input: TransitionBundleInput;
};

export type MutationTransitionBundleToShippedArgs = {
  input: TransitionBundleInput;
};

export type MutationUpdateAddressArgs = {
  input: UpdateAddressInput;
};

export type MutationUpdateBundleArgs = {
  input: UpdateBundleInput;
};

export type MutationUpdateBundleItemArgs = {
  input: UpdateBundleItemInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationUpsertDisputeArgs = {
  input: UpsertDisputeInput;
};

export type MutationUpsertGeneralSavedSearchArgs = {
  input: UpsertGeneralSavedSearchInput;
};

export type MutationUpsertIsoSavedSearchArgs = {
  input: UpsertIsoSavedSearchInput;
};

export type MutationUpsertListingArgs = {
  input: UpsertListingInput;
};

export type MutationUpsertPriceDropConfigArgs = {
  input: UpsertPriceDropConfigInput;
};

export type MutationVerifyBundleArgs = {
  input: TransitionBundleInput;
};

export type Order = {
  billingAddress?: Maybe<Address>;
  bundles: Array<Bundle>;
  buyer?: Maybe<User>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  lineItems: Array<LineItem>;
  sharetribeBuyerId: Scalars['String'];
  shippingAddress: Address;
  updatedAt: Scalars['DateTime'];
};

export enum Participant {
  Brand = 'BRAND',
  Buyer = 'BUYER',
  Seller = 'SELLER',
}

export enum PaymentMethod {
  ApplePay = 'APPLE_PAY',
  Card = 'CARD',
  GooglePay = 'GOOGLE_PAY',
}

export enum PayoutType {
  Cash = 'CASH',
  Credit = 'CREDIT',
}

export type PriceDropConfig = {
  anchorPrice: Scalars['Int'];
  daysBetweenPriceDrops: Scalars['Int'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  listing: Listing;
  minPrice: Scalars['Int'];
  nextScheduledDrop?: Maybe<Scalars['DateTime']>;
  percentageDrop: Scalars['Int'];
};

export type PriceDropConfigPayload = {
  priceDropConfig: PriceDropConfig;
};

export type Query = {
  brandDisbursements: Array<Disbursement>;
  brandReports: Array<Disbursement>;
  bundle?: Maybe<Bundle>;
  bundles: Array<Bundle>;
  cartItems: Array<CartItem>;
  disbursement?: Maybe<Disbursement>;
  favoritedItems: Array<FavoritedItem>;
  isoSavedSearches: Array<SavedSearch>;
  order?: Maybe<Order>;
  orders: Array<Order>;
  priceDropConfig?: Maybe<PriceDropConfig>;
  savedSearch?: Maybe<SavedSearch>;
  shop?: Maybe<Shop>;
  shopByHostname?: Maybe<Shop>;
};

export type QueryBrandDisbursementsArgs = {
  offset?: Maybe<Scalars['Int']>;
  shopId: Scalars['String'];
};

export type QueryBrandReportsArgs = {
  cursor?: Maybe<Scalars['String']>;
  shopId: Scalars['String'];
};

export type QueryBundleArgs = {
  id: Scalars['String'];
};

export type QueryBundlesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sharetribeListingIds?: Maybe<Array<Scalars['String']>>;
  sharetribeSellerId: Scalars['String'];
  shopName?: Maybe<Scalars['String']>;
  status?: Maybe<BundleStatus>;
};

export type QueryDisbursementArgs = {
  id: Scalars['String'];
};

export type QueryIsoSavedSearchesArgs = {
  email: Scalars['String'];
  treetId: Scalars['String'];
};

export type QueryOrderArgs = {
  id: Scalars['String'];
};

export type QueryOrdersArgs = {
  bundleStatus?: Maybe<BundleStatus>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  searchQuery?: Maybe<Scalars['String']>;
  sharetribeBuyerId: Scalars['String'];
  shopName?: Maybe<Scalars['String']>;
};

export type QueryPriceDropConfigArgs = {
  isActive?: Maybe<Scalars['Boolean']>;
  listingId?: Maybe<Scalars['ID']>;
  sharetribeListingId?: Maybe<Scalars['ID']>;
};

export type QuerySavedSearchArgs = {
  email: Scalars['String'];
  treetId: Scalars['String'];
  type?: Maybe<SavedSearchType>;
};

export type QueryShopArgs = {
  treetId: Scalars['String'];
};

export type QueryShopByHostnameArgs = {
  hostname: Scalars['String'];
};

export type Refund = {
  bundle: Bundle;
  createdAt: Scalars['DateTime'];
  currency: Currency;
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  price: Scalars['Int'];
  refundedLineItems: Array<LineItem>;
  type: RefundType;
  updatedAt: Scalars['DateTime'];
};

export type RefundPayload = {
  refund: Refund;
};

export enum RefundType {
  Cancellation = 'CANCELLATION',
  Dispute = 'DISPUTE',
}

export type RemoveCartItemsInput = {
  sharetribeListingIds: Array<Scalars['String']>;
};

export type RemoveFavoritedItemInput = {
  sharetribeListingId: Scalars['String'];
};

export type ReturnInsuranceLineItem = {
  createdAt: Scalars['DateTime'];
  currency: Currency;
  id: Scalars['ID'];
  lineItem: LineItem;
  price: Scalars['Int'];
  provider: ReturnInsuranceProvider;
  /** For Seel, this is the policy ID */
  providerId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export enum ReturnInsuranceProvider {
  Seel = 'SEEL',
}

export type SavedSearch = {
  cadence: Cadence;
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  id: Scalars['ID'];
  search: Scalars['Json'];
  shop: Shop;
  /** Email Sign Up Attribution */
  subscribeSource: EmailSubscribeSource;
  type: SavedSearchType;
  updatedAt: Scalars['DateTime'];
};

export type SavedSearchPayload = {
  metadata?: Maybe<SavedSearchPayloadMetadata>;
  savedSearch: SavedSearch;
};

export type SavedSearchPayloadMetadata = {
  created?: Maybe<Scalars['Boolean']>;
  updated?: Maybe<Scalars['Boolean']>;
};

export enum SavedSearchType {
  General = 'GENERAL',
  InSearchOf = 'IN_SEARCH_OF',
}

export enum ShippingLabelProvider {
  Shopify = 'SHOPIFY',
  SuperCircle = 'SUPER_CIRCLE',
  Treet = 'TREET',
}

export type ShippingLineItem = {
  createdAt: Scalars['DateTime'];
  currency: Currency;
  id: Scalars['ID'];
  lineItem: LineItem;
  price: Scalars['Int'];
  quantity: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type Shop = {
  brandName: Scalars['String'];
  canonicalRootUrl: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** Currency of the brand's Shopify account. */
  defaultCurrency?: Maybe<Currency>;
  hostname: Scalars['String'];
  id: Scalars['ID'];
  primaryLocale: Locale;
  savedSearches: Array<SavedSearch>;
  treetId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type TaxLineItem = {
  createdAt: Scalars['DateTime'];
  currency: Currency;
  id: Scalars['ID'];
  metadata: Scalars['Json'];
  rate: Scalars['Float'];
  taxCode: Scalars['String'];
  taxId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  value: Scalars['Float'];
};

export type TransitionBundleInput = {
  id: Scalars['ID'];
};

export type TransitionBundleItemToCanceledInput = {
  bundleItemId: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  reason: CancelReason;
};

export type TransitionBundleItemToPaidOutInput = {
  bundleItemId: Scalars['String'];
  type: PayoutType;
};

export type TransitionBundleItemToRefundedInput = {
  bundleItemId: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  type: RefundType;
};

export type TransitionBundleItemToVerifiedInput = {
  id: Scalars['ID'];
};

export type TransitionDisputedBundleItemInput = {
  id: Scalars['ID'];
};

export type UpdateAddressInput = {
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['ID'];
  state: Scalars['String'];
  zip: Scalars['String'];
};

export type UpdateBundleInput = {
  canceledAt?: Maybe<Scalars['DateTime']>;
  expireAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isMarkedFulfilled?: Maybe<Scalars['Boolean']>;
  payoutOption?: Maybe<PayoutType>;
  status?: Maybe<BundleStatus>;
};

export type UpdateBundleItemInput = {
  id: Scalars['ID'];
  status: ItemStatus;
};

export type UpdateUserInput = {
  email: Scalars['String'];
  newEmail?: Maybe<Scalars['String']>;
  stripeAccountId?: Maybe<Scalars['String']>;
};

export type UpsertDisputeInput = {
  bundleId: Scalars['String'];
  bundleItemId: Scalars['String'];
  itemStatus?: Maybe<ItemStatus>;
  note?: Maybe<Scalars['String']>;
  reason: DisputeReason;
  status: DisputeStatus;
};

export type UpsertGeneralSavedSearchInput = {
  cadence: Cadence;
  email: Scalars['String'];
  search: Scalars['Json'];
  shopId: Scalars['String'];
  /** Email Sign Up Attribution */
  subscribeSource: EmailSubscribeSource;
};

export type UpsertIsoSavedSearchInput = {
  cadence: Cadence;
  email: Scalars['String'];
  search: Scalars['Json'];
  shopId: Scalars['String'];
  /** Email Sign Up Attribution */
  subscribeSource: EmailSubscribeSource;
};

export type UpsertListingInput = {
  availableInventory?: Maybe<Scalars['Int']>;
  currency: Currency;
  /** Sourced from Shopify Product Variant */
  externalSku?: Maybe<Scalars['String']>;
  isBrandDirect?: Maybe<Scalars['Boolean']>;
  /** Brand of item if it differs from shop's brand */
  itemBrand?: Maybe<Scalars['String']>;
  itemType?: Maybe<ListingItemType>;
  price: Scalars['Int'];
  sharetribeListingId: Scalars['String'];
  sharetribePublicData?: Maybe<Scalars['Json']>;
  sharetribeSellerId: Scalars['String'];
  sharetribeSourceListingId?: Maybe<Scalars['String']>;
  shipFromCountry?: Maybe<CountryCode>;
  shopId: Scalars['String'];
  state?: Maybe<ListingState>;
  title: Scalars['String'];
};

export type UpsertPriceDropConfigInput = {
  anchorPrice: Scalars['Int'];
  daysBetweenPriceDrops: Scalars['Int'];
  isActive: Scalars['Boolean'];
  listingId: Scalars['String'];
  minPrice: Scalars['Int'];
  nextScheduledDrop?: Maybe<Scalars['DateTime']>;
  percentageDrop: Scalars['Int'];
};

export type User = {
  createdAt: Scalars['DateTime'];
  createdFromShop: Shop;
  displayName: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  sharetribeUserId: Scalars['String'];
  stripeAccountId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type UserPayload = {
  user: User;
};

export enum WeightUnit {
  G = 'G',
  Oz = 'OZ',
}

export type CoreAddress = {
  id: string;
  addressLine1: string;
  addressLine2?: Maybe<string>;
  city: string;
  state: string;
  country: string;
  zip: string;
  fullName: string;
  phone?: Maybe<string>;
};

export type CoreBundle = {
  id: string;
  createdAt: any;
  expireAt?: Maybe<any>;
  sharetribeTransactionIds: Array<string>;
  status: BundleStatus;
  isMarkedFulfilled?: Maybe<boolean>;
  type?: Maybe<BundleType>;
  isBrandDirect: boolean;
  refunds: Array<{
    id: string;
    currency: Currency;
    price: number;
    type: RefundType;
    refundedLineItems: Array<{ id: string }>;
  }>;
  lineItems: Array<{
    id: string;
    name: string;
    code: LineItemCode;
    includeFor: Array<Participant>;
    listingLineItem?: Maybe<{
      id: string;
      currency: Currency;
      price: number;
      quantity: number;
      sharetribeListingId: string;
    }>;
    shippingLineItem?: Maybe<{ id: string; currency: Currency; price: number; quantity: number }>;
    discountLineItem?: Maybe<{
      id: string;
      title: string;
      currency: Currency;
      value: number;
      valueType: DiscountValueType;
      target: DiscountTarget;
      metadata: any;
    }>;
    returnInsuranceLineItem?: Maybe<{
      id: string;
      currency: Currency;
      price: number;
      provider: ReturnInsuranceProvider;
      providerId: string;
    }>;
    taxLineItem?: Maybe<{
      id: string;
      taxId: string;
      taxCode: string;
      currency: Currency;
      value: number;
      rate: number;
      metadata: any;
    }>;
  }>;
  fulfillment?: Maybe<{
    id: string;
    trackingURL: string;
    trackingNumber: string;
    labelURL?: Maybe<string>;
    weightUnit: WeightUnit;
    weight: number;
    shippingLabelProvider?: Maybe<ShippingLabelProvider>;
    deliveredAt?: Maybe<any>;
  }>;
  returnFulfillment?: Maybe<{
    id: string;
    trackingURL: string;
    trackingNumber: string;
    labelURL?: Maybe<string>;
    weightUnit: WeightUnit;
    weight: number;
    shippingLabelProvider?: Maybe<ShippingLabelProvider>;
    deliveredAt?: Maybe<any>;
  }>;
  bundleItems: Array<{
    id: string;
    status?: Maybe<ItemStatus>;
    historicalStatuses: Array<BundleItemHistoricalStatus>;
    dispute?: Maybe<{ id: string; reason: DisputeReason; note?: Maybe<string> }>;
    listing: { id: string; sharetribeListingId: string };
  }>;
  order: { sharetribeBuyerId: string; buyer?: Maybe<{ displayName: string }> };
};

export type CoreBundleItem = {
  id: string;
  status?: Maybe<ItemStatus>;
  historicalStatuses: Array<BundleItemHistoricalStatus>;
  dispute?: Maybe<{ id: string; reason: DisputeReason; note?: Maybe<string> }>;
  listing: { id: string; sharetribeListingId: string };
};

export type CoreDisbursement = {
  id: string;
  startDate: any;
  endDate: any;
  finalDepositTotal?: Maybe<number>;
  currency: Currency;
};

export type CoreDiscountLineItem = {
  id: string;
  title: string;
  currency: Currency;
  value: number;
  valueType: DiscountValueType;
  target: DiscountTarget;
  metadata: any;
};

export type CoreFulfillment = {
  id: string;
  trackingURL: string;
  trackingNumber: string;
  labelURL?: Maybe<string>;
  weightUnit: WeightUnit;
  weight: number;
  shippingLabelProvider?: Maybe<ShippingLabelProvider>;
  deliveredAt?: Maybe<any>;
};

export type CoreLineItem = {
  id: string;
  name: string;
  code: LineItemCode;
  includeFor: Array<Participant>;
  listingLineItem?: Maybe<{
    id: string;
    currency: Currency;
    price: number;
    quantity: number;
    sharetribeListingId: string;
  }>;
  shippingLineItem?: Maybe<{ id: string; currency: Currency; price: number; quantity: number }>;
  discountLineItem?: Maybe<{
    id: string;
    title: string;
    currency: Currency;
    value: number;
    valueType: DiscountValueType;
    target: DiscountTarget;
    metadata: any;
  }>;
  returnInsuranceLineItem?: Maybe<{
    id: string;
    currency: Currency;
    price: number;
    provider: ReturnInsuranceProvider;
    providerId: string;
  }>;
  taxLineItem?: Maybe<{
    id: string;
    taxId: string;
    taxCode: string;
    currency: Currency;
    value: number;
    rate: number;
    metadata: any;
  }>;
};

export type CoreListingLineItem = {
  id: string;
  currency: Currency;
  price: number;
  quantity: number;
  sharetribeListingId: string;
};

export type CoreOrder = {
  id: string;
  createdAt: any;
  shippingAddress: {
    id: string;
    addressLine1: string;
    addressLine2?: Maybe<string>;
    city: string;
    state: string;
    country: string;
    zip: string;
    fullName: string;
    phone?: Maybe<string>;
  };
  billingAddress?: Maybe<{
    id: string;
    addressLine1: string;
    addressLine2?: Maybe<string>;
    city: string;
    state: string;
    country: string;
    zip: string;
    fullName: string;
    phone?: Maybe<string>;
  }>;
  bundles: Array<{
    id: string;
    createdAt: any;
    expireAt?: Maybe<any>;
    sharetribeTransactionIds: Array<string>;
    status: BundleStatus;
    isMarkedFulfilled?: Maybe<boolean>;
    type?: Maybe<BundleType>;
    isBrandDirect: boolean;
    refunds: Array<{
      id: string;
      currency: Currency;
      price: number;
      type: RefundType;
      refundedLineItems: Array<{ id: string }>;
    }>;
    lineItems: Array<{
      id: string;
      name: string;
      code: LineItemCode;
      includeFor: Array<Participant>;
      listingLineItem?: Maybe<{
        id: string;
        currency: Currency;
        price: number;
        quantity: number;
        sharetribeListingId: string;
      }>;
      shippingLineItem?: Maybe<{ id: string; currency: Currency; price: number; quantity: number }>;
      discountLineItem?: Maybe<{
        id: string;
        title: string;
        currency: Currency;
        value: number;
        valueType: DiscountValueType;
        target: DiscountTarget;
        metadata: any;
      }>;
      returnInsuranceLineItem?: Maybe<{
        id: string;
        currency: Currency;
        price: number;
        provider: ReturnInsuranceProvider;
        providerId: string;
      }>;
      taxLineItem?: Maybe<{
        id: string;
        taxId: string;
        taxCode: string;
        currency: Currency;
        value: number;
        rate: number;
        metadata: any;
      }>;
    }>;
    fulfillment?: Maybe<{
      id: string;
      trackingURL: string;
      trackingNumber: string;
      labelURL?: Maybe<string>;
      weightUnit: WeightUnit;
      weight: number;
      shippingLabelProvider?: Maybe<ShippingLabelProvider>;
      deliveredAt?: Maybe<any>;
    }>;
    returnFulfillment?: Maybe<{
      id: string;
      trackingURL: string;
      trackingNumber: string;
      labelURL?: Maybe<string>;
      weightUnit: WeightUnit;
      weight: number;
      shippingLabelProvider?: Maybe<ShippingLabelProvider>;
      deliveredAt?: Maybe<any>;
    }>;
    bundleItems: Array<{
      id: string;
      status?: Maybe<ItemStatus>;
      historicalStatuses: Array<BundleItemHistoricalStatus>;
      dispute?: Maybe<{ id: string; reason: DisputeReason; note?: Maybe<string> }>;
      listing: { id: string; sharetribeListingId: string };
    }>;
    order: { sharetribeBuyerId: string; buyer?: Maybe<{ displayName: string }> };
  }>;
  lineItems: Array<{
    id: string;
    name: string;
    code: LineItemCode;
    includeFor: Array<Participant>;
    listingLineItem?: Maybe<{
      id: string;
      currency: Currency;
      price: number;
      quantity: number;
      sharetribeListingId: string;
    }>;
    shippingLineItem?: Maybe<{ id: string; currency: Currency; price: number; quantity: number }>;
    discountLineItem?: Maybe<{
      id: string;
      title: string;
      currency: Currency;
      value: number;
      valueType: DiscountValueType;
      target: DiscountTarget;
      metadata: any;
    }>;
    returnInsuranceLineItem?: Maybe<{
      id: string;
      currency: Currency;
      price: number;
      provider: ReturnInsuranceProvider;
      providerId: string;
    }>;
    taxLineItem?: Maybe<{
      id: string;
      taxId: string;
      taxCode: string;
      currency: Currency;
      value: number;
      rate: number;
      metadata: any;
    }>;
  }>;
};

export type CoreRefund = {
  id: string;
  currency: Currency;
  price: number;
  type: RefundType;
  refundedLineItems: Array<{ id: string }>;
};

export type CoreReturnInsuranceLineItem = {
  id: string;
  currency: Currency;
  price: number;
  provider: ReturnInsuranceProvider;
  providerId: string;
};

export type CoreShippingLineItem = {
  id: string;
  currency: Currency;
  price: number;
  quantity: number;
};

export type CoreTaxLineItem = {
  id: string;
  taxId: string;
  taxCode: string;
  currency: Currency;
  value: number;
  rate: number;
  metadata: any;
};

export type AllBrandDisbursementsVariables = Exact<{
  shopId: Scalars['String'];
}>;

export type AllBrandDisbursementsResponse = {
  brandDisbursements: Array<{
    id: string;
    startDate: any;
    endDate: any;
    finalDepositTotal?: Maybe<number>;
    currency: Currency;
  }>;
};

export type AllBrandReportsVariables = Exact<{
  shopId: Scalars['String'];
  cursor?: Maybe<Scalars['String']>;
}>;

export type AllBrandReportsResponse = {
  brandReports: Array<{ id: string; createdAt: any; startDate: any; endDate: any }>;
};

export type AllBundlesVariables = Exact<{
  userId: Scalars['String'];
  status?: Maybe<BundleStatus>;
  shopName?: Maybe<Scalars['String']>;
  sharetribeListingIds?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;

export type AllBundlesResponse = {
  bundles: Array<{
    id: string;
    createdAt: any;
    expireAt?: Maybe<any>;
    sharetribeTransactionIds: Array<string>;
    status: BundleStatus;
    isMarkedFulfilled?: Maybe<boolean>;
    type?: Maybe<BundleType>;
    isBrandDirect: boolean;
    refunds: Array<{
      id: string;
      currency: Currency;
      price: number;
      type: RefundType;
      refundedLineItems: Array<{ id: string }>;
    }>;
    lineItems: Array<{
      id: string;
      name: string;
      code: LineItemCode;
      includeFor: Array<Participant>;
      listingLineItem?: Maybe<{
        id: string;
        currency: Currency;
        price: number;
        quantity: number;
        sharetribeListingId: string;
      }>;
      shippingLineItem?: Maybe<{ id: string; currency: Currency; price: number; quantity: number }>;
      discountLineItem?: Maybe<{
        id: string;
        title: string;
        currency: Currency;
        value: number;
        valueType: DiscountValueType;
        target: DiscountTarget;
        metadata: any;
      }>;
      returnInsuranceLineItem?: Maybe<{
        id: string;
        currency: Currency;
        price: number;
        provider: ReturnInsuranceProvider;
        providerId: string;
      }>;
      taxLineItem?: Maybe<{
        id: string;
        taxId: string;
        taxCode: string;
        currency: Currency;
        value: number;
        rate: number;
        metadata: any;
      }>;
    }>;
    fulfillment?: Maybe<{
      id: string;
      trackingURL: string;
      trackingNumber: string;
      labelURL?: Maybe<string>;
      weightUnit: WeightUnit;
      weight: number;
      shippingLabelProvider?: Maybe<ShippingLabelProvider>;
      deliveredAt?: Maybe<any>;
    }>;
    returnFulfillment?: Maybe<{
      id: string;
      trackingURL: string;
      trackingNumber: string;
      labelURL?: Maybe<string>;
      weightUnit: WeightUnit;
      weight: number;
      shippingLabelProvider?: Maybe<ShippingLabelProvider>;
      deliveredAt?: Maybe<any>;
    }>;
    bundleItems: Array<{
      id: string;
      status?: Maybe<ItemStatus>;
      historicalStatuses: Array<BundleItemHistoricalStatus>;
      dispute?: Maybe<{ id: string; reason: DisputeReason; note?: Maybe<string> }>;
      listing: { id: string; sharetribeListingId: string };
    }>;
    order: { sharetribeBuyerId: string; buyer?: Maybe<{ displayName: string }> };
  }>;
};

export type AllOrdersVariables = Exact<{
  userId: Scalars['String'];
  shopName?: Maybe<Scalars['String']>;
  bundleStatus?: Maybe<BundleStatus>;
  searchQuery?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;

export type AllOrdersResponse = {
  orders: Array<{
    id: string;
    createdAt: any;
    shippingAddress: {
      id: string;
      addressLine1: string;
      addressLine2?: Maybe<string>;
      city: string;
      state: string;
      country: string;
      zip: string;
      fullName: string;
      phone?: Maybe<string>;
    };
    billingAddress?: Maybe<{
      id: string;
      addressLine1: string;
      addressLine2?: Maybe<string>;
      city: string;
      state: string;
      country: string;
      zip: string;
      fullName: string;
      phone?: Maybe<string>;
    }>;
    bundles: Array<{
      id: string;
      createdAt: any;
      expireAt?: Maybe<any>;
      sharetribeTransactionIds: Array<string>;
      status: BundleStatus;
      isMarkedFulfilled?: Maybe<boolean>;
      type?: Maybe<BundleType>;
      isBrandDirect: boolean;
      refunds: Array<{
        id: string;
        currency: Currency;
        price: number;
        type: RefundType;
        refundedLineItems: Array<{ id: string }>;
      }>;
      lineItems: Array<{
        id: string;
        name: string;
        code: LineItemCode;
        includeFor: Array<Participant>;
        listingLineItem?: Maybe<{
          id: string;
          currency: Currency;
          price: number;
          quantity: number;
          sharetribeListingId: string;
        }>;
        shippingLineItem?: Maybe<{
          id: string;
          currency: Currency;
          price: number;
          quantity: number;
        }>;
        discountLineItem?: Maybe<{
          id: string;
          title: string;
          currency: Currency;
          value: number;
          valueType: DiscountValueType;
          target: DiscountTarget;
          metadata: any;
        }>;
        returnInsuranceLineItem?: Maybe<{
          id: string;
          currency: Currency;
          price: number;
          provider: ReturnInsuranceProvider;
          providerId: string;
        }>;
        taxLineItem?: Maybe<{
          id: string;
          taxId: string;
          taxCode: string;
          currency: Currency;
          value: number;
          rate: number;
          metadata: any;
        }>;
      }>;
      fulfillment?: Maybe<{
        id: string;
        trackingURL: string;
        trackingNumber: string;
        labelURL?: Maybe<string>;
        weightUnit: WeightUnit;
        weight: number;
        shippingLabelProvider?: Maybe<ShippingLabelProvider>;
        deliveredAt?: Maybe<any>;
      }>;
      returnFulfillment?: Maybe<{
        id: string;
        trackingURL: string;
        trackingNumber: string;
        labelURL?: Maybe<string>;
        weightUnit: WeightUnit;
        weight: number;
        shippingLabelProvider?: Maybe<ShippingLabelProvider>;
        deliveredAt?: Maybe<any>;
      }>;
      bundleItems: Array<{
        id: string;
        status?: Maybe<ItemStatus>;
        historicalStatuses: Array<BundleItemHistoricalStatus>;
        dispute?: Maybe<{ id: string; reason: DisputeReason; note?: Maybe<string> }>;
        listing: { id: string; sharetribeListingId: string };
      }>;
      order: { sharetribeBuyerId: string; buyer?: Maybe<{ displayName: string }> };
    }>;
    lineItems: Array<{
      id: string;
      name: string;
      code: LineItemCode;
      includeFor: Array<Participant>;
      listingLineItem?: Maybe<{
        id: string;
        currency: Currency;
        price: number;
        quantity: number;
        sharetribeListingId: string;
      }>;
      shippingLineItem?: Maybe<{ id: string; currency: Currency; price: number; quantity: number }>;
      discountLineItem?: Maybe<{
        id: string;
        title: string;
        currency: Currency;
        value: number;
        valueType: DiscountValueType;
        target: DiscountTarget;
        metadata: any;
      }>;
      returnInsuranceLineItem?: Maybe<{
        id: string;
        currency: Currency;
        price: number;
        provider: ReturnInsuranceProvider;
        providerId: string;
      }>;
      taxLineItem?: Maybe<{
        id: string;
        taxId: string;
        taxCode: string;
        currency: Currency;
        value: number;
        rate: number;
        metadata: any;
      }>;
    }>;
  }>;
};

export type BundleByIdVariables = Exact<{
  bundleId: Scalars['String'];
}>;

export type BundleByIdResponse = {
  bundle?: Maybe<{
    id: string;
    type?: Maybe<BundleType>;
    status: BundleStatus;
    expireAt?: Maybe<any>;
    sharetribeTransactionIds: Array<string>;
    fulfillment?: Maybe<{ weight: number; weightUnit: WeightUnit }>;
    order: { id: string; sharetribeBuyerId: string };
    bundleItems: Array<{ id: string; listing: { id: string; sharetribeListingId: string } }>;
    lineItems: Array<{
      id: string;
      name: string;
      code: LineItemCode;
      includeFor: Array<Participant>;
      listingLineItem?: Maybe<{
        id: string;
        currency: Currency;
        price: number;
        quantity: number;
        sharetribeListingId: string;
      }>;
      shippingLineItem?: Maybe<{ id: string; currency: Currency; price: number; quantity: number }>;
      discountLineItem?: Maybe<{
        id: string;
        title: string;
        currency: Currency;
        value: number;
        valueType: DiscountValueType;
        target: DiscountTarget;
        metadata: any;
      }>;
      returnInsuranceLineItem?: Maybe<{
        id: string;
        currency: Currency;
        price: number;
        provider: ReturnInsuranceProvider;
        providerId: string;
      }>;
      taxLineItem?: Maybe<{
        id: string;
        taxId: string;
        taxCode: string;
        currency: Currency;
        value: number;
        rate: number;
        metadata: any;
      }>;
    }>;
  }>;
};

export type CartItemsVariables = Exact<{ [key: string]: never }>;

export type CartItemsResponse = {
  cartItems: Array<{ id: string; listing: { id: string; sharetribeListingId: string } }>;
};

export type CreateCancellationVariables = Exact<{
  input: CreateCancellationInput;
}>;

export type CreateCancellationResponse = { createCancellation: { cancellation: { id: string } } };

export type CreateCartItemVariables = Exact<{
  input: CreateCartItemInput;
}>;

export type CreateCartItemResponse = {
  createCartItem: Array<{ id: string; listing: { id: string; sharetribeListingId: string } }>;
};

export type UpsertDisputeVariables = Exact<{
  input: UpsertDisputeInput;
}>;

export type UpsertDisputeResponse = { upsertDispute: { dispute: { id: string } } };

export type CreateFavoritedItemVariables = Exact<{
  input: CreateFavoritedItemInput;
}>;

export type CreateFavoritedItemResponse = {
  createFavoritedItem: { favoritedItem: { userId: string; listingId: string } };
};

export type UpsertListingVariables = Exact<{
  input: UpsertListingInput;
}>;

export type UpsertListingResponse = { upsertListing: { listing: { id: string } } };

export type CreateListingFeedbackVariables = Exact<{
  input: CreateListingFeedbackInput;
}>;

export type CreateListingFeedbackResponse = {
  createListingFeedback: { listingFeedback: { id: string } };
};

export type CreateUserVariables = Exact<{
  input: CreateUserInput;
}>;

export type CreateUserResponse = { createUser: { user: { id: string } } };

export type CreditCodeByBundleIdVariables = Exact<{
  bundleId: Scalars['String'];
}>;

export type CreditCodeByBundleIdResponse = {
  bundle?: Maybe<{
    id: string;
    creditCode?: Maybe<{
      id: string;
      code?: Maybe<string>;
      amount: number;
      type: CreditCodeType;
      currency: Currency;
    }>;
  }>;
};

export type DisbursementByIdVariables = Exact<{
  disbursementId: Scalars['String'];
}>;

export type DisbursementByIdResponse = {
  disbursement?: Maybe<{
    id: string;
    startDate: any;
    endDate: any;
    type: DisbursementType;
    status: DisbursementStatus;
    currency: Currency;
    discountsTotal?: Maybe<number>;
    returnInsuranceTotal?: Maybe<number>;
    shippingTotal?: Maybe<number>;
    taxTotal?: Maybe<number>;
    customerPaidTotal: number;
    peerCashSalesGMVTotal?: Maybe<number>;
    peerCreditSalesGMVTotal?: Maybe<number>;
    brandDirectSalesGMVTotal?: Maybe<number>;
    relistedTradeInSalesGMVTotal?: Maybe<number>;
    grossMerchandiseValueTotal: number;
    peerSellerPayoutTotal?: Maybe<number>;
    treetTakeTotal: number;
    ccProcessingTotal: number;
    brandCoveredDiscountsTotal?: Maybe<number>;
    salesCostTotal: number;
    reimbursedShippingCostTotal?: Maybe<number>;
    additionalLineItems?: Maybe<any>;
    reimbursementsTotal: number;
    returnShippingCostTotal?: Maybe<number>;
    brandCoveredShippingCostTotal?: Maybe<number>;
    additionalCostsTotal: number;
    brandDirectSalesPayoutTotal: number;
    relistedTradeInSalesPayoutTotal: number;
    previouslyDepositedTotal?: Maybe<number>;
    grossMarginTotal: number;
    earningsTotal: number;
    finalDepositTotal?: Maybe<number>;
  }>;
};

export type FavoritedItemsVariables = Exact<{ [key: string]: never }>;

export type FavoritedItemsResponse = {
  favoritedItems: Array<{ id: string; listing: { id: string; sharetribeListingId: string } }>;
};

export type OrderByIdVariables = Exact<{
  orderId: Scalars['String'];
}>;

export type OrderByIdResponse = {
  order?: Maybe<{
    sharetribeBuyerId: string;
    bundles: Array<{
      id: string;
      sharetribeTransactionIds: Array<string>;
      lineItems: Array<{
        id: string;
        name: string;
        code: LineItemCode;
        includeFor: Array<Participant>;
        listingLineItem?: Maybe<{
          id: string;
          currency: Currency;
          price: number;
          quantity: number;
          sharetribeListingId: string;
        }>;
        shippingLineItem?: Maybe<{
          id: string;
          currency: Currency;
          price: number;
          quantity: number;
        }>;
        discountLineItem?: Maybe<{
          id: string;
          title: string;
          currency: Currency;
          value: number;
          valueType: DiscountValueType;
          target: DiscountTarget;
          metadata: any;
        }>;
        returnInsuranceLineItem?: Maybe<{
          id: string;
          currency: Currency;
          price: number;
          provider: ReturnInsuranceProvider;
          providerId: string;
        }>;
        taxLineItem?: Maybe<{
          id: string;
          taxId: string;
          taxCode: string;
          currency: Currency;
          value: number;
          rate: number;
          metadata: any;
        }>;
      }>;
      bundleItems: Array<{ id: string; listing: { id: string; sharetribeListingId: string } }>;
    }>;
  }>;
};

export type RemoveCartItemVariables = Exact<{
  input: RemoveCartItemsInput;
}>;

export type RemoveCartItemResponse = {
  removeCartItem: Array<{ id: string; listing: { id: string; sharetribeListingId: string } }>;
};

export type RemoveFavoritedItemVariables = Exact<{
  input: RemoveFavoritedItemInput;
}>;

export type RemoveFavoritedItemResponse = {
  removeFavoritedItem: { favoritedItem: { userId: string; listingId: string } };
};

export type SavedSearchByEmailVariables = Exact<{
  email: Scalars['String'];
  treetId: Scalars['String'];
  type: SavedSearchType;
}>;

export type SavedSearchByEmailResponse = {
  savedSearch?: Maybe<{ id: string; search: any; cadence: Cadence }>;
};

export type ShopByHostnameVariables = Exact<{
  hostname: Scalars['String'];
}>;

export type ShopByHostnameResponse = {
  shopByHostname?: Maybe<{
    id: string;
    treetId: string;
    canonicalRootUrl: string;
    defaultCurrency?: Maybe<Currency>;
    primaryLocale: Locale;
  }>;
};

export type ShopByTreetIdVariables = Exact<{
  treetId: Scalars['String'];
}>;

export type ShopByTreetIdResponse = {
  shop?: Maybe<{
    id: string;
    canonicalRootUrl: string;
    defaultCurrency?: Maybe<Currency>;
    primaryLocale: Locale;
  }>;
};

export type UpdateAddressVariables = Exact<{
  input: UpdateAddressInput;
}>;

export type UpdateAddressResponse = {
  updateAddress: {
    address: {
      id: string;
      addressLine1: string;
      addressLine2?: Maybe<string>;
      city: string;
      state: string;
      country: string;
      zip: string;
      fullName: string;
      phone?: Maybe<string>;
    };
  };
};

export type UpdateBundleVariables = Exact<{
  input: UpdateBundleInput;
}>;

export type UpdateBundleResponse = {
  updateBundle: {
    bundle: {
      id: string;
      status: BundleStatus;
      isMarkedFulfilled?: Maybe<boolean>;
      canceledAt?: Maybe<any>;
      expireAt?: Maybe<any>;
      lineItems: Array<{
        id: string;
        name: string;
        code: LineItemCode;
        includeFor: Array<Participant>;
        listingLineItem?: Maybe<{
          id: string;
          currency: Currency;
          price: number;
          quantity: number;
          sharetribeListingId: string;
        }>;
        shippingLineItem?: Maybe<{
          id: string;
          currency: Currency;
          price: number;
          quantity: number;
        }>;
        discountLineItem?: Maybe<{
          id: string;
          title: string;
          currency: Currency;
          value: number;
          valueType: DiscountValueType;
          target: DiscountTarget;
          metadata: any;
        }>;
        returnInsuranceLineItem?: Maybe<{
          id: string;
          currency: Currency;
          price: number;
          provider: ReturnInsuranceProvider;
          providerId: string;
        }>;
        taxLineItem?: Maybe<{
          id: string;
          taxId: string;
          taxCode: string;
          currency: Currency;
          value: number;
          rate: number;
          metadata: any;
        }>;
      }>;
    };
  };
};

export type UpdateBundleItemVariables = Exact<{
  input: UpdateBundleItemInput;
}>;

export type UpdateBundleItemResponse = {
  updateBundleItem: { bundleItem: { id: string; status?: Maybe<ItemStatus> } };
};

export type UpdateUserVariables = Exact<{
  input: UpdateUserInput;
}>;

export type UpdateUserResponse = {
  updateUser: { user: { email: string; stripeAccountId?: Maybe<string> } };
};

export type UpsertGeneralSavedSearchVariables = Exact<{
  input: UpsertGeneralSavedSearchInput;
}>;

export type UpsertGeneralSavedSearchResponse = {
  upsertGeneralSavedSearch: {
    savedSearch: {
      id: string;
      email: string;
      search: any;
      cadence: Cadence;
      subscribeSource: EmailSubscribeSource;
      shop: { id: string };
    };
  };
};

export type UpsertIsoSavedSearchVariables = Exact<{
  input: UpsertIsoSavedSearchInput;
}>;

export type UpsertIsoSavedSearchResponse = {
  upsertISOSavedSearch: {
    savedSearch: {
      id: string;
      email: string;
      search: any;
      cadence: Cadence;
      subscribeSource: EmailSubscribeSource;
      shop: { id: string };
    };
    metadata?: Maybe<{ created?: Maybe<boolean>; updated?: Maybe<boolean> }>;
  };
};

export type UpsertPriceDropConfigVariables = Exact<{
  input: UpsertPriceDropConfigInput;
}>;

export type UpsertPriceDropConfigResponse = {
  upsertPriceDropConfig: { priceDropConfig: { id: string } };
};

export type GetPriceDropConfigBySharetribeListingIdVariables = Exact<{
  sharetribeListingId?: Maybe<Scalars['ID']>;
}>;

export type GetPriceDropConfigBySharetribeListingIdResponse = {
  priceDropConfig?: Maybe<{
    id: string;
    anchorPrice: number;
    minPrice: number;
    nextScheduledDrop?: Maybe<any>;
    isActive: boolean;
    percentageDrop: number;
  }>;
};

export type IsoSavedSearchesByEmailVariables = Exact<{
  email: Scalars['String'];
  treetId: Scalars['String'];
}>;

export type IsoSavedSearchesByEmailResponse = {
  isoSavedSearches: Array<{ id: string; search: any }>;
};

export const CoreDisbursement = gql`
  fragment CoreDisbursement on Disbursement {
    id
    startDate
    endDate
    finalDepositTotal
    currency
  }
`;
export const CoreAddress = gql`
  fragment CoreAddress on Address {
    id
    addressLine1
    addressLine2
    city
    state
    country
    zip
    fullName
    phone
  }
`;
export const CoreRefund = gql`
  fragment CoreRefund on Refund {
    id
    currency
    price
    type
    refundedLineItems {
      id
    }
  }
`;
export const CoreListingLineItem = gql`
  fragment CoreListingLineItem on ListingLineItem {
    id
    currency
    price
    quantity
    sharetribeListingId
  }
`;
export const CoreShippingLineItem = gql`
  fragment CoreShippingLineItem on ShippingLineItem {
    id
    currency
    price
    quantity
  }
`;
export const CoreDiscountLineItem = gql`
  fragment CoreDiscountLineItem on DiscountLineItem {
    id
    title
    currency
    value
    valueType
    target
    metadata
  }
`;
export const CoreReturnInsuranceLineItem = gql`
  fragment CoreReturnInsuranceLineItem on ReturnInsuranceLineItem {
    id
    currency
    price
    provider
    providerId
  }
`;
export const CoreTaxLineItem = gql`
  fragment CoreTaxLineItem on TaxLineItem {
    id
    taxId
    taxCode
    currency
    value
    rate
    metadata
  }
`;
export const CoreLineItem = gql`
  fragment CoreLineItem on LineItem {
    id
    name
    code
    includeFor
    listingLineItem {
      ...CoreListingLineItem
    }
    shippingLineItem {
      ...CoreShippingLineItem
    }
    discountLineItem {
      ...CoreDiscountLineItem
    }
    returnInsuranceLineItem {
      ...CoreReturnInsuranceLineItem
    }
    taxLineItem {
      ...CoreTaxLineItem
    }
  }
  ${CoreListingLineItem}
  ${CoreShippingLineItem}
  ${CoreDiscountLineItem}
  ${CoreReturnInsuranceLineItem}
  ${CoreTaxLineItem}
`;
export const CoreFulfillment = gql`
  fragment CoreFulfillment on Fulfillment {
    id
    trackingURL
    trackingNumber
    labelURL
    weightUnit
    weight
    shippingLabelProvider
    deliveredAt
  }
`;
export const CoreBundleItem = gql`
  fragment CoreBundleItem on BundleItem {
    id
    status
    dispute {
      id
      reason
      note
    }
    historicalStatuses
    listing {
      id
      sharetribeListingId
    }
  }
`;
export const CoreBundle = gql`
  fragment CoreBundle on Bundle {
    id
    createdAt
    expireAt
    sharetribeTransactionIds
    status
    isMarkedFulfilled
    type
    isBrandDirect
    refunds {
      ...CoreRefund
    }
    lineItems {
      ...CoreLineItem
    }
    fulfillment {
      ...CoreFulfillment
    }
    returnFulfillment {
      ...CoreFulfillment
    }
    bundleItems {
      ...CoreBundleItem
    }
    order {
      sharetribeBuyerId
      buyer {
        displayName
      }
    }
  }
  ${CoreRefund}
  ${CoreLineItem}
  ${CoreFulfillment}
  ${CoreBundleItem}
`;
export const CoreOrder = gql`
  fragment CoreOrder on Order {
    id
    createdAt
    shippingAddress {
      ...CoreAddress
    }
    billingAddress {
      ...CoreAddress
    }
    bundles {
      ...CoreBundle
    }
    lineItems {
      ...CoreLineItem
    }
  }
  ${CoreAddress}
  ${CoreBundle}
  ${CoreLineItem}
`;
export const AllBrandDisbursementsDocument = gql`
  query AllBrandDisbursements($shopId: String!) {
    brandDisbursements(shopId: $shopId) {
      ...CoreDisbursement
    }
  }
  ${CoreDisbursement}
`;

/**
 * __useAllBrandDisbursements__
 *
 * To run a query within a React component, call `useAllBrandDisbursements` and pass it any options that fit your needs.
 * When your component renders, `useAllBrandDisbursements` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllBrandDisbursements({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useAllBrandDisbursements(
  baseOptions: Apollo.QueryHookOptions<
    AllBrandDisbursementsResponse,
    AllBrandDisbursementsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllBrandDisbursementsResponse, AllBrandDisbursementsVariables>(
    AllBrandDisbursementsDocument,
    options
  );
}
export function useAllBrandDisbursementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllBrandDisbursementsResponse,
    AllBrandDisbursementsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllBrandDisbursementsResponse, AllBrandDisbursementsVariables>(
    AllBrandDisbursementsDocument,
    options
  );
}
export type AllBrandDisbursementsHookResult = ReturnType<typeof useAllBrandDisbursements>;
export type AllBrandDisbursementsLazyQueryHookResult = ReturnType<
  typeof useAllBrandDisbursementsLazyQuery
>;
export type AllBrandDisbursementsQueryResult = Apollo.QueryResult<
  AllBrandDisbursementsResponse,
  AllBrandDisbursementsVariables
>;
export const AllBrandReportsDocument = gql`
  query AllBrandReports($shopId: String!, $cursor: String) {
    brandReports(shopId: $shopId, cursor: $cursor) {
      id
      createdAt
      startDate
      endDate
    }
  }
`;

/**
 * __useAllBrandReports__
 *
 * To run a query within a React component, call `useAllBrandReports` and pass it any options that fit your needs.
 * When your component renders, `useAllBrandReports` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllBrandReports({
 *   variables: {
 *      shopId: // value for 'shopId'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useAllBrandReports(
  baseOptions: Apollo.QueryHookOptions<AllBrandReportsResponse, AllBrandReportsVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllBrandReportsResponse, AllBrandReportsVariables>(
    AllBrandReportsDocument,
    options
  );
}
export function useAllBrandReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllBrandReportsResponse, AllBrandReportsVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllBrandReportsResponse, AllBrandReportsVariables>(
    AllBrandReportsDocument,
    options
  );
}
export type AllBrandReportsHookResult = ReturnType<typeof useAllBrandReports>;
export type AllBrandReportsLazyQueryHookResult = ReturnType<typeof useAllBrandReportsLazyQuery>;
export type AllBrandReportsQueryResult = Apollo.QueryResult<
  AllBrandReportsResponse,
  AllBrandReportsVariables
>;
export const AllBundlesDocument = gql`
  query AllBundles(
    $userId: String!
    $status: BundleStatus
    $shopName: String
    $sharetribeListingIds: [String!]
    $limit: Int
    $offset: Int
  ) {
    bundles(
      sharetribeSellerId: $userId
      status: $status
      shopName: $shopName
      sharetribeListingIds: $sharetribeListingIds
      limit: $limit
      offset: $offset
    ) {
      ...CoreBundle
    }
  }
  ${CoreBundle}
`;

/**
 * __useAllBundles__
 *
 * To run a query within a React component, call `useAllBundles` and pass it any options that fit your needs.
 * When your component renders, `useAllBundles` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllBundles({
 *   variables: {
 *      userId: // value for 'userId'
 *      status: // value for 'status'
 *      shopName: // value for 'shopName'
 *      sharetribeListingIds: // value for 'sharetribeListingIds'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAllBundles(
  baseOptions: Apollo.QueryHookOptions<AllBundlesResponse, AllBundlesVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllBundlesResponse, AllBundlesVariables>(AllBundlesDocument, options);
}
export function useAllBundlesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllBundlesResponse, AllBundlesVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllBundlesResponse, AllBundlesVariables>(AllBundlesDocument, options);
}
export type AllBundlesHookResult = ReturnType<typeof useAllBundles>;
export type AllBundlesLazyQueryHookResult = ReturnType<typeof useAllBundlesLazyQuery>;
export type AllBundlesQueryResult = Apollo.QueryResult<AllBundlesResponse, AllBundlesVariables>;
export const AllOrdersDocument = gql`
  query AllOrders(
    $userId: String!
    $shopName: String
    $bundleStatus: BundleStatus
    $searchQuery: String
    $limit: Int
    $offset: Int
  ) {
    orders(
      sharetribeBuyerId: $userId
      shopName: $shopName
      bundleStatus: $bundleStatus
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
    ) {
      ...CoreOrder
    }
  }
  ${CoreOrder}
`;

/**
 * __useAllOrders__
 *
 * To run a query within a React component, call `useAllOrders` and pass it any options that fit your needs.
 * When your component renders, `useAllOrders` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllOrders({
 *   variables: {
 *      userId: // value for 'userId'
 *      shopName: // value for 'shopName'
 *      bundleStatus: // value for 'bundleStatus'
 *      searchQuery: // value for 'searchQuery'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAllOrders(
  baseOptions: Apollo.QueryHookOptions<AllOrdersResponse, AllOrdersVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllOrdersResponse, AllOrdersVariables>(AllOrdersDocument, options);
}
export function useAllOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllOrdersResponse, AllOrdersVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllOrdersResponse, AllOrdersVariables>(AllOrdersDocument, options);
}
export type AllOrdersHookResult = ReturnType<typeof useAllOrders>;
export type AllOrdersLazyQueryHookResult = ReturnType<typeof useAllOrdersLazyQuery>;
export type AllOrdersQueryResult = Apollo.QueryResult<AllOrdersResponse, AllOrdersVariables>;
export const BundleByIdDocument = gql`
  query BundleById($bundleId: String!) {
    bundle(id: $bundleId) {
      id
      type
      status
      fulfillment {
        weight
        weightUnit
      }
      order {
        id
        sharetribeBuyerId
      }
      bundleItems {
        id
        listing {
          id
          sharetribeListingId
        }
      }
      lineItems {
        ...CoreLineItem
      }
      expireAt
      sharetribeTransactionIds
    }
  }
  ${CoreLineItem}
`;

/**
 * __useBundleById__
 *
 * To run a query within a React component, call `useBundleById` and pass it any options that fit your needs.
 * When your component renders, `useBundleById` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBundleById({
 *   variables: {
 *      bundleId: // value for 'bundleId'
 *   },
 * });
 */
export function useBundleById(
  baseOptions: Apollo.QueryHookOptions<BundleByIdResponse, BundleByIdVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BundleByIdResponse, BundleByIdVariables>(BundleByIdDocument, options);
}
export function useBundleByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BundleByIdResponse, BundleByIdVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BundleByIdResponse, BundleByIdVariables>(BundleByIdDocument, options);
}
export type BundleByIdHookResult = ReturnType<typeof useBundleById>;
export type BundleByIdLazyQueryHookResult = ReturnType<typeof useBundleByIdLazyQuery>;
export type BundleByIdQueryResult = Apollo.QueryResult<BundleByIdResponse, BundleByIdVariables>;
export const CartItemsDocument = gql`
  query CartItems {
    cartItems {
      id
      listing {
        id
        sharetribeListingId
      }
    }
  }
`;

/**
 * __useCartItems__
 *
 * To run a query within a React component, call `useCartItems` and pass it any options that fit your needs.
 * When your component renders, `useCartItems` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartItems({
 *   variables: {
 *   },
 * });
 */
export function useCartItems(
  baseOptions?: Apollo.QueryHookOptions<CartItemsResponse, CartItemsVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CartItemsResponse, CartItemsVariables>(CartItemsDocument, options);
}
export function useCartItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CartItemsResponse, CartItemsVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CartItemsResponse, CartItemsVariables>(CartItemsDocument, options);
}
export type CartItemsHookResult = ReturnType<typeof useCartItems>;
export type CartItemsLazyQueryHookResult = ReturnType<typeof useCartItemsLazyQuery>;
export type CartItemsQueryResult = Apollo.QueryResult<CartItemsResponse, CartItemsVariables>;
export const CreateCancellationDocument = gql`
  mutation CreateCancellation($input: CreateCancellationInput!) {
    createCancellation(input: $input) {
      cancellation {
        id
      }
    }
  }
`;
export type CreateCancellationMutationFn = Apollo.MutationFunction<
  CreateCancellationResponse,
  CreateCancellationVariables
>;

/**
 * __useCreateCancellation__
 *
 * To run a mutation, you first call `useCreateCancellation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCancellation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCancellation, { data, loading, error }] = useCreateCancellation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCancellation(
  baseOptions?: Apollo.MutationHookOptions<CreateCancellationResponse, CreateCancellationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCancellationResponse, CreateCancellationVariables>(
    CreateCancellationDocument,
    options
  );
}
export type CreateCancellationHookResult = ReturnType<typeof useCreateCancellation>;
export type CreateCancellationMutationResult = Apollo.MutationResult<CreateCancellationResponse>;
export type CreateCancellationMutationOptions = Apollo.BaseMutationOptions<
  CreateCancellationResponse,
  CreateCancellationVariables
>;
export const CreateCartItemDocument = gql`
  mutation CreateCartItem($input: CreateCartItemInput!) {
    createCartItem(input: $input) {
      id
      listing {
        id
        sharetribeListingId
      }
    }
  }
`;
export type CreateCartItemMutationFn = Apollo.MutationFunction<
  CreateCartItemResponse,
  CreateCartItemVariables
>;

/**
 * __useCreateCartItem__
 *
 * To run a mutation, you first call `useCreateCartItem` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCartItem` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCartItem, { data, loading, error }] = useCreateCartItem({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCartItem(
  baseOptions?: Apollo.MutationHookOptions<CreateCartItemResponse, CreateCartItemVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCartItemResponse, CreateCartItemVariables>(
    CreateCartItemDocument,
    options
  );
}
export type CreateCartItemHookResult = ReturnType<typeof useCreateCartItem>;
export type CreateCartItemMutationResult = Apollo.MutationResult<CreateCartItemResponse>;
export type CreateCartItemMutationOptions = Apollo.BaseMutationOptions<
  CreateCartItemResponse,
  CreateCartItemVariables
>;
export const UpsertDisputeDocument = gql`
  mutation UpsertDispute($input: UpsertDisputeInput!) {
    upsertDispute(input: $input) {
      dispute {
        id
      }
    }
  }
`;
export type UpsertDisputeMutationFn = Apollo.MutationFunction<
  UpsertDisputeResponse,
  UpsertDisputeVariables
>;

/**
 * __useUpsertDispute__
 *
 * To run a mutation, you first call `useUpsertDispute` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertDispute` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertDispute, { data, loading, error }] = useUpsertDispute({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertDispute(
  baseOptions?: Apollo.MutationHookOptions<UpsertDisputeResponse, UpsertDisputeVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpsertDisputeResponse, UpsertDisputeVariables>(
    UpsertDisputeDocument,
    options
  );
}
export type UpsertDisputeHookResult = ReturnType<typeof useUpsertDispute>;
export type UpsertDisputeMutationResult = Apollo.MutationResult<UpsertDisputeResponse>;
export type UpsertDisputeMutationOptions = Apollo.BaseMutationOptions<
  UpsertDisputeResponse,
  UpsertDisputeVariables
>;
export const CreateFavoritedItemDocument = gql`
  mutation CreateFavoritedItem($input: CreateFavoritedItemInput!) {
    createFavoritedItem(input: $input) {
      favoritedItem {
        userId
        listingId
      }
    }
  }
`;
export type CreateFavoritedItemMutationFn = Apollo.MutationFunction<
  CreateFavoritedItemResponse,
  CreateFavoritedItemVariables
>;

/**
 * __useCreateFavoritedItem__
 *
 * To run a mutation, you first call `useCreateFavoritedItem` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFavoritedItem` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFavoritedItem, { data, loading, error }] = useCreateFavoritedItem({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFavoritedItem(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFavoritedItemResponse,
    CreateFavoritedItemVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateFavoritedItemResponse, CreateFavoritedItemVariables>(
    CreateFavoritedItemDocument,
    options
  );
}
export type CreateFavoritedItemHookResult = ReturnType<typeof useCreateFavoritedItem>;
export type CreateFavoritedItemMutationResult = Apollo.MutationResult<CreateFavoritedItemResponse>;
export type CreateFavoritedItemMutationOptions = Apollo.BaseMutationOptions<
  CreateFavoritedItemResponse,
  CreateFavoritedItemVariables
>;
export const UpsertListingDocument = gql`
  mutation UpsertListing($input: UpsertListingInput!) {
    upsertListing(input: $input) {
      listing {
        id
      }
    }
  }
`;
export type UpsertListingMutationFn = Apollo.MutationFunction<
  UpsertListingResponse,
  UpsertListingVariables
>;

/**
 * __useUpsertListing__
 *
 * To run a mutation, you first call `useUpsertListing` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertListing` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertListing, { data, loading, error }] = useUpsertListing({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertListing(
  baseOptions?: Apollo.MutationHookOptions<UpsertListingResponse, UpsertListingVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpsertListingResponse, UpsertListingVariables>(
    UpsertListingDocument,
    options
  );
}
export type UpsertListingHookResult = ReturnType<typeof useUpsertListing>;
export type UpsertListingMutationResult = Apollo.MutationResult<UpsertListingResponse>;
export type UpsertListingMutationOptions = Apollo.BaseMutationOptions<
  UpsertListingResponse,
  UpsertListingVariables
>;
export const CreateListingFeedbackDocument = gql`
  mutation CreateListingFeedback($input: CreateListingFeedbackInput!) {
    createListingFeedback(input: $input) {
      listingFeedback {
        id
      }
    }
  }
`;
export type CreateListingFeedbackMutationFn = Apollo.MutationFunction<
  CreateListingFeedbackResponse,
  CreateListingFeedbackVariables
>;

/**
 * __useCreateListingFeedback__
 *
 * To run a mutation, you first call `useCreateListingFeedback` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateListingFeedback` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createListingFeedback, { data, loading, error }] = useCreateListingFeedback({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateListingFeedback(
  baseOptions?: Apollo.MutationHookOptions<
    CreateListingFeedbackResponse,
    CreateListingFeedbackVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateListingFeedbackResponse, CreateListingFeedbackVariables>(
    CreateListingFeedbackDocument,
    options
  );
}
export type CreateListingFeedbackHookResult = ReturnType<typeof useCreateListingFeedback>;
export type CreateListingFeedbackMutationResult =
  Apollo.MutationResult<CreateListingFeedbackResponse>;
export type CreateListingFeedbackMutationOptions = Apollo.BaseMutationOptions<
  CreateListingFeedbackResponse,
  CreateListingFeedbackVariables
>;
export const CreateUserDocument = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      user {
        id
      }
    }
  }
`;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserResponse, CreateUserVariables>;

/**
 * __useCreateUser__
 *
 * To run a mutation, you first call `useCreateUser` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUser` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUser, { data, loading, error }] = useCreateUser({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUser(
  baseOptions?: Apollo.MutationHookOptions<CreateUserResponse, CreateUserVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserResponse, CreateUserVariables>(CreateUserDocument, options);
}
export type CreateUserHookResult = ReturnType<typeof useCreateUser>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserResponse>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  CreateUserResponse,
  CreateUserVariables
>;
export const CreditCodeByBundleIdDocument = gql`
  query CreditCodeByBundleId($bundleId: String!) {
    bundle(id: $bundleId) {
      id
      creditCode {
        id
        code
        amount
        type
        currency
      }
    }
  }
`;

/**
 * __useCreditCodeByBundleId__
 *
 * To run a query within a React component, call `useCreditCodeByBundleId` and pass it any options that fit your needs.
 * When your component renders, `useCreditCodeByBundleId` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditCodeByBundleId({
 *   variables: {
 *      bundleId: // value for 'bundleId'
 *   },
 * });
 */
export function useCreditCodeByBundleId(
  baseOptions: Apollo.QueryHookOptions<CreditCodeByBundleIdResponse, CreditCodeByBundleIdVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CreditCodeByBundleIdResponse, CreditCodeByBundleIdVariables>(
    CreditCodeByBundleIdDocument,
    options
  );
}
export function useCreditCodeByBundleIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CreditCodeByBundleIdResponse,
    CreditCodeByBundleIdVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CreditCodeByBundleIdResponse, CreditCodeByBundleIdVariables>(
    CreditCodeByBundleIdDocument,
    options
  );
}
export type CreditCodeByBundleIdHookResult = ReturnType<typeof useCreditCodeByBundleId>;
export type CreditCodeByBundleIdLazyQueryHookResult = ReturnType<
  typeof useCreditCodeByBundleIdLazyQuery
>;
export type CreditCodeByBundleIdQueryResult = Apollo.QueryResult<
  CreditCodeByBundleIdResponse,
  CreditCodeByBundleIdVariables
>;
export const DisbursementByIdDocument = gql`
  query DisbursementById($disbursementId: String!) {
    disbursement(id: $disbursementId) {
      id
      startDate
      endDate
      type
      status
      currency
      discountsTotal
      returnInsuranceTotal
      shippingTotal
      taxTotal
      customerPaidTotal
      peerCashSalesGMVTotal
      peerCreditSalesGMVTotal
      brandDirectSalesGMVTotal
      relistedTradeInSalesGMVTotal
      grossMerchandiseValueTotal
      peerSellerPayoutTotal
      treetTakeTotal
      ccProcessingTotal
      brandCoveredDiscountsTotal
      salesCostTotal
      reimbursedShippingCostTotal
      additionalLineItems
      reimbursementsTotal
      returnShippingCostTotal
      brandCoveredShippingCostTotal
      additionalCostsTotal
      brandDirectSalesPayoutTotal
      relistedTradeInSalesPayoutTotal
      previouslyDepositedTotal
      grossMarginTotal
      earningsTotal
      finalDepositTotal
    }
  }
`;

/**
 * __useDisbursementById__
 *
 * To run a query within a React component, call `useDisbursementById` and pass it any options that fit your needs.
 * When your component renders, `useDisbursementById` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisbursementById({
 *   variables: {
 *      disbursementId: // value for 'disbursementId'
 *   },
 * });
 */
export function useDisbursementById(
  baseOptions: Apollo.QueryHookOptions<DisbursementByIdResponse, DisbursementByIdVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DisbursementByIdResponse, DisbursementByIdVariables>(
    DisbursementByIdDocument,
    options
  );
}
export function useDisbursementByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DisbursementByIdResponse, DisbursementByIdVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DisbursementByIdResponse, DisbursementByIdVariables>(
    DisbursementByIdDocument,
    options
  );
}
export type DisbursementByIdHookResult = ReturnType<typeof useDisbursementById>;
export type DisbursementByIdLazyQueryHookResult = ReturnType<typeof useDisbursementByIdLazyQuery>;
export type DisbursementByIdQueryResult = Apollo.QueryResult<
  DisbursementByIdResponse,
  DisbursementByIdVariables
>;
export const FavoritedItemsDocument = gql`
  query FavoritedItems {
    favoritedItems {
      id
      listing {
        id
        sharetribeListingId
      }
    }
  }
`;

/**
 * __useFavoritedItems__
 *
 * To run a query within a React component, call `useFavoritedItems` and pass it any options that fit your needs.
 * When your component renders, `useFavoritedItems` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFavoritedItems({
 *   variables: {
 *   },
 * });
 */
export function useFavoritedItems(
  baseOptions?: Apollo.QueryHookOptions<FavoritedItemsResponse, FavoritedItemsVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FavoritedItemsResponse, FavoritedItemsVariables>(
    FavoritedItemsDocument,
    options
  );
}
export function useFavoritedItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FavoritedItemsResponse, FavoritedItemsVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FavoritedItemsResponse, FavoritedItemsVariables>(
    FavoritedItemsDocument,
    options
  );
}
export type FavoritedItemsHookResult = ReturnType<typeof useFavoritedItems>;
export type FavoritedItemsLazyQueryHookResult = ReturnType<typeof useFavoritedItemsLazyQuery>;
export type FavoritedItemsQueryResult = Apollo.QueryResult<
  FavoritedItemsResponse,
  FavoritedItemsVariables
>;
export const OrderByIdDocument = gql`
  query OrderById($orderId: String!) {
    order(id: $orderId) {
      sharetribeBuyerId
      bundles {
        id
        sharetribeTransactionIds
        lineItems {
          ...CoreLineItem
        }
        bundleItems {
          id
          listing {
            id
            sharetribeListingId
          }
        }
      }
    }
  }
  ${CoreLineItem}
`;

/**
 * __useOrderById__
 *
 * To run a query within a React component, call `useOrderById` and pass it any options that fit your needs.
 * When your component renders, `useOrderById` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderById({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useOrderById(
  baseOptions: Apollo.QueryHookOptions<OrderByIdResponse, OrderByIdVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderByIdResponse, OrderByIdVariables>(OrderByIdDocument, options);
}
export function useOrderByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderByIdResponse, OrderByIdVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderByIdResponse, OrderByIdVariables>(OrderByIdDocument, options);
}
export type OrderByIdHookResult = ReturnType<typeof useOrderById>;
export type OrderByIdLazyQueryHookResult = ReturnType<typeof useOrderByIdLazyQuery>;
export type OrderByIdQueryResult = Apollo.QueryResult<OrderByIdResponse, OrderByIdVariables>;
export const RemoveCartItemDocument = gql`
  mutation RemoveCartItem($input: RemoveCartItemsInput!) {
    removeCartItem(input: $input) {
      id
      listing {
        id
        sharetribeListingId
      }
    }
  }
`;
export type RemoveCartItemMutationFn = Apollo.MutationFunction<
  RemoveCartItemResponse,
  RemoveCartItemVariables
>;

/**
 * __useRemoveCartItem__
 *
 * To run a mutation, you first call `useRemoveCartItem` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCartItem` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCartItem, { data, loading, error }] = useRemoveCartItem({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveCartItem(
  baseOptions?: Apollo.MutationHookOptions<RemoveCartItemResponse, RemoveCartItemVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveCartItemResponse, RemoveCartItemVariables>(
    RemoveCartItemDocument,
    options
  );
}
export type RemoveCartItemHookResult = ReturnType<typeof useRemoveCartItem>;
export type RemoveCartItemMutationResult = Apollo.MutationResult<RemoveCartItemResponse>;
export type RemoveCartItemMutationOptions = Apollo.BaseMutationOptions<
  RemoveCartItemResponse,
  RemoveCartItemVariables
>;
export const RemoveFavoritedItemDocument = gql`
  mutation RemoveFavoritedItem($input: RemoveFavoritedItemInput!) {
    removeFavoritedItem(input: $input) {
      favoritedItem {
        userId
        listingId
      }
    }
  }
`;
export type RemoveFavoritedItemMutationFn = Apollo.MutationFunction<
  RemoveFavoritedItemResponse,
  RemoveFavoritedItemVariables
>;

/**
 * __useRemoveFavoritedItem__
 *
 * To run a mutation, you first call `useRemoveFavoritedItem` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFavoritedItem` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFavoritedItem, { data, loading, error }] = useRemoveFavoritedItem({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveFavoritedItem(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveFavoritedItemResponse,
    RemoveFavoritedItemVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveFavoritedItemResponse, RemoveFavoritedItemVariables>(
    RemoveFavoritedItemDocument,
    options
  );
}
export type RemoveFavoritedItemHookResult = ReturnType<typeof useRemoveFavoritedItem>;
export type RemoveFavoritedItemMutationResult = Apollo.MutationResult<RemoveFavoritedItemResponse>;
export type RemoveFavoritedItemMutationOptions = Apollo.BaseMutationOptions<
  RemoveFavoritedItemResponse,
  RemoveFavoritedItemVariables
>;
export const SavedSearchByEmailDocument = gql`
  query SavedSearchByEmail($email: String!, $treetId: String!, $type: SavedSearchType!) {
    savedSearch(email: $email, treetId: $treetId, type: $type) {
      id
      search
      cadence
    }
  }
`;

/**
 * __useSavedSearchByEmail__
 *
 * To run a query within a React component, call `useSavedSearchByEmail` and pass it any options that fit your needs.
 * When your component renders, `useSavedSearchByEmail` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSavedSearchByEmail({
 *   variables: {
 *      email: // value for 'email'
 *      treetId: // value for 'treetId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useSavedSearchByEmail(
  baseOptions: Apollo.QueryHookOptions<SavedSearchByEmailResponse, SavedSearchByEmailVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SavedSearchByEmailResponse, SavedSearchByEmailVariables>(
    SavedSearchByEmailDocument,
    options
  );
}
export function useSavedSearchByEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SavedSearchByEmailResponse, SavedSearchByEmailVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SavedSearchByEmailResponse, SavedSearchByEmailVariables>(
    SavedSearchByEmailDocument,
    options
  );
}
export type SavedSearchByEmailHookResult = ReturnType<typeof useSavedSearchByEmail>;
export type SavedSearchByEmailLazyQueryHookResult = ReturnType<
  typeof useSavedSearchByEmailLazyQuery
>;
export type SavedSearchByEmailQueryResult = Apollo.QueryResult<
  SavedSearchByEmailResponse,
  SavedSearchByEmailVariables
>;
export const ShopByHostnameDocument = gql`
  query ShopByHostname($hostname: String!) {
    shopByHostname(hostname: $hostname) {
      id
      treetId
      canonicalRootUrl
      defaultCurrency
      primaryLocale
    }
  }
`;

/**
 * __useShopByHostname__
 *
 * To run a query within a React component, call `useShopByHostname` and pass it any options that fit your needs.
 * When your component renders, `useShopByHostname` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopByHostname({
 *   variables: {
 *      hostname: // value for 'hostname'
 *   },
 * });
 */
export function useShopByHostname(
  baseOptions: Apollo.QueryHookOptions<ShopByHostnameResponse, ShopByHostnameVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ShopByHostnameResponse, ShopByHostnameVariables>(
    ShopByHostnameDocument,
    options
  );
}
export function useShopByHostnameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ShopByHostnameResponse, ShopByHostnameVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ShopByHostnameResponse, ShopByHostnameVariables>(
    ShopByHostnameDocument,
    options
  );
}
export type ShopByHostnameHookResult = ReturnType<typeof useShopByHostname>;
export type ShopByHostnameLazyQueryHookResult = ReturnType<typeof useShopByHostnameLazyQuery>;
export type ShopByHostnameQueryResult = Apollo.QueryResult<
  ShopByHostnameResponse,
  ShopByHostnameVariables
>;
export const ShopByTreetIdDocument = gql`
  query ShopByTreetId($treetId: String!) {
    shop(treetId: $treetId) {
      id
      canonicalRootUrl
      defaultCurrency
      primaryLocale
    }
  }
`;

/**
 * __useShopByTreetId__
 *
 * To run a query within a React component, call `useShopByTreetId` and pass it any options that fit your needs.
 * When your component renders, `useShopByTreetId` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopByTreetId({
 *   variables: {
 *      treetId: // value for 'treetId'
 *   },
 * });
 */
export function useShopByTreetId(
  baseOptions: Apollo.QueryHookOptions<ShopByTreetIdResponse, ShopByTreetIdVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ShopByTreetIdResponse, ShopByTreetIdVariables>(
    ShopByTreetIdDocument,
    options
  );
}
export function useShopByTreetIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ShopByTreetIdResponse, ShopByTreetIdVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ShopByTreetIdResponse, ShopByTreetIdVariables>(
    ShopByTreetIdDocument,
    options
  );
}
export type ShopByTreetIdHookResult = ReturnType<typeof useShopByTreetId>;
export type ShopByTreetIdLazyQueryHookResult = ReturnType<typeof useShopByTreetIdLazyQuery>;
export type ShopByTreetIdQueryResult = Apollo.QueryResult<
  ShopByTreetIdResponse,
  ShopByTreetIdVariables
>;
export const UpdateAddressDocument = gql`
  mutation UpdateAddress($input: UpdateAddressInput!) {
    updateAddress(input: $input) {
      address {
        ...CoreAddress
      }
    }
  }
  ${CoreAddress}
`;
export type UpdateAddressMutationFn = Apollo.MutationFunction<
  UpdateAddressResponse,
  UpdateAddressVariables
>;

/**
 * __useUpdateAddress__
 *
 * To run a mutation, you first call `useUpdateAddress` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAddress` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAddress, { data, loading, error }] = useUpdateAddress({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAddress(
  baseOptions?: Apollo.MutationHookOptions<UpdateAddressResponse, UpdateAddressVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAddressResponse, UpdateAddressVariables>(
    UpdateAddressDocument,
    options
  );
}
export type UpdateAddressHookResult = ReturnType<typeof useUpdateAddress>;
export type UpdateAddressMutationResult = Apollo.MutationResult<UpdateAddressResponse>;
export type UpdateAddressMutationOptions = Apollo.BaseMutationOptions<
  UpdateAddressResponse,
  UpdateAddressVariables
>;
export const UpdateBundleDocument = gql`
  mutation UpdateBundle($input: UpdateBundleInput!) {
    updateBundle(input: $input) {
      bundle {
        id
        status
        isMarkedFulfilled
        canceledAt
        expireAt
        lineItems {
          ...CoreLineItem
        }
      }
    }
  }
  ${CoreLineItem}
`;
export type UpdateBundleMutationFn = Apollo.MutationFunction<
  UpdateBundleResponse,
  UpdateBundleVariables
>;

/**
 * __useUpdateBundle__
 *
 * To run a mutation, you first call `useUpdateBundle` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBundle` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBundle, { data, loading, error }] = useUpdateBundle({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBundle(
  baseOptions?: Apollo.MutationHookOptions<UpdateBundleResponse, UpdateBundleVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBundleResponse, UpdateBundleVariables>(
    UpdateBundleDocument,
    options
  );
}
export type UpdateBundleHookResult = ReturnType<typeof useUpdateBundle>;
export type UpdateBundleMutationResult = Apollo.MutationResult<UpdateBundleResponse>;
export type UpdateBundleMutationOptions = Apollo.BaseMutationOptions<
  UpdateBundleResponse,
  UpdateBundleVariables
>;
export const UpdateBundleItemDocument = gql`
  mutation UpdateBundleItem($input: UpdateBundleItemInput!) {
    updateBundleItem(input: $input) {
      bundleItem {
        id
        status
      }
    }
  }
`;
export type UpdateBundleItemMutationFn = Apollo.MutationFunction<
  UpdateBundleItemResponse,
  UpdateBundleItemVariables
>;

/**
 * __useUpdateBundleItem__
 *
 * To run a mutation, you first call `useUpdateBundleItem` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBundleItem` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBundleItem, { data, loading, error }] = useUpdateBundleItem({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBundleItem(
  baseOptions?: Apollo.MutationHookOptions<UpdateBundleItemResponse, UpdateBundleItemVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBundleItemResponse, UpdateBundleItemVariables>(
    UpdateBundleItemDocument,
    options
  );
}
export type UpdateBundleItemHookResult = ReturnType<typeof useUpdateBundleItem>;
export type UpdateBundleItemMutationResult = Apollo.MutationResult<UpdateBundleItemResponse>;
export type UpdateBundleItemMutationOptions = Apollo.BaseMutationOptions<
  UpdateBundleItemResponse,
  UpdateBundleItemVariables
>;
export const UpdateUserDocument = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        email
        stripeAccountId
      }
    }
  }
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserResponse, UpdateUserVariables>;

/**
 * __useUpdateUser__
 *
 * To run a mutation, you first call `useUpdateUser` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUser` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUser, { data, loading, error }] = useUpdateUser({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUser(
  baseOptions?: Apollo.MutationHookOptions<UpdateUserResponse, UpdateUserVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserResponse, UpdateUserVariables>(UpdateUserDocument, options);
}
export type UpdateUserHookResult = ReturnType<typeof useUpdateUser>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserResponse>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserResponse,
  UpdateUserVariables
>;
export const UpsertGeneralSavedSearchDocument = gql`
  mutation UpsertGeneralSavedSearch($input: UpsertGeneralSavedSearchInput!) {
    upsertGeneralSavedSearch(input: $input) {
      savedSearch {
        id
        email
        search
        cadence
        shop {
          id
        }
        subscribeSource
      }
    }
  }
`;
export type UpsertGeneralSavedSearchMutationFn = Apollo.MutationFunction<
  UpsertGeneralSavedSearchResponse,
  UpsertGeneralSavedSearchVariables
>;

/**
 * __useUpsertGeneralSavedSearch__
 *
 * To run a mutation, you first call `useUpsertGeneralSavedSearch` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertGeneralSavedSearch` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertGeneralSavedSearch, { data, loading, error }] = useUpsertGeneralSavedSearch({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertGeneralSavedSearch(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertGeneralSavedSearchResponse,
    UpsertGeneralSavedSearchVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpsertGeneralSavedSearchResponse, UpsertGeneralSavedSearchVariables>(
    UpsertGeneralSavedSearchDocument,
    options
  );
}
export type UpsertGeneralSavedSearchHookResult = ReturnType<typeof useUpsertGeneralSavedSearch>;
export type UpsertGeneralSavedSearchMutationResult =
  Apollo.MutationResult<UpsertGeneralSavedSearchResponse>;
export type UpsertGeneralSavedSearchMutationOptions = Apollo.BaseMutationOptions<
  UpsertGeneralSavedSearchResponse,
  UpsertGeneralSavedSearchVariables
>;
export const UpsertIsoSavedSearchDocument = gql`
  mutation UpsertISOSavedSearch($input: UpsertISOSavedSearchInput!) {
    upsertISOSavedSearch(input: $input) {
      savedSearch {
        id
        email
        search
        cadence
        shop {
          id
        }
        subscribeSource
      }
      metadata {
        created
        updated
      }
    }
  }
`;
export type UpsertIsoSavedSearchMutationFn = Apollo.MutationFunction<
  UpsertIsoSavedSearchResponse,
  UpsertIsoSavedSearchVariables
>;

/**
 * __useUpsertIsoSavedSearch__
 *
 * To run a mutation, you first call `useUpsertIsoSavedSearch` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertIsoSavedSearch` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertIsoSavedSearch, { data, loading, error }] = useUpsertIsoSavedSearch({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertIsoSavedSearch(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertIsoSavedSearchResponse,
    UpsertIsoSavedSearchVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpsertIsoSavedSearchResponse, UpsertIsoSavedSearchVariables>(
    UpsertIsoSavedSearchDocument,
    options
  );
}
export type UpsertIsoSavedSearchHookResult = ReturnType<typeof useUpsertIsoSavedSearch>;
export type UpsertIsoSavedSearchMutationResult =
  Apollo.MutationResult<UpsertIsoSavedSearchResponse>;
export type UpsertIsoSavedSearchMutationOptions = Apollo.BaseMutationOptions<
  UpsertIsoSavedSearchResponse,
  UpsertIsoSavedSearchVariables
>;
export const UpsertPriceDropConfigDocument = gql`
  mutation UpsertPriceDropConfig($input: UpsertPriceDropConfigInput!) {
    upsertPriceDropConfig(input: $input) {
      priceDropConfig {
        id
      }
    }
  }
`;
export type UpsertPriceDropConfigMutationFn = Apollo.MutationFunction<
  UpsertPriceDropConfigResponse,
  UpsertPriceDropConfigVariables
>;

/**
 * __useUpsertPriceDropConfig__
 *
 * To run a mutation, you first call `useUpsertPriceDropConfig` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertPriceDropConfig` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertPriceDropConfig, { data, loading, error }] = useUpsertPriceDropConfig({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertPriceDropConfig(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertPriceDropConfigResponse,
    UpsertPriceDropConfigVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpsertPriceDropConfigResponse, UpsertPriceDropConfigVariables>(
    UpsertPriceDropConfigDocument,
    options
  );
}
export type UpsertPriceDropConfigHookResult = ReturnType<typeof useUpsertPriceDropConfig>;
export type UpsertPriceDropConfigMutationResult =
  Apollo.MutationResult<UpsertPriceDropConfigResponse>;
export type UpsertPriceDropConfigMutationOptions = Apollo.BaseMutationOptions<
  UpsertPriceDropConfigResponse,
  UpsertPriceDropConfigVariables
>;
export const GetPriceDropConfigBySharetribeListingIdDocument = gql`
  query GetPriceDropConfigBySharetribeListingId($sharetribeListingId: ID) {
    priceDropConfig(sharetribeListingId: $sharetribeListingId) {
      id
      anchorPrice
      minPrice
      nextScheduledDrop
      isActive
      percentageDrop
    }
  }
`;

/**
 * __useGetPriceDropConfigBySharetribeListingId__
 *
 * To run a query within a React component, call `useGetPriceDropConfigBySharetribeListingId` and pass it any options that fit your needs.
 * When your component renders, `useGetPriceDropConfigBySharetribeListingId` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPriceDropConfigBySharetribeListingId({
 *   variables: {
 *      sharetribeListingId: // value for 'sharetribeListingId'
 *   },
 * });
 */
export function useGetPriceDropConfigBySharetribeListingId(
  baseOptions?: Apollo.QueryHookOptions<
    GetPriceDropConfigBySharetribeListingIdResponse,
    GetPriceDropConfigBySharetribeListingIdVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPriceDropConfigBySharetribeListingIdResponse,
    GetPriceDropConfigBySharetribeListingIdVariables
  >(GetPriceDropConfigBySharetribeListingIdDocument, options);
}
export function useGetPriceDropConfigBySharetribeListingIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPriceDropConfigBySharetribeListingIdResponse,
    GetPriceDropConfigBySharetribeListingIdVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPriceDropConfigBySharetribeListingIdResponse,
    GetPriceDropConfigBySharetribeListingIdVariables
  >(GetPriceDropConfigBySharetribeListingIdDocument, options);
}
export type GetPriceDropConfigBySharetribeListingIdHookResult = ReturnType<
  typeof useGetPriceDropConfigBySharetribeListingId
>;
export type GetPriceDropConfigBySharetribeListingIdLazyQueryHookResult = ReturnType<
  typeof useGetPriceDropConfigBySharetribeListingIdLazyQuery
>;
export type GetPriceDropConfigBySharetribeListingIdQueryResult = Apollo.QueryResult<
  GetPriceDropConfigBySharetribeListingIdResponse,
  GetPriceDropConfigBySharetribeListingIdVariables
>;
export const IsoSavedSearchesByEmailDocument = gql`
  query ISOSavedSearchesByEmail($email: String!, $treetId: String!) {
    isoSavedSearches(email: $email, treetId: $treetId) {
      id
      search
    }
  }
`;

/**
 * __useIsoSavedSearchesByEmail__
 *
 * To run a query within a React component, call `useIsoSavedSearchesByEmail` and pass it any options that fit your needs.
 * When your component renders, `useIsoSavedSearchesByEmail` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsoSavedSearchesByEmail({
 *   variables: {
 *      email: // value for 'email'
 *      treetId: // value for 'treetId'
 *   },
 * });
 */
export function useIsoSavedSearchesByEmail(
  baseOptions: Apollo.QueryHookOptions<
    IsoSavedSearchesByEmailResponse,
    IsoSavedSearchesByEmailVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IsoSavedSearchesByEmailResponse, IsoSavedSearchesByEmailVariables>(
    IsoSavedSearchesByEmailDocument,
    options
  );
}
export function useIsoSavedSearchesByEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IsoSavedSearchesByEmailResponse,
    IsoSavedSearchesByEmailVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IsoSavedSearchesByEmailResponse, IsoSavedSearchesByEmailVariables>(
    IsoSavedSearchesByEmailDocument,
    options
  );
}
export type IsoSavedSearchesByEmailHookResult = ReturnType<typeof useIsoSavedSearchesByEmail>;
export type IsoSavedSearchesByEmailLazyQueryHookResult = ReturnType<
  typeof useIsoSavedSearchesByEmailLazyQuery
>;
export type IsoSavedSearchesByEmailQueryResult = Apollo.QueryResult<
  IsoSavedSearchesByEmailResponse,
  IsoSavedSearchesByEmailVariables
>;
export const namedOperations = {
  Query: {
    AllBrandDisbursements: 'AllBrandDisbursements',
    AllBrandReports: 'AllBrandReports',
    AllBundles: 'AllBundles',
    AllOrders: 'AllOrders',
    BundleById: 'BundleById',
    CartItems: 'CartItems',
    CreditCodeByBundleId: 'CreditCodeByBundleId',
    DisbursementById: 'DisbursementById',
    FavoritedItems: 'FavoritedItems',
    OrderById: 'OrderById',
    SavedSearchByEmail: 'SavedSearchByEmail',
    ShopByHostname: 'ShopByHostname',
    ShopByTreetId: 'ShopByTreetId',
    GetPriceDropConfigBySharetribeListingId: 'GetPriceDropConfigBySharetribeListingId',
    ISOSavedSearchesByEmail: 'ISOSavedSearchesByEmail',
  },
  Mutation: {
    CreateCancellation: 'CreateCancellation',
    CreateCartItem: 'CreateCartItem',
    UpsertDispute: 'UpsertDispute',
    CreateFavoritedItem: 'CreateFavoritedItem',
    UpsertListing: 'UpsertListing',
    CreateListingFeedback: 'CreateListingFeedback',
    CreateUser: 'CreateUser',
    RemoveCartItem: 'RemoveCartItem',
    RemoveFavoritedItem: 'RemoveFavoritedItem',
    UpdateAddress: 'UpdateAddress',
    UpdateBundle: 'UpdateBundle',
    UpdateBundleItem: 'UpdateBundleItem',
    UpdateUser: 'UpdateUser',
    UpsertGeneralSavedSearch: 'UpsertGeneralSavedSearch',
    UpsertISOSavedSearch: 'UpsertISOSavedSearch',
    UpsertPriceDropConfig: 'UpsertPriceDropConfig',
  },
  Fragment: {
    CoreAddress: 'CoreAddress',
    CoreBundle: 'CoreBundle',
    CoreBundleItem: 'CoreBundleItem',
    CoreDisbursement: 'CoreDisbursement',
    CoreDiscountLineItem: 'CoreDiscountLineItem',
    CoreFulfillment: 'CoreFulfillment',
    CoreLineItem: 'CoreLineItem',
    CoreListingLineItem: 'CoreListingLineItem',
    CoreOrder: 'CoreOrder',
    CoreRefund: 'CoreRefund',
    CoreReturnInsuranceLineItem: 'CoreReturnInsuranceLineItem',
    CoreShippingLineItem: 'CoreShippingLineItem',
    CoreTaxLineItem: 'CoreTaxLineItem',
  },
};
