/* eslint-disable import/prefer-default-export */
import { ListingWithImages, OwnListingWithImages } from '../types/sharetribe/listing';
import {
  GetFeaturedImageInputs,
  getFeaturedListingImageHelper,
  getSharetribeImagesForListing,
  getStockImagesForListing,
  getUploadcareImagesForListing,
  maybeSmartResizeImage,
} from '../util/listings/listingImages';
import { useShopConfig } from './shopConfig';

type ExtendedGetFeaturedImageInputs = GetFeaturedImageInputs & {
  imageIndex: number;
};

// Keep core logic in sync with server/utils/listings.ts
export const getFeaturedListingImage = (params: GetFeaturedImageInputs) => {
  const {
    firstStockImageSrc,
    firstSellerImage,
    firstSellerImageSrc,
    shouldShowStockImage,
    isUploadcareEnabled,
  } = getFeaturedListingImageHelper(params);

  const { listing, imageRatio } = params;

  const treetId = listing?.attributes?.publicData?.domain;

  if (shouldShowStockImage && firstStockImageSrc) {
    const src = maybeSmartResizeImage({
      src: firstStockImageSrc,
      treetId,
      imageRatio,
    });
    return { src };
  }

  if (isUploadcareEnabled && firstSellerImageSrc) {
    const src = maybeSmartResizeImage({
      src: firstSellerImageSrc,
      treetId,
      imageRatio,
    });

    return {
      src,
    };
  }

  return { image: firstSellerImage };
};

/**
 * Returns the featured image for the listing
 */
export const useFeaturedListingImage = (
  listing: ListingWithImages | OwnListingWithImages,
  forceHideStockImages?: boolean
) => {
  const {
    chooseStockImagesOption,
    maxStockImages,
    listingFlowConfig: { featuredListingImageType },
    imageRatio,
  } = useShopConfig();

  return getFeaturedListingImage({
    showStockImages: !forceHideStockImages && maxStockImages > 0,
    featuredListingImageType,
    chooseStockImagesOption,
    listing,
    imageRatio,
  });
};

// TODO: (HM |TREET-6824) refactor getSpecificListingImage and getFeaturedListingImage
/*
  returns a specific image from the ordered list of images for a listing,
  taking into account the featured image rules
*/
export const getSpecificListingImage = (params: ExtendedGetFeaturedImageInputs) => {
  if (!params?.listing) return null;
  const { chooseStockImagesOption, listing, imageRatio, imageIndex } = params;
  const { shouldShowStockImage, isUploadcareEnabled } = getFeaturedListingImageHelper(params);

  const treetId = listing?.attributes?.publicData?.domain;

  if (shouldShowStockImage) {
    const listingStockImages = getStockImagesForListing(listing, chooseStockImagesOption) || [];
    const stockImageSrc = listingStockImages?.[imageIndex];
    if (stockImageSrc) {
      return maybeSmartResizeImage({ src: stockImageSrc, treetId, imageRatio });
    }
  }
  if (isUploadcareEnabled) {
    const uploadcareImages = getUploadcareImagesForListing(listing);
    const sellerImageSrc = uploadcareImages?.[imageIndex];
    if (sellerImageSrc) {
      return maybeSmartResizeImage({ src: sellerImageSrc, treetId, imageRatio });
    }
  }
  const sharetribeImages = getSharetribeImagesForListing(listing);
  const shareTribeImage = sharetribeImages?.[imageIndex];
  return shareTribeImage;
};

// TODO: (HM |TREET-6824) refactor useSpecificListingImage and useFeaturedListingImage
export const useSpecificListingImage = (
  listing: ListingWithImages | OwnListingWithImages,
  imageIndex: number,
  forceHideStockImages?: boolean
) => {
  const {
    chooseStockImagesOption,
    maxStockImages,
    listingFlowConfig: { featuredListingImageType },
    imageRatio,
  } = useShopConfig();

  return getSpecificListingImage({
    showStockImages: !forceHideStockImages && maxStockImages > 0 && maxStockImages > imageIndex,
    featuredListingImageType,
    chooseStockImagesOption,
    listing,
    imageRatio,
    imageIndex,
  });
};
