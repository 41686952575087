import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfigFromOptions } from '../filters/size';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { BuilderSections } from '../../util/builder';

const robertgrahamSizes = [
  { key: 'XS', label: 'XS' },
  { key: 'S', label: 'S' },
  { key: 'M', label: 'M' },
  { key: 'L', label: 'L' },
  { key: 'XL', label: 'XL' },
  { key: '2XL', label: '2XL' },
  { key: '3XL', label: '3XL' },
  { key: '4XL', label: '4XL' },
  { key: 'ONE SIZE', label: 'ONE SIZE' },
  { key: '30W', label: '30W' },
  { key: '32W', label: '32W' },
  { key: '34W', label: '34W' },
  { key: '36W', label: '36W' },
  { key: '38W', label: '38W' },
  { key: '40W', label: '40W' },
  { key: '42W', label: '42W' },
  { key: '8', label: 'Shoes: 8' },
  { key: '8.5', label: 'Shoes: 8.5' },
  { key: '9', label: 'Shoes: 9' },
  { key: '9.5', label: 'Shoes: 9.5' },
  { key: '10', label: 'Shoes: 10' },
  { key: '10.5', label: 'Shoes: 10.5' },
  { key: '11', label: 'Shoes: 11' },
  { key: '11.5', label: 'Shoes: 11.5' },
  { key: '12', label: 'Shoes: 12' },
  { key: '13', label: 'Shoes: 13' },
];

export const robertgrahamShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getBrandDirectFilterConfig(),
    getSizeFilterConfigFromOptions({
      options: robertgrahamSizes,
    }),
  ],
  builderConfig: {
    sections: {
      [BuilderSections.LandingPageContent]: '3ebb3cfe3fb246f99d145697d4f79a11',
    },
  },
};
