import { Box } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { Field, useForm } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { useSelector } from 'react-redux';
import { FieldCheckbox, FieldSelect, FieldTextInput, ItemizedListing, TypographyWrapper } from '..';
import { useShopConfig } from '../../hooks/shopConfig';
import { BundleType, CoreBundleItem } from '../../types/apollo/generated/types.generated';
import { DisputeFormData } from '../../types/forms/disputeFormData';
import { BundleInfo } from '../../types/models/bundle';
import { ListingItemType, ListingWithImages } from '../../types/sharetribe/listing';
import { CLAIM_TYPES, TradeInPayoutOption } from '../../util/constants';
import * as validators from '../../util/validators';
import { getOverrideButtonStyles } from '../Button/Button';
import { BUNDLE_ITEM_IDS_FIELD_NAME, DISPUTES_INFO_FIELD_PREFIX } from './constants';
import DisputeOrVerifyTradeInModalWizard, { WizardPage } from './DisputeOrVerifyTradeInModalWizard';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useTradeInConfig } from '../../hooks/useTradeInConfig';
import { getBundleListings } from '../../util/bundles';

const REFERRER_LOCATION = 'Dispute Modal';

const CLAIM_TYPE_FIELD_NAME = 'claim-type';

interface DisputeModalGenericSectionProps {
  bundleItemId: string;
}

interface DisputeModalDescriptionSectionProps extends DisputeModalGenericSectionProps {
  isRequired: boolean;
}

const ClaimReasonSelect: FC<DisputeModalGenericSectionProps> = (props) => {
  const { bundleItemId } = props;
  return (
    <FieldSelect
      id={`${DISPUTES_INFO_FIELD_PREFIX}.${bundleItemId}.claimType`} // disputesInfo.5203-29349.claimType
      name={`${DISPUTES_INFO_FIELD_PREFIX}.${bundleItemId}.claimType`}
      disabled={false}
      label="Reason for claim"
      validate={validators.required('Please choose an option')}
    >
      <option disabled value="">
        Please choose a reason
      </option>
      {CLAIM_TYPES.map(({ key, label }) => (
        <option key={key} value={key}>
          {label}
        </option>
      ))}
    </FieldSelect>
  );
};

const TradeInCreditSelect: FC<DisputeModalGenericSectionProps> = (props) => {
  const { bundleItemId } = props;
  const tradeInConfig = useTradeInConfig();
  const form = useForm();

  const tradeInCreditPayoutOptions =
    tradeInConfig?.tradeInCreditPayoutOptionsCollection?.items || [];

  return (
    <FieldSelect
      id={`${DISPUTES_INFO_FIELD_PREFIX}.${bundleItemId}.claimTypeKey`}
      name={`${DISPUTES_INFO_FIELD_PREFIX}.${bundleItemId}.claimTypeKey`}
      disabled={false}
      label="Credit payout"
      validate={validators.required('Please choose an option')}
      onChange={(event: any) => {
        const claimType =
          event.target.options[event.target.selectedIndex].getAttribute(CLAIM_TYPE_FIELD_NAME);
        const { value } = event.target;
        form.change(`${DISPUTES_INFO_FIELD_PREFIX}.${bundleItemId}.claimType`, claimType);
        form.change(`${DISPUTES_INFO_FIELD_PREFIX}.${bundleItemId}.claimTypeKey`, value);
      }}
    >
      <option disabled value="">
        Please choose an option
      </option>
      {(tradeInCreditPayoutOptions as TradeInPayoutOption[]).map((tradeInCreditPayoutOption) => {
        const { key, uniqueKeyOverride, label } = tradeInCreditPayoutOption;
        return (
          <option
            key={uniqueKeyOverride || key}
            value={uniqueKeyOverride || key}
            {...{ [CLAIM_TYPE_FIELD_NAME]: key }}
          >
            {label}
          </option>
        );
      })}
    </FieldSelect>
  );
};

const Description: FC<DisputeModalDescriptionSectionProps> = (props) => {
  const { bundleItemId, isRequired } = props;
  return (
    <FieldTextInput
      id={`${DISPUTES_INFO_FIELD_PREFIX}.${bundleItemId}.description`}
      name={`${DISPUTES_INFO_FIELD_PREFIX}.${bundleItemId}.description`}
      type="textarea"
      label="Description"
      placeholder="Please provide a description of the discrepancy."
      {...(isRequired && { validate: validators.required('Please add a description') })}
    />
  );
};

const UploadImages: FC<DisputeModalGenericSectionProps> = (props) => {
  const { bundleItemId } = props;
  const [imagesUploadLibrary, setImagesUploadLibrary] = useState<any>();
  const { css: shopCss, styles: defaultTreetStyles } = useShopConfig();
  const isMobile = useIsMobile();

  let buttonOverrideStyles: any = {};
  if (shopCss?.primaryButton) {
    buttonOverrideStyles = getOverrideButtonStyles(shopCss?.primaryButton, false, false, isMobile);
    if (!buttonOverrideStyles.borderRadius) {
      // Image Uploader button is rounded by default,
      // so unset border radius if one is not specified to force
      // a rectangular button.
      buttonOverrideStyles.borderRadius = 'unset';
    }
  }

  useEffect(() => {
    const loadData = async () => {
      // HACK (annietsai|TREET-29): Load this in asynchronously
      // because for some reason, it doesn't play well with SSR
      const ImageUploader = await import('react-images-upload');
      setImagesUploadLibrary(ImageUploader as any);
    };
    loadData();
  }, []);

  if (!imagesUploadLibrary) return null;

  const ImageUploader = imagesUploadLibrary.default;

  return (
    // Not required because claim type can be "Item not received"
    <Field name={`${DISPUTES_INFO_FIELD_PREFIX}.${bundleItemId}.images`}>
      {(fieldProps) => (
        <ImageUploader
          withIcon={false}
          className="treet"
          style={{ maxWidth: '800px', margin: '20px auto' }}
          buttonStyles={buttonOverrideStyles}
          labelStyles={shopCss?.body2}
          errorStyle={{ ...shopCss?.body2, color: defaultTreetStyles.red60 }}
          withPreview
          buttonText="Choose images"
          onChange={(a: any, b: any) => {
            fieldProps.input.onChange(b);
          }}
          imgExtension={['.jpg', '.png']}
          label="5 images max (5mb max per file). Accepted: jpg or png"
          maxFileSize={5242880} // 5 * 1024 * 1024
        />
      )}
    </Field>
  );
};

const TradeInDisputeReason: FC<DisputeModalGenericSectionProps> = (props) => {
  const { bundleItemId } = props;
  return (
    <>
      <Box mb={2}>
        <TradeInCreditSelect bundleItemId={bundleItemId} />
      </Box>
      <Box mb={2}>
        <Description bundleItemId={bundleItemId} isRequired={false} />
      </Box>
    </>
  );
};

const MarketplaceDisputeReason: FC<DisputeModalGenericSectionProps> = (props) => {
  const { bundleItemId } = props;
  return (
    <>
      <Box mb={2}>
        <ClaimReasonSelect bundleItemId={bundleItemId} />
      </Box>
      <Box mb={2}>
        <Description bundleItemId={bundleItemId} isRequired />
      </Box>
      <UploadImages bundleItemId={bundleItemId} />
    </>
  );
};

const getBundleItemToListingIdMap = (bundleItems: CoreBundleItem[]): Map<string, string> =>
  new Map(bundleItems.map(({ id, listing: { sharetribeListingId } }) => [id, sharetribeListingId]));

interface DisputePageProps {
  listing: ListingWithImages;
  bundleItemId: string;
}

const DisputePage: FC<DisputePageProps> = (props: DisputePageProps) => {
  const { bundleItemId, listing } = props;
  const isTradeInItem = listing.attributes.publicData?.listingItemType === ListingItemType.TradeIn;

  return (
    <WizardPage>
      <TypographyWrapper variant="body1" typographyOverrides={{ style: { fontWeight: 'bold' } }}>
        What’s wrong with this item?
      </TypographyWrapper>
      <ItemizedListing listing={listing} referrerForLogging={REFERRER_LOCATION} />
      {isTradeInItem ? (
        <TradeInDisputeReason bundleItemId={bundleItemId} />
      ) : (
        <MarketplaceDisputeReason bundleItemId={bundleItemId} />
      )}
    </WizardPage>
  );
};

interface ItemizedBundleProps {
  bundle?: BundleInfo;
}

const ItemizedBundle: FC<ItemizedBundleProps> = (props) => {
  const { bundle } = props;
  const bundleItems = bundle?.bundleItems ?? [];
  const rootState = useSelector<any>((state) => state) as any;
  const listings = new Map(
    bundle ? getBundleListings(bundle, rootState).map((listing) => [listing.id.uuid, listing]) : []
  );
  const bundleItemIdToListingId = getBundleItemToListingIdMap(bundle?.bundleItems ?? []);
  return (
    <>
      {bundleItems.map((item) => {
        const sharetribeListingId = bundleItemIdToListingId.get(item.id) ?? '';
        const listing = listings.get(sharetribeListingId);
        const isTradeInItem =
          listing?.attributes?.publicData?.listingItemType === ListingItemType.TradeIn ?? false;

        return (
          listing && (
            <ItemizedListing
              key={item.id}
              listing={listing}
              actionEl={
                <FieldCheckbox
                  id={`${BUNDLE_ITEM_IDS_FIELD_NAME}.${item.id}`}
                  name={BUNDLE_ITEM_IDS_FIELD_NAME}
                  value={item.id}
                  validate={
                    isTradeInItem ? undefined : validators.requiredFieldArrayCheckbox('Required')
                  }
                />
              }
              referrerForLogging={REFERRER_LOCATION}
            />
          )
        );
      })}
    </>
  );
};

interface DisputeOrVerifyTradeInModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (values: DisputeFormData) => Promise<void>;
  onVerify: () => void;
  bundle?: BundleInfo;
}

const DisputeOrVerifyTradeInModal: FC<DisputeOrVerifyTradeInModalProps> = (
  props: DisputeOrVerifyTradeInModalProps
) => {
  const { open, bundle, onClose, onSubmit, onVerify } = props;

  const rootState = useSelector<any>((state) => state) as any;
  const [disputedBundleItemIds, setDisputedBundleItemIds] = useState<string[]>([]);
  const isTradeInBundle = bundle?.type === BundleType.TradeIn;
  const listings = new Map(
    getBundleListings(bundle, rootState).map((listing) => [listing.id.uuid, listing])
  );
  const bundleItemIdToListingId = getBundleItemToListingIdMap(bundle?.bundleItems ?? []);
  const renderWizardPages = () =>
    disputedBundleItemIds.map((bundleItemId) => {
      const sharetribeListingId = bundleItemIdToListingId.get(bundleItemId) ?? '';
      const listing = listings.get(sharetribeListingId);
      return (
        listing && <DisputePage listing={listing} bundleItemId={bundleItemId} key={bundleItemId} />
      );
    });

  const handleClose = () => {
    onClose();
    setDisputedBundleItemIds([]);
  };

  return (
    <DisputeOrVerifyTradeInModalWizard
      open={open}
      onClose={handleClose}
      onSubmit={onSubmit}
      onVerify={onVerify}
      isTradeIn={isTradeInBundle}
    >
      <WizardPage>
        {/* Using an OnChange + useState instead of using a FormSpy because if we use a FormSpy
         the WizardPages would be children of the FormSpy instead of the
         DisputeOrVerifyTradeInModalWizard, and it won't detect the WizardPages properly */}
        <OnChange name={BUNDLE_ITEM_IDS_FIELD_NAME}>
          {(value: string[]) => setDisputedBundleItemIds(value || [])}
        </OnChange>
        <Box pb={1}>
          <TypographyWrapper variant="body1">
            {isTradeInBundle
              ? 'Verify and issue credit for the items in this order. Select any item(s) below that should not be issued full credit. Items that are not flagged will be considered verified for full credit.'
              : 'Which item(s) in this order do you want to file a dispute for? Items not flagged will be considered verified for this order.'}
          </TypographyWrapper>
        </Box>
        <ItemizedBundle bundle={bundle} />
      </WizardPage>
      {/* Creates a Wizard Page for each transaction that is being disputed */}
      {renderWizardPages()}
    </DisputeOrVerifyTradeInModalWizard>
  );
};

export default DisputeOrVerifyTradeInModal;
