import { ListingItemType } from '@prisma/client';

const MINIMUM_ALLOWABLE_PRICE = 0;
const MINIMUM_ALLOWABLE_MARKETPLACE_PRICE = 100; // $1.00
export const MAXIMUM_ALLOWABLE_PRICE = 9999999; // $99,999.99

export const getRecommendedPrice = (originalPrice, condition, conditionToRecommendedDiscount) =>
  Math.round(originalPrice * conditionToRecommendedDiscount[condition]);

/**
 * Computes what the highest min price is by using the pricing restrictions and the
 * minimum price set.
 * @param {*} originalPrice
 * @param {*} pricingRestrictions
 * @param {*} minimumPrice
 */
export const calculateMinimumPrice = (
  originalPrice,
  pricingRestrictions,
  tags,
  listingItemType = ''
) => {
  const isMarketPlaceListingItem =
    listingItemType && listingItemType === ListingItemType.MARKETPLACE;

  const minimumValues = isMarketPlaceListingItem
    ? [MINIMUM_ALLOWABLE_MARKETPLACE_PRICE] // prevent marketplace items from being listed for $0.00
    : [MINIMUM_ALLOWABLE_PRICE];

  if (!pricingRestrictions) return minimumValues[0];

  // eslint-disable-next-line no-restricted-syntax
  for (const pricingRestriction of pricingRestrictions) {
    const { type, tag, minimumPrice, minimumPercentage } = pricingRestriction;

    // First check if this pricing restriction is relevant.
    if (type === 'tag' && !tags?.includes(tag)) {
      // eslint-disable-next-line no-continue
      continue;
    }

    // The pricing restriction config should only include either one of minimumPercentage
    // or minimumPrice. Check for null because it could be 0.
    if (minimumPrice != null) {
      minimumValues.push(minimumPrice);
    } else if (minimumPercentage != null && originalPrice) {
      minimumValues.push(Number(originalPrice) * 100 * minimumPercentage);
    }
  }

  return Math.max(...minimumValues);
};

/**
 * Computes what the lowest max price is by using the pricing restrictions and the
 * minimum price set.
 * @param {*} originalPrice
 * @param {*} pricingRestrictions
 * @param {*} maximumPrice
 */
export const calculateMaximumPrice = (originalPrice, pricingRestrictions, tags) => {
  const maximumValues = [MAXIMUM_ALLOWABLE_PRICE];

  if (!pricingRestrictions) return MAXIMUM_ALLOWABLE_PRICE;

  // eslint-disable-next-line no-restricted-syntax
  for (const pricingRestriction of pricingRestrictions) {
    const { type, tag, maximumPrice, maximumPercentage } = pricingRestriction;

    // First check if this pricing restriction is relevant.
    if (type === 'tag' && !tags?.includes(tag)) {
      // eslint-disable-next-line no-continue
      continue;
    }

    // The pricing restriction config should only include either one of maximumPercentage
    // or maximumPrice. Check for null because it could be 0.
    if (maximumPrice != null) {
      maximumValues.push(maximumPrice);
    } else if (maximumPercentage != null && originalPrice) {
      maximumValues.push(Number(originalPrice) * 100 * maximumPercentage);
    }
  }

  return Math.min(...maximumValues);
};
