import omit from 'lodash/omit';
import { validate as isValidUUID } from 'uuid';
import { isEmpty } from 'lodash';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchStripeAccount } from '../../ducks/stripeConnectAccount.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { denormalisedEntities, updatedEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import * as log from '../../util/log';
import { parse } from '../../util/urlHelpers';
import { handle } from '../../util/helpers';
import { fetchOwnListings as fetchOwnListingsApiRequest, setInventory } from '../../util/api';
import { ITEM_AVAILABILITY_AVAILABLE, ITEM_AVAILABILITY_PURCHASED } from '../../util/constants';
import { LISTING_BARCODE_CHAR_LENGTH, ListingStatusFilters } from './ManageListingsPage.utils';
import { getEnabledCustomerExperiences, getShopConfig } from '../../shopConfig/configHelper';
import {
  LISTING_STATE_CLOSED,
  LISTING_STATE_DRAFT,
  LISTING_STATE_PUBLISHED,
  LISTING_STATES,
} from '../../util/types';
import { RequestStatus } from '../../types/requestStatus';
import { ListingItemType } from '../../types/sharetribe/listing';

// ================ Action types ================ //

export const FETCH_LISTINGS_REQUEST = 'app/ManageListingsPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/ManageListingsPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/ManageListingsPage/FETCH_LISTINGS_ERROR';

export const FETCH_OWN_LISTINGS_REQUEST = 'app/ManageListingsPage/FETCH_OWN_LISTINGS_REQUEST';
export const FETCH_OWN_LISTINGS_SUCCESS = 'app/ManageListingsPage/FETCH_OWN_LISTINGS_SUCCESS';
export const FETCH_OWN_LISTINGS_ERROR = 'app/ManageListingsPage/FETCH_OWN_LISTINGS_ERROR';

export const FETCH_ORDERS_REQUEST = 'app/ManageListingsPage/FETCH_ORDERS_REQUEST';
export const FETCH_ORDERS_SUCCESS = 'app/ManageListingsPage/FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_ERROR = 'app/ManageListingsPage/FETCH_ORDERS_ERROR';

export const OPEN_LISTING_REQUEST = 'app/ManageListingsPage/OPEN_LISTING_REQUEST';
export const OPEN_LISTING_SUCCESS = 'app/ManageListingsPage/OPEN_LISTING_SUCCESS';
export const OPEN_LISTING_ERROR = 'app/ManageListingsPage/OPEN_LISTING_ERROR';

export const CLOSE_LISTING_REQUEST = 'app/ManageListingsPage/CLOSE_LISTING_REQUEST';
export const CLOSE_LISTING_SUCCESS = 'app/ManageListingsPage/CLOSE_LISTING_SUCCESS';
export const CLOSE_LISTING_ERROR = 'app/ManageListingsPage/CLOSE_LISTING_ERROR';

export const RELIST_LISTING_REQUEST = 'app/ManageListingsPage/RELIST_LISTING_REQUEST';
export const RELIST_LISTING_SUCCESS = 'app/ManageListingsPage/RELIST_LISTING_SUCCESS';
export const RELIST_LISTING_ERROR = 'app/ManageListingsPage/RELIST_LISTING_ERROR';

export const DELETE_DRAFT_REQUEST = 'app/ManageListingsPage/DELETE_DRAFT_REQUEST';
export const DELETE_DRAFT_SUCCESS = 'app/ManageListingsPage/DELETE_DRAFT_SUCCESS';
export const DELETE_DRAFT_ERROR = 'app/ManageListingsPage/DELETE_DRAFT_ERROR';

export const UPDATE_QUANTITY_REQUEST = 'app/ManageListingsPage/UPDATE_QUANTITY_REQUEST';
export const UPDATE_QUANTITY_SUCCESS = 'app/ManageListingsPage/UPDATE_QUANTITY_SUCCESS';
export const UPDATE_QUANTITY_ERROR = 'app/ManageListingsPage/UPDATE_QUANTITY_ERROR';

export const DELETE_LISTING_REQUEST = 'app/ManageListingsPage/DELETE_LISTING_REQUEST';
export const DELETE_LISTING_SUCCESS = 'app/ManageListingsPage/DELETE_LISTING_SUCCESS';
export const DELETE_LISTING_ERROR = 'app/ManageListingsPage/DELETE_LISTING_ERROR';

export const ADD_OWN_ENTITIES = 'app/ManageListingsPage/ADD_OWN_ENTITIES';

export const CREATE_DUPLICATE_LISTING_REQUEST =
  'app/ManageListingsPage/CREATE_DUPLICATE_LISTING_REQUEST';
export const CREATE_DUPLICATE_LISTING_SUCCESS =
  'app/ManageListingsPage/CREATE_DUPLICATE_LISTING_SUCCESS';
export const CREATE_DUPLICATE_LISTING_ERROR =
  'app/ManageListingsPage/CREATE_DUPLICATE_LISTING_ERROR';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  queryParams: null,
  fetchListingsStatus: RequestStatus.Ready,
  fetchListingsError: null,
  currentPageResultIds: [],
  ownEntities: {},
  openingListing: null,
  openingListingError: null,
  closingListing: null,
  closingListingError: null,
  deleteDraftId: null,
  deleteDraftError: null,
  deleteListingId: null,
  deleteListingError: null,
  relistListingId: null,
  relistListingError: null,
  updateQuantityId: null,
  updateQuantityError: null,
  listingStatusFilter: null,
  entities: {},
  relistAsDuplicateListingId: null,
  relistAsDuplicateListingError: null,
};

const resultIds = (data) => data.data.map((l) => l.id);

const merge = (state, sdkResponse) => {
  const apiResponse = sdkResponse.data;
  return {
    ...state,
    ownEntities: updatedEntities({ ...state.ownEntities }, apiResponse),
  };
};

const updateListingAttributes = (state, listingEntity) => {
  const oldListing = state.ownEntities.listing[listingEntity.id.uuid];
  const updatedListing = { ...oldListing, attributes: listingEntity.attributes };
  const listingEntities = {
    ...state.ownEntities.listing,
    [listingEntity.id.uuid]: updatedListing,
  };
  return {
    ...state,
    ownEntities: { ...state.ownEntities, listing: listingEntities },
  };
};

const manageListingsPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        queryParams: payload.queryParams,
        fetchListingsStatus: RequestStatus.Pending,
        queryListingsError: null,
        currentPageResultIds: [],
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        fetchListingsStatus: RequestStatus.Success,
        listingStatusFilter: payload.filter,
      };
    case FETCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, fetchListingsStatus: RequestStatus.Error, queryListingsError: payload };

    case OPEN_LISTING_REQUEST:
      return {
        ...state,
        openingListing: payload.listingId,
        openingListingError: null,
      };
    case OPEN_LISTING_SUCCESS:
      return {
        ...updateListingAttributes(state, payload.data),
        openingListing: null,
      };
    case OPEN_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        openingListing: null,
        openingListingError: {
          listingId: state.openingListing,
          error: payload,
        },
      };
    }

    case CLOSE_LISTING_REQUEST:
      return {
        ...state,
        closingListing: payload.listingId,
        closingListingError: null,
      };
    case CLOSE_LISTING_SUCCESS:
      return {
        ...updateListingAttributes(state, payload.data),
        closingListing: null,
      };
    case CLOSE_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        closingListing: null,
        closingListingError: {
          listingId: state.closingListing,
          error: payload,
        },
      };
    }

    case DELETE_DRAFT_REQUEST:
      return {
        ...state,
        deleteDraftId: payload.listingId,
        deleteDraftError: null,
      };
    case DELETE_DRAFT_SUCCESS: {
      const listingEntities = omit(state.ownEntities.listing, [payload.listingId.uuid]);
      return {
        ...state,
        deleteDraftId: null,
        ownEntities: {
          ...state.ownEntities,
          listing: listingEntities,
        },
        pagination: {
          ...state.pagination,
          totalItems: state.pagination.totalItems - 1,
        },
      };
    }
    case DELETE_DRAFT_ERROR:
      return {
        ...state,
        deleteDraftId: null,
        deleteDraftError: payload,
      };
    case RELIST_LISTING_REQUEST:
      return {
        ...state,
        relistListingId: payload.listingId,
        relistListingError: null,
      };
    case RELIST_LISTING_SUCCESS:
      return {
        ...state,
        relistListingId: null,
      };
    case RELIST_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        relistListingId: null,
        relistListingError: {
          listingId: state.relistListingId,
          error: payload,
        },
      };
    }
    case UPDATE_QUANTITY_REQUEST:
      return {
        ...state,
        updateQuantityId: payload.listingId,
        updateQuantityError: null,
      };
    case UPDATE_QUANTITY_SUCCESS:
      return {
        ...state,
        updateQuantityId: null,
      };
    case UPDATE_QUANTITY_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        updateQuantityId: null,
        updateQuantityError: {
          listingId: state.updateQuantityId,
          error: payload,
        },
      };
    }
    case DELETE_LISTING_REQUEST:
      return {
        ...state,
        deleteListingId: payload.listingId,
        deleteListingError: null,
      };
    case DELETE_LISTING_SUCCESS: {
      const listingEntities = omit(state.ownEntities.listing, [payload.listingId.uuid]);
      return {
        ...state,
        deleteListingId: null,
        ownEntities: {
          ...state.ownEntities,
          listing: listingEntities,
        },
        pagination: {
          ...state.pagination,
          totalItems: state.pagination.totalItems - 1,
        },
      };
    }

    case DELETE_LISTING_ERROR:
      return {
        ...state,
        deleteListingId: null,
        deleteListingError: payload,
      };

    case ADD_OWN_ENTITIES:
      return merge(state, payload);

    case CREATE_DUPLICATE_LISTING_REQUEST:
      return {
        ...state,
        relistAsDuplicateListingId: payload.listingId,
        relistAsDuplicateListingError: null,
      };
    case CREATE_DUPLICATE_LISTING_SUCCESS:
      return {
        ...state,
        relistAsDuplicateListingId: null,
      };
    case CREATE_DUPLICATE_LISTING_ERROR:
      return {
        ...state,
        relistAsDuplicateListingError: {
          listingId: state.relistAsDuplicateListingId,
          error: payload,
        },
        relistAsDuplicateListingId: null,
      };
    default:
      return state;
  }
};

export default manageListingsPageReducer;

// ================ Selectors ================ //

/**
 * Get the denormalised own listing entities with the given IDs
 *
 * @param {Array<UUID>} listingIds listing IDs to select from the store
 * @param {Object} ownEntities Sharetribe entities from the Redux store
 */
export const getOwnListingsById = (listingIds, ownEntities) => {
  const resources = listingIds.map((id) => ({
    id,
    type: 'listing',
  }));
  const throwIfNotFound = false;
  return denormalisedEntities(ownEntities, resources, throwIfNotFound);
};

// ================ Action creators ================ //

// This works the same way as addMarketplaceEntities,
// but we don't want to mix own listings with searched listings
// (own listings data contains different info - e.g. exact location etc.)
export const addOwnEntities = (sdkResponse) => ({
  type: ADD_OWN_ENTITIES,
  payload: sdkResponse,
});

export const openListingRequest = (listingId) => ({
  type: OPEN_LISTING_REQUEST,
  payload: { listingId },
});

export const openListingSuccess = (response) => ({
  type: OPEN_LISTING_SUCCESS,
  payload: response.data,
});

export const openListingError = (e) => ({
  type: OPEN_LISTING_ERROR,
  error: true,
  payload: e,
});

export const closeListingRequest = (listingId) => ({
  type: CLOSE_LISTING_REQUEST,
  payload: { listingId },
});

export const closeListingSuccess = (response) => ({
  type: CLOSE_LISTING_SUCCESS,
  payload: response.data,
});

export const closeListingError = (e) => ({
  type: CLOSE_LISTING_ERROR,
  error: true,
  payload: e,
});

export const relistListingRequest = (listingId) => ({
  type: RELIST_LISTING_REQUEST,
  payload: { listingId },
});

export const relistListingSuccess = (response) => ({
  type: RELIST_LISTING_SUCCESS,
  payload: response.data,
});

export const relistListingError = (e) => ({
  type: RELIST_LISTING_ERROR,
  error: true,
  payload: e,
});

export const deleteDraftRequest = (listingId) => ({
  type: DELETE_DRAFT_REQUEST,
  payload: { listingId },
});

export const deleteDraftSuccess = (listingId) => ({
  type: DELETE_DRAFT_SUCCESS,
  payload: { listingId },
});

export const deleteDraftError = (e) => ({
  type: DELETE_DRAFT_ERROR,
  error: true,
  payload: e,
});

export const queryListingsRequest = (queryParams) => ({
  type: FETCH_LISTINGS_REQUEST,
  payload: { queryParams },
});

export const queryListingsSuccess = (response, filter = null) => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: { data: response.data, filter },
});

export const queryListingsError = (e) => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const updateQuantityRequest = (listingId) => ({
  type: UPDATE_QUANTITY_REQUEST,
  payload: { listingId },
});

export const updateQuantitySuccess = (response) => ({
  type: UPDATE_QUANTITY_SUCCESS,
  payload: response.data,
});

export const updateQuantityError = (e) => ({
  type: UPDATE_QUANTITY_ERROR,
  error: true,
  payload: e,
});

export const deleteListingRequest = (listingId) => ({
  type: DELETE_LISTING_REQUEST,
  payload: { listingId },
});

export const deleteListingSuccess = (listingId) => ({
  type: DELETE_LISTING_SUCCESS,
  payload: { listingId },
});

export const deleteListingError = (e) => ({
  type: DELETE_LISTING_ERROR,
  error: true,
  payload: e,
});

export const createDuplicateListingRequest = (listingId) => ({
  type: CREATE_DUPLICATE_LISTING_REQUEST,
  payload: { listingId },
});

export const createDuplicateListingSuccess = () => ({
  type: CREATE_DUPLICATE_LISTING_SUCCESS,
});

export const createrelistAsDuplicateListingError = (e) => ({
  type: CREATE_DUPLICATE_LISTING_ERROR,
  payload: e,
});
// ================ Thunks ================ //

const listingParams = { include: ['images', 'privateData', 'currentStock'] };

// Calls backend API which allows for refining the ST own listings query by public data fields via
// the integration API. Otherwise Sharetribe's marketplace ownListings API does not allow for public
// data filtering.
export const queryOwnListingsFromApi =
  (queryParams, listingStatusFilter = null) =>
  async (dispatch) => {
    dispatch(queryListingsRequest(queryParams));

    const { perPage, ...rest } = queryParams;
    const params = {
      ...rest,
      per_page: perPage,
    };

    try {
      const response = await fetchOwnListingsApiRequest({ params });
      dispatch(addOwnEntities(response));
      dispatch(queryListingsSuccess(response, listingStatusFilter));
      return response;
    } catch (e) {
      dispatch(queryListingsError(e));
      log.error(e, 'fetching-own-listings-api-failed', { queryParams });
      return null;
    }
  };

export const closeListing = (listingId) => (dispatch, getState, sdk) => {
  dispatch(closeListingRequest(listingId));

  return sdk.ownListings
    .close({ id: listingId }, { expand: true })
    .then((response) => {
      dispatch(closeListingSuccess(response));
      return response;
    })
    .catch((e) => {
      dispatch(closeListingError(storableError(e)));
    });
};

export const deleteDraft = (listingId) => (dispatch, _, sdk) => {
  dispatch(deleteDraftRequest(listingId));

  return sdk.ownListings
    .discardDraft({ id: listingId }, { expand: true })
    .then((response) => {
      dispatch(deleteDraftSuccess(listingId));
      return response;
    })
    .catch((e) => {
      dispatch(deleteDraftError(storableError(e)));
      throw e;
    });
};

export const openListing = (listingId) => (dispatch, getState, sdk) => {
  dispatch(openListingRequest(listingId));

  return sdk.ownListings
    .open({ id: listingId }, { expand: true })
    .then((response) => {
      dispatch(openListingSuccess(response));
      return response;
    })
    .catch((e) => {
      dispatch(openListingError(storableError(e)));
    });
};

export const relistListing = (listingId) => async (dispatch, getState, sdk) => {
  dispatch(relistListingRequest(listingId));

  const [reopenListingResponse, reopenListingError] = await handle(
    sdk.ownListings.open({ id: listingId }, { expand: true })
  );
  if (reopenListingError) {
    log.error(reopenListingError, 'relist-open-error', { listingId });
    dispatch(relistListingError(storableError(reopenListingError)));
    return null;
  }
  const [, updateListingError] = await handle(
    sdk.ownListings.update({
      id: listingId,
      publicData: { availability: ITEM_AVAILABILITY_AVAILABLE },
    })
  );
  if (updateListingError) {
    log.error(updateListingError, 'relist-update-error', { listingId });
    sdk.ownListings.close({ id: listingId }, { expand: true });
    dispatch(relistListingError(storableError(reopenListingError)));
    return null;
  }
  const [, updateStockError] = await handle(
    setInventory({ listingId: listingId.uuid, oldTotal: 0, newTotal: 1, forceUpdate: true })
  );
  if (updateStockError) {
    sdk.ownListings.close({ id: listingId }, { expand: true });
    sdk.ownListings.update({
      id: listingId,
      publicData: { availability: ITEM_AVAILABILITY_PURCHASED },
    });
    dispatch(relistListingError(storableError(reopenListingError)));
    return null;
  }

  dispatch(relistListingSuccess(reopenListingResponse));

  return reopenListingResponse;
};

export const updateListingQuantity =
  (listingId, oldQuantity, newQuantity) => async (dispatch, getState, sdk) => {
    dispatch(updateQuantityRequest(listingId));

    const [updateStockResponse, updateStockError] = await handle(
      setInventory({ listingId, oldTotal: oldQuantity, newTotal: newQuantity })
    );
    if (updateStockError) {
      dispatch(updateQuantityError(storableError(updateStockError)));
      return null;
    }

    addMarketplaceEntities(updateStockResponse);

    // Refetch listing so we can update the listing in the store
    const [refetchedListingResponse, refetchedListingError] = await handle(
      sdk.ownListings.show({ id: listingId, ...listingParams }, { expand: true })
    );

    if (refetchedListingError) {
      log.error(refetchedListingError, 'show-own-listing-after-stock-adjustment-error', {
        listingId,
        oldQuantity,
        newQuantity,
        refetchedListingError,
      });
      dispatch(updateQuantityError(storableError(refetchedListingError)));
      return null;
    }

    dispatch(addMarketplaceEntities(refetchedListingResponse));
    dispatch(addOwnEntities(refetchedListingResponse));
    dispatch(updateQuantitySuccess(refetchedListingResponse));

    return refetchedListingResponse;
  };

export const createDuplicateListingForRelist =
  (params, listingId) => async (dispatch, getState, sdk) => {
    dispatch(createDuplicateListingRequest(listingId));

    const queryParams = {
      expand: true,
      include: ['author', 'images'],
      'fields.image': ['variants.default'],
    };

    try {
      const listingDraftResponse = await sdk.ownListings.createDraft(params, queryParams);
      const listingDraftId = listingDraftResponse?.data?.data?.id?.uuid;

      if (isEmpty(listingDraftResponse) || !listingDraftId) {
        throw new Error('Error creating duplicate listing from relist.');
      }

      dispatch(updateListingQuantity(listingDraftId, null, 1));
      dispatch(createDuplicateListingSuccess());
      return listingDraftId;
    } catch (e) {
      log.error(e, 'create-duplicate-listing-from-relist-failed', params);
      dispatch(createrelistAsDuplicateListingError(storableError(e)));
      return null;
    }
  };

export const deleteListing = (listingId, title, isPublished) => async (dispatch, _, sdk) => {
  dispatch(deleteListingRequest(listingId));

  try {
    if (isPublished) {
      // Cannot close pending approval or already closed listings. Will just update the public data.
      await sdk.ownListings.close({ id: listingId }, { expand: true });
    }
    await sdk.ownListings.update({
      id: listingId,
      title: `[Deleted] ${title}`,
      publicData: {
        deleted: true,
      },
    });
    dispatch(deleteListingSuccess(listingId));
  } catch (e) {
    console.log(e);
    log.error(e, 'delete-listings-failed', { listingId, e });
    dispatch(deleteListingError(storableError(e)));
    throw e;
  }
};

const getPublicDataSearchField = (targetId) => {
  if (targetId.includes('gid://shopify/ProductVariant/')) {
    return 'pub_shopifyProductVariantId';
  }
  if (targetId.length === LISTING_BARCODE_CHAR_LENGTH) {
    return 'pub_barcode';
  }
  if (isValidUUID(targetId)) {
    return 'ids';
  }
  return null;
};

export const loadData = (params, search) => async (dispatch, getState) => {
  const { uploadId } = params;
  const { treetId, shopConfig: shopConfigV2 } = getState().initial;
  const { enabledCustomerExperiences } = getShopConfig(treetId, shopConfigV2);
  const { allowTradeInAndMarketplace } = getEnabledCustomerExperiences(enabledCustomerExperiences);

  const queryParams = parse(search);
  const page = queryParams.page || 1;
  const listingStatusFilter = queryParams.listingStatusFilter || '';
  const { state, ids, ...restQueryParams } = queryParams;
  const isDraftsPage = queryParams.state === LISTING_STATE_DRAFT;
  const dispatchArray = [];

  let { currentUser } = getState().user;
  if (!currentUser) {
    await dispatch(fetchCurrentUser());
    currentUser = getState().user.currentUser;
  }

  let queryOwnListingsParams = {
    ...restQueryParams,
    ...listingParams,
    page,
    perPage: 100,
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x', 'variants.default'],
    'limit.images': 1,
    ...(!isDraftsPage && { pub_listingItemType: ListingItemType.Marketplace }),
    ...(uploadId && { pub_uploadId: uploadId }),
  };

  if (ids) {
    // 'ids' is the name of the Sharetribe query parameter that can respect a single id
    // or a comma-separated list of ids. In this use case it will contain only a single id.
    const targetId = ids.toString().trim();
    const publicDataSearchField = getPublicDataSearchField(targetId);
    queryOwnListingsParams = {
      ...queryOwnListingsParams,
      // If it's not a valid search input, return no results
      ...(publicDataSearchField ? { [publicDataSearchField]: targetId } : { ids: '' }),
    };
  }

  if (listingStatusFilter === ListingStatusFilters.SOLD) {
    dispatchArray.push(
      dispatch(
        queryOwnListingsFromApi(
          {
            ...queryOwnListingsParams,
            pub_availability: ITEM_AVAILABILITY_PURCHASED,
            states: LISTING_STATE_CLOSED,
          },
          listingStatusFilter
        )
      )
    );
  } else if (listingStatusFilter === ListingStatusFilters.UNSOLD) {
    dispatchArray.push(
      dispatch(
        queryOwnListingsFromApi(
          {
            ...queryOwnListingsParams,
            pub_availability: ITEM_AVAILABILITY_AVAILABLE,
            states: LISTING_STATE_PUBLISHED,
          },
          listingStatusFilter
        )
      )
    );
  } else {
    dispatchArray.push(
      dispatch(
        queryOwnListingsFromApi({
          ...queryOwnListingsParams,
          // Combined sites show drafts separately
          ...(allowTradeInAndMarketplace && {
            states: isDraftsPage
              ? LISTING_STATE_DRAFT
              : LISTING_STATES.filter((listingState) => listingState !== LISTING_STATE_DRAFT),
          }),
        })
      )
    );
  }

  try {
    Promise.all(dispatchArray);
    if (
      currentUser?.stripeAccount ||
      currentUser?.attributes.profile.protectedData?.stripeAccountId
    ) {
      dispatch(fetchStripeAccount());
    }
  } catch (e) {
    log.error(e, 'fetch-listings-failed');
    dispatch(queryListingsError(storableError(e)));
  }
};
