import { CountryCode } from '../types/apollo/generated/types.generated';

export interface CountryCodeAndName {
  code: CountryCode;
  name: string;
  longformName: string;
  locale: string;
}

const US_COUNTRY_CODE = {
  code: CountryCode.Us,
  name: 'United States',
  longformName: 'the United States',
  locale: 'en-US',
};

export const COUNTRY_CODE_AND_NAME: CountryCodeAndName[] = [
  { code: CountryCode.Au, name: 'Australia', longformName: 'Australia', locale: 'en-AU' },
  { code: CountryCode.Ca, name: 'Canada', longformName: 'Canada', locale: 'en-CA' },
  {
    code: CountryCode.Gb,
    name: 'United Kingdom',
    longformName: 'the United Kingdom',
    locale: 'en-GB',
  },
  ...[US_COUNTRY_CODE],
];

// Defaults to US if country code cannot be found.
export const getCountryObjectFromCode = (countryCode: CountryCode): CountryCodeAndName =>
  COUNTRY_CODE_AND_NAME.find((country) => country.code === countryCode) || US_COUNTRY_CODE;

// Defaults to US (en) if locale cannot be found.
export const getCountryObjectFromLocale = (locale: string): CountryCodeAndName =>
  COUNTRY_CODE_AND_NAME.find((country) => country.locale.toLowerCase() === locale.toLowerCase()) ||
  US_COUNTRY_CODE;
