/* eslint-disable import/no-cycle */
import React from 'react';
import loadable from '@loadable/component';
import getPageDataLoadingAPI from './containers/pageDataLoadingAPI';
import { NotFoundPage } from './containers';
// routeConfiguration needs to initialize containers first
// Otherwise, components will import form container eventually and
// at that point css bundling / imports will happen in wrong order.
import { NamedRedirect } from './components';
import { PageName } from './types/routes';
import { ListingPageParamType } from './util/urlHelpers';
import {
  DRAFT_ID,
  DRAFT_SLUG,
  ListingPageParamTab,
} from './containers/EditListingPage/EditListingPage.utils';
import { doesRouteHaveViews } from './util/routes';
import { getSubdomain } from './util/envHelpers';
import ManageClosetPage from './containers/ManageClosetPage/ManageClosetPage';
import { Feature, isFeatureEnabled } from './util/featureFlags';

const pageDataLoadingAPI = getPageDataLoadingAPI();

export const PAGE_TO_PATH = {
  ManagePurchasesPage: '/purchases',
  ManageSalesPage: '/sales',
  ManageListingsPage: '/listings',
  ManageClosetPage: '/closet',
  ManageTradeInsPage: '/trade-ins',
  ProfileSettingsPage: '/profile-settings',
  AccountSettingsPage: '/account',
  NotificationSettingsPage: '/notification-settings',
  AboutBasePage: '/about',
  AboutTreetPage: '/about-treet',
};

export const ACCOUNT_SETTINGS_PAGES = [
  'ContactDetailsPage',
  'PasswordChangePage',
  'StripePayoutPage',
  'PaymentMethodsPage',
];

const AboutPage = loadable(() =>
  import(/* webpackChunkName: "AboutPage" */ './containers/AboutPage/AboutPage')
);
const AboutTreetPage = loadable(() =>
  import(/* webpackChunkName: "AboutTreetPage" */ './containers/AboutTreetPage/AboutTreetPage')
);
const AdminPage = loadable(() =>
  import(/* webpackChunkName: "AdminPage" */ './containers/AdminPage/AdminPage')
);
const AuthenticationRedirectPage = loadable(() =>
  import(
    /* webpackChunkName: "AuthenticationRedirectPage" */ './containers/AuthenticationRedirectPage/AuthenticationRedirectPage'
  )
);
const AuthenticationPage = loadable(() =>
  import(
    /* webpackChunkName: "AuthenticationPage" */ './containers/AuthenticationPage/AuthenticationPage'
  )
);
const CheckoutPage = loadable(() =>
  import(/* webpackChunkName: "CheckoutPage" */ './containers/CheckoutPage/CheckoutPage')
);
const ContactDetailsPage = loadable(() =>
  import(
    /* webpackChunkName: "ContactDetailsPage" */ './containers/ContactDetailsPage/ContactDetailsPage'
  )
);
const CookiePolicyPage = loadable(() =>
  import(
    /* webpackChunkName: "CookiePolicyPage" */ './containers/CookiePolicyPage/CookiePolicyPage'
  )
);
const EditListingPage = loadable(() =>
  import(/* webpackChunkName: "EditListingPage" */ './containers/EditListingPage/EditListingPage')
);
const EmailVerificationPage = loadable(() =>
  import(
    /* webpackChunkName: "EmailVerificationPage" */ './containers/EmailVerificationPage/EmailVerificationPage'
  )
);
const FAQPage = loadable(() =>
  import(/* webpackChunkName: "FAQPage" */ './containers/FAQPage/FAQPage')
);
const FavoritesPage = loadable(() =>
  import(/* webpackChunkName: "FavoritesPage" */ './containers/FavoritesPage/FavoritesPage')
);
const NotificationSettingsPage = loadable(() =>
  import(
    /* webpackChunkName: "NotificationSettingsPage" */ './containers/NotificationSettingsPage/NotificationSettingsPage'
  )
);
const GenerateShippingLabelPage = loadable(() =>
  import(
    /* webpackChunkName: "GenerateShippingLabelPage" */ './containers/GenerateShippingLabelPage/GenerateShippingLabelPage'
  )
);
const HomePage = loadable(() =>
  import(/* webpackChunkName: "HomePage" */ './containers/HomePage/HomePage')
);
const ListingPage = loadable(() =>
  import(
    /* webpackChunkName: "ListingPage" */ /* webpackPrefetch: true */ './containers/ListingPage/ListingPage'
  )
);
const ManageListingsPage = loadable(() =>
  import(
    /* webpackChunkName: "ManageListingsPage" */ './containers/ManageListingsPage/ManageListingsPage'
  )
);
const ManagePurchasesPage = loadable(() =>
  import(
    /* webpackChunkName: "ManagePurchasesPage" */ './containers/ManagePurchasesPage/ManagePurchasesPage'
  )
);
const ManageSalesPage = loadable(() =>
  import(/* webpackChunkName: "ManageSalesPage" */ './containers/ManageSalesPage/ManageSalesPage')
);
const ManageTradeInsPage = loadable(() =>
  import(
    /* webpackChunkName: "ManageTradeInsPage" */ './containers/ManageTradeInsPage/ManageTradeInsPage'
  )
);
const PasswordChangePage = loadable(() =>
  import(
    /* webpackChunkName: "PasswordChangePage" */ './containers/PasswordChangePage/PasswordChangePage'
  )
);
const PasswordRecoveryPage = loadable(() =>
  import(
    /* webpackChunkName: "PasswordRecoveryPage" */ './containers/PasswordRecoveryPage/PasswordRecoveryPage'
  )
);
const PasswordResetPage = loadable(() =>
  import(
    /* webpackChunkName: "PasswordResetPage" */ './containers/PasswordResetPage/PasswordResetPage'
  )
);
const PaymentMethodsPage = loadable(() =>
  import(
    /* webpackChunkName: "PaymentMethodsPage" */ './containers/PaymentMethodsPage/PaymentMethodsPage'
  )
);
const PrivacyPolicyPage = loadable(() =>
  import(
    /* webpackChunkName: "PrivacyPolicyPage" */ './containers/PrivacyPolicyPage/PrivacyPolicyPage'
  )
);
const ProfilePage = loadable(() =>
  import(/* webpackChunkName: "ProfilePage" */ './containers/ProfilePage/ProfilePage')
);
const ProfileSettingsPage = loadable(() =>
  import(
    /* webpackChunkName: "ProfileSettingsPage" */ './containers/ProfileSettingsPage/ProfileSettingsPage'
  )
);
const OrderSuccessPage = loadable(() =>
  import(
    /* webpackChunkName: "OrderSuccessPage" */ './containers/OrderSuccessPage/OrderSuccessPage'
  )
);

const StripePayoutPage = loadable(() =>
  import(
    /* webpackChunkName: "StripePayoutPage" */ './containers/StripePayoutPage/StripePayoutPage'
  )
);
const TermsOfServicePage = loadable(() =>
  import(
    /* webpackChunkName: "TermsOfServicePage" */ './containers/TermsOfServicePage/TermsOfServicePage'
  )
);
const TreetProtectionPage = loadable(() =>
  import(
    /* webpackChunkName: "TreetProtectionPage" */ './containers/TreetProtectionPage/TreetProtectionPage'
  )
);
const TreetShopLandingPage = loadable(() =>
  import(
    /* webpackChunkName: "TreetShopLandingPage" */ './containers/TreetShopLandingPage/TreetShopLandingPage'
  )
);

const RedirectToLandingPage = () => <NamedRedirect name="LandingPage" />;
// NOTE: Most server-side endpoints are prefixed with /api. Requests to those
// endpoints are indended to be handled in the server instead of the browser and
// they will not render the application. So remember to avoid routes starting
// with /api and if you encounter clashing routes see server/index.js if there's
// a conflicting route defined there.

// Our routes are exact by default.
// See behaviour from Routes.js where Route is created.
const routeConfiguration = (treetId) => {
  const isTreetShop = treetId === 'treet';
  const subdomain = getSubdomain();
  const hasViews = doesRouteHaveViews(subdomain);
  const isGuestListingEnabled = isFeatureEnabled(Feature.GuestListing, subdomain);

  if (!hasViews) {
    return [
      {
        path: '/notfound',
        name: 'NotFoundPage',
        component: NotFoundPage,
      },
    ];
  }
  // TODO (sonia-y | TREET-1511): Clean up unused routes after TreetV2 is launched
  const treetShopRoutes = [
    {
      path: '/',
      name: 'TreetShopLandingPage',
      component: TreetShopLandingPage,
      loadData: pageDataLoadingAPI.TreetShopLandingPage.loadData,
    },
    {
      path: '/*',
      name: 'TreetShopLandingPagePath',
      component: () => <NamedRedirect name="TreetShopLandingPage" />,
      exact: false,
    },
  ];

  const treetAppRoutes = [
    {
      path: '/',
      name: 'SearchPage',
      component: HomePage,
      loadData: pageDataLoadingAPI.LandingPageV2.loadData,
    },
    {
      path: '/',
      name: 'LandingPage',
      component: HomePage,
      loadData: pageDataLoadingAPI.LandingPageV2.loadData,
    },
    {
      path: '/s',
      name: 'DeprecatedSearchPage',
      component: HomePage,
      loadData: pageDataLoadingAPI.LandingPageV2.loadData,
    },
    {
      path: PAGE_TO_PATH.AboutTreetPage,
      name: 'AboutTreetPage',
      component: AboutTreetPage,
    },
    {
      path: '/protections',
      name: 'TreetProtectionPage',
      component: TreetProtectionPage,
    },
    {
      path: PAGE_TO_PATH.AboutBasePage,
      name: 'AboutBasePage',
      component: () => <NamedRedirect name="AboutPage" params={{ tab: 'info' }} />,
    },
    {
      path: `${PAGE_TO_PATH.AboutBasePage}/:tab`,
      name: 'AboutPage',
      component: AboutPage,
    },
    {
      path: '/admin',
      name: 'AdminBasePage',
      component: () => <NamedRedirect name="AdminPage" params={{ tab: 'dashboard' }} />,
    },
    {
      path: '/admin/:tab',
      name: 'AdminPage',
      component: AdminPage,
    },
    {
      path: '/admin/:tab/:id',
      name: 'AdminPageVariant',
      component: AdminPage,
    },
    {
      path: '/l',
      name: 'ListingBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/l/:slug/:id',
      name: 'ListingPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/checkout',
      name: PageName.CheckoutPage,
      auth: true,
      component: CheckoutPage,
      setInitialValues: pageDataLoadingAPI.CheckoutPage.setInitialValues,
    },
    {
      path: '/l/:slug/:id/:variant',
      name: 'ListingPageVariant',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/l/new',
      name: 'NewListingPage',
      auth: !isGuestListingEnabled,
      component: () => (
        <NamedRedirect
          name="EditListingPage"
          params={{
            slug: DRAFT_SLUG,
            id: DRAFT_ID,
            type: ListingPageParamType.New,
            tab: ListingPageParamTab.Search,
          }}
        />
      ),
    },
    {
      path: '/l/:slug/:id/:type/:tab',
      name: 'EditListingPage',
      auth: !isGuestListingEnabled,
      component: EditListingPage,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },

    // Canonical path should be after the `/l/new` path since they
    // conflict and `new` is not a valid listing UUID.
    {
      path: '/l/:id',
      name: 'ListingPageCanonical',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/u',
      name: 'ProfileBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/u/:id',
      name: 'ProfilePage',
      component: ProfilePage,
      loadData: pageDataLoadingAPI.ProfilePage.loadData,
    },
    {
      path: PAGE_TO_PATH.ProfileSettingsPage,
      name: 'ProfileSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ProfileSettingsPage,
    },
    {
      path: PAGE_TO_PATH.NotificationSettingsPage,
      name: 'NotificationSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: NotificationSettingsPage,
    },
    // Note: authenticating with IdP (e.g. Google) expects that /login path exists
    // so that in the error case users can be redirected back to the LoginPage
    // In case you change this, remember to update the route in server/api/auth/loginWithIdp.js
    {
      path: '/login',
      name: 'LoginPage',
      component: AuthenticationPage,
      extraProps: { tab: 'login' },
    },
    {
      path: '/signup',
      name: 'SignupPage',
      component: AuthenticationPage,
      extraProps: { tab: 'signup' },
    },
    {
      path: '/recover-password',
      name: 'PasswordRecoveryPage',
      component: PasswordRecoveryPage,
    },
    {
      path: '/order/:id/success',
      name: 'OrderSuccessPage',
      auth: true,
      authPage: 'LoginPage',
      component: OrderSuccessPage,
      setInitialValues: pageDataLoadingAPI.OrderSuccessPage.setInitialValues,
      loadData: pageDataLoadingAPI.OrderSuccessPage.loadData,
    },
    {
      path: '/favorites',
      name: 'FavoritesPage',
      auth: true,
      authPage: 'LoginPage',
      component: FavoritesPage,
      loadData: pageDataLoadingAPI.FavoritesPage.loadData,
    },
    {
      path: '/generate-shipping-label/:id',
      name: 'GenerateShippingLabelPage',
      auth: true,
      authPage: 'LoginPage',
      component: GenerateShippingLabelPage,
    },
    {
      path: PAGE_TO_PATH.ManageListingsPage,
      name: 'ManageListingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ManageListingsPage,
      loadData: pageDataLoadingAPI.ManageListingsPage.loadData,
    },
    {
      path: `${PAGE_TO_PATH.ManageListingsPage}/:uploadId`,
      name: 'ManageListingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ManageListingsPage,
      loadData: pageDataLoadingAPI.ManageListingsPage.loadData,
    },
    {
      path: PAGE_TO_PATH.ManageClosetPage,
      name: 'ManageClosetPage',
      auth: true,
      authPage: 'LoginPage',
      component: ManageClosetPage,
      loadData: pageDataLoadingAPI.ManageClosetPage.loadData,
    },
    {
      path: PAGE_TO_PATH.ManageTradeInsPage,
      name: 'ManageTradeInsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ManageTradeInsPage,
      loadData: pageDataLoadingAPI.ManageTradeInsPage.loadData,
    },
    {
      path: PAGE_TO_PATH.ManagePurchasesPage,
      name: 'ManagePurchasesPage',
      auth: true,
      authPage: 'LoginPage',
      component: ManagePurchasesPage,
      loadData: pageDataLoadingAPI.ManagePurchasesPage.loadData,
    },
    {
      path: `${PAGE_TO_PATH.ManagePurchasesPage}/:id`,
      name: 'ManagePurchasePage',
      auth: true,
      authPage: 'LoginPage',
      component: ManagePurchasesPage,
      loadData: pageDataLoadingAPI.ManagePurchasesPage.loadData,
    },
    {
      path: PAGE_TO_PATH.ManageSalesPage,
      name: 'ManageSalesPage',
      auth: true,
      authPage: 'LoginPage',
      component: ManageSalesPage,
      loadData: pageDataLoadingAPI.ManageSalesPage.loadData,
    },
    {
      path: `${PAGE_TO_PATH.ManageSalesPage}/:id`,
      name: 'ManageSalePage',
      auth: true,
      authPage: 'LoginPage',
      component: ManageSalesPage,
      loadData: pageDataLoadingAPI.ManageSalesPage.loadData,
    },
    {
      path: PAGE_TO_PATH.AccountSettingsPage,
      name: 'AccountSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="ContactDetailsPage" />,
    },
    {
      path: `${PAGE_TO_PATH.AccountSettingsPage}/contact-details`,
      name: 'ContactDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ContactDetailsPage,
      loadData: pageDataLoadingAPI.ContactDetailsPage.loadData,
    },
    {
      path: `${PAGE_TO_PATH.AccountSettingsPage}/change-password`,
      name: 'PasswordChangePage',
      auth: true,
      authPage: 'LoginPage',
      component: PasswordChangePage,
    },
    {
      path: `${PAGE_TO_PATH.AccountSettingsPage}/payments`,
      name: 'StripePayoutPage',
      auth: true,
      authPage: 'LoginPage',
      component: StripePayoutPage,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    },
    {
      path: `${PAGE_TO_PATH.AccountSettingsPage}/payments/:returnURLType`,
      name: 'StripePayoutOnboardingPage',
      auth: true,
      authPage: 'LoginPage',
      component: StripePayoutPage,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    },
    {
      path: `${PAGE_TO_PATH.AccountSettingsPage}/payment-methods`,
      name: 'PaymentMethodsPage',
      auth: true,
      authPage: 'LoginPage',
      component: PaymentMethodsPage,
      loadData: pageDataLoadingAPI.PaymentMethodsPage.loadData,
    },
    {
      path: '/terms-of-service',
      name: 'TermsOfServicePage',
      component: TermsOfServicePage,
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicyPage',
      component: PrivacyPolicyPage,
    },
    {
      path: '/cookie-policy',
      name: 'CookiePolicyPage',
      component: CookiePolicyPage,
    },
    {
      path: '/faq',
      name: 'FAQPage',
      component: FAQPage,
    },
    {
      path: '/notfound',
      name: 'NotFoundPage',
      component: NotFoundPage,
    },
    {
      path: '/redirect',
      name: 'AuthPageRedirect',
      component: AuthenticationRedirectPage,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /reset-password endpoint
    {
      path: '/reset-password',
      name: 'PasswordResetPage',
      component: PasswordResetPage,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /verify-email endpoint
    {
      path: '/verify-email',
      name: 'EmailVerificationPage',
      auth: true,
      authPage: 'LoginPage',
      component: EmailVerificationPage,
      loadData: pageDataLoadingAPI.EmailVerificationPage.loadData,
    },
  ];

  return isTreetShop ? treetShopRoutes : treetAppRoutes;
};

export default routeConfiguration;
