/* eslint-disable import/prefer-default-export */
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { BuilderSections } from '../../util/builder';

const representSizes = ['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL'];

export const representShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: representSizes }),
  ],
  builderConfig: {
    sections: {
      [BuilderSections.LandingPageContent]: '6e46ea194772449cbd3f1da78e6c563c',
      [BuilderSections.AboutPageHeroContent]: '4e927a0e68ed4db9a44e2481fce5cea4',
      [BuilderSections.PDPContent]: '71daf4724c9e4792b6f5abd6f8e031fa',
      [BuilderSections.ListingPageTitleAndVariantDetailsContent]:
        '1e55cf3ca8a94d3da4122f2c0c10cabb',
      [BuilderSections.ListingCardDetails]: 'b7b1c01217214c1386ffc091a90c583f',
      [BuilderSections.FooterContent]: 'c54aa4b1595b48dcb44d053da10fec5b',
      [BuilderSections.TopbarContent]: '2b581895a3ba4af8a77e764697a8538d',
      [BuilderSections.TopbarMobileContent]: '8c51773516f443769917a28c4229e796',
    },
  },
};
