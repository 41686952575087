// Datadog Realtime User Monitoring
import { datadogRum } from '@datadog/browser-rum';

export const setup = () => {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_RUM_APPLICATION_ID as string,
    clientToken: process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN as string,
    site: 'datadoghq.com',
    service: 'treet-web-app',
    env: process.env.REACT_APP_ENV,
    version: process.env.HEROKU_RELEASE_VERSION as string,
    sampleRate: 100,
    replaySampleRate: 0,
    trackInteractions: true,
  });
};
