/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Box, Divider } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useShopConfig } from '../../hooks/shopConfig';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { defaultTreetStyles } from '../../shopConfig/config';
import { TopbarLink } from '../../types/shopConfig/shopConfigV2';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import {
  AvatarLarge,
  Button,
  FormattedMessage,
  IconLinkTo,
  InlineTextButton,
  NamedLink,
  TypographyWrapper,
} from '..';
import { useCurrentUserPermissions } from '../../hooks/useUserPermissions';
import { AboutPageTab } from '../../containers/AboutPage/aboutPageUtils';
import { AdminPageTab } from '../../containers/AdminPage/AdminPage.utils';
import { useActionCopy } from '../../hooks/useActionCopy';
import { useEnabledCustomerExperiences } from '../../hooks/useEnabledCustomerExperiences';
import { useLogout } from '../../hooks/useLogout';
import { usePageTitles } from '../../hooks/usePageTitles';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { Feature } from '../../util/featureFlags';
import { useAllowedFindItemMethods } from '../../hooks/useAllowedFindItemMethods';
import { ModalType, setActiveModal } from '../../ducks/modal.duck';
import { setSavedSearchSource } from '../../ducks/user.duck';
import { EmailSubscribeSource } from '../../types/apollo/generated/types.generated';
import css from './TopbarMobileMenu.module.css';

const AuthenticationSection = () => (
  <>
    <NamedLink name="SignupPage" className={css.navigationLink}>
      <TypographyWrapper variant="h2">Sign Up</TypographyWrapper>
    </NamedLink>
    <NamedLink name="LoginPage" className={css.navigationLink}>
      <TypographyWrapper variant="h2">Log In</TypographyWrapper>
    </NamedLink>
  </>
);

const TopbarMobileMenu = (props) => {
  const { isAuthenticated, currentPage, currentUser } = props;

  const { handleLogout } = useLogout();
  const {
    isAdmin,
    isBrand,
    isAccounting,
    isTradeInAdmin,
    canOwnTradeIns,
    canOwnListingsForSale,
    canViewPage,
  } = useCurrentUserPermissions();

  const isISOEnabled = useFeatureFlags(Feature.InSearchOf);
  const isNotificationSettingsPageEnabled = useFeatureFlags(Feature.FollowingPage);

  const { shopName, mainSite, css: brandCss, treetShopName } = useShopConfig();
  const {
    isBrandDirectOnly,
    allowBuy,
    allowList,
    allowMarketplace,
    isTradeInOnly,
    isListTradeInOnly,
  } = useEnabledCustomerExperiences();
  const { purchasesTitle, salesTitle, tradeInsTitle, listingsTitle, closetTitle } = usePageTitles();
  const { listActionCopy } = useActionCopy();
  const { shouldAllowSearch: hasProductCatalog } = useAllowedFindItemMethods();

  const dispatch = useDispatch();

  const { topbarLayout } = brandCss;

  const user = ensureCurrentUser(currentUser);

  const currentPageClass = (page) => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const sectionDivider = (
    <div style={{ margin: '24px 0', width: '100%' }}>
      <Divider />
    </div>
  );

  const otherSiteConfig = topbarLayout.find(
    (section) => section.type === TopbarLink.OtherSite
  )?.config;
  const topbarSections = {
    [TopbarLink.ShopAll]: (
      <NamedLink name="LandingPage" to={{ state: { scrollOnRender: true } }}>
        <Box display="flex" flexDirection="row" alignItems="center" className={css.navigationLink}>
          <TypographyWrapper component="span" variant="h2">
            Shop All
          </TypographyWrapper>
        </Box>
      </NamedLink>
    ),
    [TopbarLink.MainSite]: (
      <a href={mainSite} key="main-site">
        <Box display="flex" flexDirection="row" alignItems="center" className={css.navigationLink}>
          <TypographyWrapper component="span" variant="h2">
            {shopName} Main Site&nbsp;
          </TypographyWrapper>
          <IconLinkTo color="inherit" />
        </Box>
      </a>
    ),
    [TopbarLink.OtherSite]: otherSiteConfig ? (
      <a href={otherSiteConfig.url} key="main-site">
        <Box display="flex" flexDirection="row" alignItems="center" className={css.navigationLink}>
          <TypographyWrapper component="span" variant="h2">
            {otherSiteConfig.label}&nbsp;
          </TypographyWrapper>
        </Box>
      </a>
    ) : null,
  };

  const renderAdditionalLinks = () => {
    const aboutTreetPageName = !isTradeInOnly ? 'AboutPage' : 'AboutTreetPage';
    const aboutTreetPageParams = !isTradeInOnly ? { params: { tab: AboutPageTab.AboutTreet } } : {};

    return (
      <>
        {sectionDivider}
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('AboutPage', currentPage))}
          params={{ tab: AboutPageTab.Info }}
          name="AboutPage"
        >
          <TypographyWrapper component="span" variant="h2">
            About {treetShopName}
          </TypographyWrapper>
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass(aboutTreetPageName))}
          name={aboutTreetPageName}
          {...aboutTreetPageParams}
        >
          <TypographyWrapper component="span" variant="h2">
            About Treet
          </TypographyWrapper>
        </NamedLink>
      </>
    );
  };

  if (!isAuthenticated) {
    return (
      <div className={css.root}>
        <div className={css.content}>
          {topbarLayout.map((section) => (
            <div key={section.type}>{topbarSections[section.type]}</div>
          ))}
          {renderAdditionalLinks()}
          {sectionDivider}
          <AuthenticationSection />
        </div>
        {!isBrandDirectOnly && (
          <Box className={css.footer} bgcolor={brandCss?.backgroundColor || 'white'}>
            <NamedLink name="NewListingPage" style={{ textDecoration: 'none' }}>
              <Button>{listActionCopy}</Button>
            </NamedLink>
          </Box>
        )}
      </div>
    );
  }

  const displayName = user.attributes.profile.firstName;

  const hasManageSectionLinks =
    allowBuy || isTradeInAdmin || canOwnTradeIns || canOwnListingsForSale;

  return (
    <div className={css.root}>
      <AvatarLarge className={css.avatar} user={currentUser} />
      <div className={css.content}>
        <span className={css.greeting}>
          <TypographyWrapper variant="h2">
            <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
          </TypographyWrapper>
        </span>
        <Box pb={2}>
          <InlineTextButton rootClassName={css.logoutButton} onClick={handleLogout}>
            <TypographyWrapper
              variant="body1"
              component="span"
              typographyOverrides={{ style: { color: defaultTreetStyles.gray40 } }}
            >
              <FormattedMessage id="TopbarMobileMenu.logoutLink" />
            </TypographyWrapper>
          </InlineTextButton>
        </Box>
        {topbarLayout.map((section) => (
          <div key={section.type}>{topbarSections[section.type]}</div>
        ))}
        {sectionDivider}
        {allowBuy && user && (
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('FavoritesPage'))}
            name="FavoritesPage"
          >
            <TypographyWrapper variant="h2">
              <FormattedMessage id="TopbarDesktop.yourFavoritesLink" />
            </TypographyWrapper>
          </NamedLink>
        )}
        {canViewPage.ManagePurchasesPage && (
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('ManagePurchasesPage'))}
            name="ManagePurchasesPage"
          >
            <TypographyWrapper variant="h2">{purchasesTitle}</TypographyWrapper>
          </NamedLink>
        )}
        {canViewPage.ManageSalesPage && (
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('ManageSalesPage'))}
            name="ManageSalesPage"
          >
            <TypographyWrapper variant="h2">{salesTitle}</TypographyWrapper>
          </NamedLink>
        )}
        {canViewPage.ManageClosetPage && (
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('ManageClosetPage'))}
            name="ManageClosetPage"
          >
            <TypographyWrapper variant="h2">{closetTitle}</TypographyWrapper>
          </NamedLink>
        )}
        {canViewPage.ManageListingsPage && (
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <TypographyWrapper variant="h2">{listingsTitle}</TypographyWrapper>
          </NamedLink>
        )}
        {canViewPage.ManageTradeInsPage && (
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('ManageTradeInsPage'))}
            name="ManageTradeInsPage"
          >
            <TypographyWrapper variant="h2">{tradeInsTitle}</TypographyWrapper>
          </NamedLink>
        )}
        {hasManageSectionLinks && sectionDivider}
        {canViewPage.ProfileSettingsPage && (
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <TypographyWrapper variant="h2">
              <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
            </TypographyWrapper>
          </NamedLink>
        )}
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
          name="AccountSettingsPage"
        >
          <TypographyWrapper variant="h2">
            <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
          </TypographyWrapper>
        </NamedLink>
        {isNotificationSettingsPageEnabled && canViewPage.NotificationSettingsPage && (
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('NotificationSettingsPage'))}
            name="NotificationSettingsPage"
          >
            <TypographyWrapper variant="h2">Notification Settings</TypographyWrapper>
          </NamedLink>
        )}
        {isISOEnabled && allowMarketplace && hasProductCatalog && (
          <>
            {sectionDivider}
            <Box className={css.navigationLink}>
              <InlineTextButton
                style={{ textDecoration: 'none' }}
                onClick={() => {
                  dispatch(setActiveModal(ModalType.ISO));
                  dispatch(
                    setSavedSearchSource({ source: EmailSubscribeSource.ProfileDropdownIso })
                  );
                }}
              >
                <TypographyWrapper variant="h2">Submit An Item Request</TypographyWrapper>
              </InlineTextButton>
            </Box>
          </>
        )}
        {(isAdmin || isBrand || isAccounting) && (
          <>
            {sectionDivider}
            <NamedLink
              className={classNames(css.navigationLink, currentPageClass('AdminPage'))}
              name="AdminPage"
              params={{
                tab: isTradeInOnly
                  ? AdminPageTab.TradeInDashboard
                  : AdminPageTab.MarketplaceDashboard,
              }}
            >
              <TypographyWrapper variant="h2">Admin Portal</TypographyWrapper>
            </NamedLink>
          </>
        )}
        {renderAdditionalLinks()}
      </div>
      {allowList && (
        <Box className={css.footer} bgcolor={brandCss?.backgroundColor || 'white'}>
          <NamedLink
            name={isListTradeInOnly ? 'ManageTradeInsPage' : 'NewListingPage'}
            style={{ textDecoration: 'none' }}
          >
            <Button>{listActionCopy}</Button>
          </NamedLink>
        </Box>
      )}
    </div>
  );
};

TopbarMobileMenu.defaultProps = {
  currentUser: null,
  currentPage: null,
};

const { bool, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
};

export default TopbarMobileMenu;
