import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { Field, useForm, useFormState } from 'react-final-form';
import ListingFeedbackHeader from './ListingFeedbackHeader';
import TypographyWrapper, {
  TypographyFormat,
  TypographyWeight,
} from '../TypographyWrapper/TypographyWrapper';
import ListingFeedbackToggleButton from './ListingFeedbackToggleButton';
import css from './ListingFeedbackModal.module.css';
import {
  buttonDetails,
  ListingFeedbackCategory,
  ListingFeedbackFormField,
} from './ListingFeedback.utils';
import { required } from '../../util/validators';
import { InlineTextButton } from '..';

const ListingFeedbackCategoryPane: FC = () => {
  const form = useForm();
  const { values } = useFormState();
  const category = values[ListingFeedbackFormField.Category];

  if (category === ListingFeedbackCategory.Other) {
    form.change(ListingFeedbackFormField.Category);
  }

  const handleChange = (_event: any, selectedCategory: ListingFeedbackCategory) => {
    form.change(ListingFeedbackFormField.Category, selectedCategory);
  };

  const itemDetailsButtons = [
    buttonDetails[ListingFeedbackCategory.Photos],
    buttonDetails[ListingFeedbackCategory.Sizing],
    buttonDetails[ListingFeedbackCategory.Quirks],
    buttonDetails[ListingFeedbackCategory.Material],
    buttonDetails[ListingFeedbackCategory.Pets],
    buttonDetails[ListingFeedbackCategory.Smoking],
  ];

  return (
    <Box display="flex" flexDirection="column">
      <ListingFeedbackHeader />
      <Box display="flex" justifyContent="center" className={css.paneBody}>
        <ToggleButtonGroup
          orientation="vertical"
          value={category}
          exclusive
          onChange={handleChange}
        >
          <Box mt={4} mb={1} ml={2}>
            <TypographyWrapper variant="body1" weight={TypographyWeight.Bold}>
              Item Details
            </TypographyWrapper>
          </Box>
          {itemDetailsButtons.map(({ structuredText, Icon, key }) => (
            <ListingFeedbackToggleButton
              key={key}
              Icon={Icon}
              structuredText={structuredText}
              value={key}
              aria-label={key}
            />
          ))}
          <Box mb={1} ml={2} className={css.categoriesHeader}>
            <TypographyWrapper variant="body1" weight={TypographyWeight.Bold}>
              Pricing
            </TypographyWrapper>
          </Box>
          <ListingFeedbackToggleButton
            Icon={buttonDetails[ListingFeedbackCategory.Price].Icon}
            structuredText={buttonDetails[ListingFeedbackCategory.Price].structuredText}
            value={buttonDetails[ListingFeedbackCategory.Price].key}
            aria-label={buttonDetails[ListingFeedbackCategory.Price].key}
          />
          <Box ml={2} className={css.categoriesHeader}>
            <TypographyWrapper variant="body1">Not seeing what you want to say?</TypographyWrapper>
          </Box>
          <InlineTextButton
            onClick={() => {
              form.change(ListingFeedbackFormField.Category, ListingFeedbackCategory.Other);
              form.submit();
            }}
          >
            <Box mb={4} ml={2} width="fit-content">
              <TypographyWrapper
                variant="body1"
                weight={TypographyWeight.Bold}
                format={TypographyFormat.Underlined}
              >
                Ask another question
              </TypographyWrapper>
            </Box>
          </InlineTextButton>
        </ToggleButtonGroup>
      </Box>
      <Field
        id={ListingFeedbackFormField.Category}
        name={ListingFeedbackFormField.Category}
        input={<input type="hidden" />}
        // this is a hidden field, but the required validator still needs a message
        validate={required('Please select a category.')}
        hidden
        component="input"
      />
    </Box>
  );
};

export default ListingFeedbackCategoryPane;
