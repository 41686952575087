/* eslint-disable import/prefer-default-export */
import { getConditionsFilterConfig } from '../filters/condition';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { BuilderSections } from '../../util/builder';
import { getTagsFilterConfig } from '../filters/styles';
import { FilterId } from '../../types/filters/filters';

const mpgsportcanadaSizes = [
  'XS',
  'S',
  'M',
  'L',
  'XL',
  'XXL',
  '2XL',
  '3XL',
  '1X',
  '2X',
  '3X',
  'One Size',
];

const mpgsportcanadaTags = [
  { key: 'mpgSport', label: 'MPG Sport', tags: ['MPG'] },
  { key: 'modernAmbition', label: 'Modern Ambition', tags: ['Modern Ambition', 'modern-ambition'] },
  { key: 'mondettaOriginals', label: 'Mondetta Originals', tags: ['Originals'] },
  { key: 'mondetta', label: 'Mondetta', tags: ['Mondetta'] },
];

export const mpgsportcanadaShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getBrandDirectFilterConfig(),
    getConditionsFilterConfig(),
    getTagsFilterConfig({
      id: FilterId.Style,
      options: mpgsportcanadaTags,
      shouldUseTreetOptions: true,
    }),
    getSizeFilterConfig({ sizes: mpgsportcanadaSizes }),
  ],
  builderConfig: {
    sections: {
      [BuilderSections.LandingPageContent]: '92c0b2f71e7c45e4b958e520b0489e1b',
    },
  },
};
