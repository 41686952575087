import { ThunkDispatch } from 'redux-thunk';
import axios from 'axios';
import { RequestStatus } from '../../types/requestStatus';
import * as log from '../../util/log';
import { storableError } from '../../util/errors';
import { queueGenerateReportsForShopJob } from '../../util/api';

// ================ Action types ================ //

export const FETCH_EXPLO_USER_TOKEN_REQUEST = 'app/AdminPage/FETCH_EXPLO_USER_TOKEN_REQUEST';
export const FETCH_EXPLO_USER_TOKEN_SUCCESS = 'app/AdminPage/FETCH_EXPLO_USER_TOKEN_SUCCESS';
export const FETCH_EXPLO_USER_TOKEN_ERROR = 'app/AdminPage/FETCH_EXPLO_USER_TOKEN_ERROR';

export const CREATE_REPORTS_REQUEST = 'app/AdminPage/CREATE_REPORTS_REQUEST';
export const CREATE_REPORTS_SUCCESS = 'app/AdminPage/CREATE_REPORTS_SUCCESS';
export const CREATE_REPORTS_ERROR = 'app/AdminPage/CREATE_REPORTS_ERROR';

interface FetchExploUserTokenRequest {
  type: typeof FETCH_EXPLO_USER_TOKEN_REQUEST;
}

interface FetchExploUserTokenSuccess {
  type: typeof FETCH_EXPLO_USER_TOKEN_SUCCESS;
  token: string;
}

interface FetchExploUserTokenError {
  type: typeof FETCH_EXPLO_USER_TOKEN_ERROR;
  error: any;
}

interface CreateReportsRequest {
  type: typeof CREATE_REPORTS_REQUEST;
}

interface CreateReportsSuccess {
  type: typeof CREATE_REPORTS_SUCCESS;
}

interface CreateReportsError {
  type: typeof CREATE_REPORTS_ERROR;
  error: any;
}

type AdminPageActionType =
  | FetchExploUserTokenRequest
  | FetchExploUserTokenSuccess
  | FetchExploUserTokenError
  | CreateReportsRequest
  | CreateReportsSuccess
  | CreateReportsError;

export interface AdminPageState {
  fetchExploTokenStatus: RequestStatus;
  exploGroupToken: string;
  fetchExploTokenError: any | null;
  createReportsStatus: RequestStatus;
  createReportsError: any | null;
}

const initialState: AdminPageState = {
  fetchExploTokenStatus: RequestStatus.Ready,
  exploGroupToken: '',
  fetchExploTokenError: null,
  createReportsStatus: RequestStatus.Ready,
  createReportsError: null,
};

export default function AdminPageReducer(
  state: AdminPageState = initialState,
  action: AdminPageActionType
): AdminPageState {
  switch (action.type) {
    case FETCH_EXPLO_USER_TOKEN_REQUEST: {
      return {
        ...state,
        fetchExploTokenStatus: RequestStatus.Pending,
        exploGroupToken: '',
        fetchExploTokenError: null,
      };
    }
    case FETCH_EXPLO_USER_TOKEN_SUCCESS: {
      return {
        ...state,
        exploGroupToken: action.token,
        fetchExploTokenStatus: RequestStatus.Success,
      };
    }
    case FETCH_EXPLO_USER_TOKEN_ERROR: {
      return {
        ...state,
        fetchExploTokenStatus: RequestStatus.Error,
        fetchExploTokenError: action.error,
      };
    }
    case CREATE_REPORTS_REQUEST: {
      return {
        ...state,
        createReportsStatus: RequestStatus.Pending,
        createReportsError: null,
      };
    }
    case CREATE_REPORTS_SUCCESS: {
      return {
        ...state,
        createReportsStatus: RequestStatus.Success,
      };
    }
    case CREATE_REPORTS_ERROR: {
      return {
        ...state,
        createReportsStatus: RequestStatus.Error,
        createReportsError: action.error,
      };
    }
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const fetchExploUserTokenRequest = () => ({
  type: FETCH_EXPLO_USER_TOKEN_REQUEST,
});

export const fetchExploUserTokenSuccess = (token: string) => ({
  type: FETCH_EXPLO_USER_TOKEN_SUCCESS,
  token,
});

export const fetchExploUserTokenError = (error: any) => ({
  type: FETCH_EXPLO_USER_TOKEN_ERROR,
  error,
});

export const createReportsRequest = () => ({ type: CREATE_REPORTS_REQUEST });

export const createReportsSuccess = () => ({ type: CREATE_REPORTS_SUCCESS });

export const createReportsError = (error: any) => ({
  type: CREATE_REPORTS_ERROR,
  error,
});

// =========== Thunks ========== //

export const fetchExploUserToken =
  (params: any) => async (dispatch: ThunkDispatch<any, any, any>) => {
    const { shopId } = params;
    const headers = {
      'Content-Type': 'application/json',
      'Explo-Authorization': `Token ${process.env.REACT_APP_EXPLO_TOKEN}`,
    };
    return axios
      .post(
        'https://api.explo.co/embed/create_user_group/',
        {
          user_group_id: shopId,
        },
        {
          headers,
        }
      )
      .then((response) => {
        dispatch(fetchExploUserTokenSuccess(response.data.user_group.token));
      })
      .catch((error) => {
        const e = error.error || storableError(error);
        dispatch(fetchExploUserTokenError(e));
        const tokenError = new Error('Failed to fetch Explo token');
        log.error(error, 'fetch-explo-token-error', { params, error: tokenError });
      });
  };

export const createReports =
  (startDate: Date, endDate: Date) =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => any) => {
    const rootState = getState();
    const { treetId } = rootState.initial;

    dispatch(createReportsRequest());

    try {
      const response = await queueGenerateReportsForShopJob({
        treetId,
        startDate,
        endDate,
      });
      dispatch(createReportsSuccess());
      return response;
    } catch (e) {
      log.error(e, 'create-reports-error', { treetId, startDate, endDate });
      dispatch(createReportsError(storableError(e)));
      throw e;
    }
  };
