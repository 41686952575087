// TODO (TREET-1876): Convert shopifyHelpers to tsx (shopifyHelpers2.tsx)
import { get, isEmpty, startCase, uniq } from 'lodash';
import { CHOOSE_STOCK_IMAGES_OPTIONS, PRODUCT_DISPLAY_OPTIONS } from '../shopConfig/config';
import { hasIntersection } from './utilityFunctions';

export const SHOPIFY_GRAPHQL_CLIENT_NAME = 'shopify';

export const SHOPIFY_GRAPHQL_CONTEXT = {
  context: { clientName: SHOPIFY_GRAPHQL_CLIENT_NAME },
};

export function getCategoriesFromProduct(product) {
  return product.tags;
}

export function filterDefaultShopifyOptions(options, shopId = '') {
  return options?.filter(
    (optionConfig) =>
      // Filter out the 'Default Title' option that shopify puts on all products since it's not actually relevant
      // Also filter out currencyCountry for Ophelia & Indigo since we don't want that to be user facing
      !(
        (optionConfig.name === 'currencyCountry' && shopId === 'opheliaandindigo') ||
        (optionConfig.name === 'Title' && optionConfig.values?.[0] === 'Default Title')
      )
  );
}

export function getSizeFromProduct() {}

export function getCategory(categories, product) {
  const { tags, productType } = product;
  const tagsSanitized = tags.map((tag) => tag.toLowerCase());

  // Some brands only have 1 category (like unhide)
  if (!categories) {
    return 'UNKNOWN';
  }

  const category = categories.find((categoryConfig) => {
    const categoryTags = categoryConfig.tags.map((tag) => tag.toLowerCase());
    return (
      hasIntersection(tagsSanitized, categoryTags) ||
      categoryTags.some((categoryTag) => productType.toLowerCase().includes(categoryTag))
    );
  });
  return category?.key || 'UNKNOWN';
}

export function getCategories(categories, product) {
  const { tags, productType } = product;
  const tagsSanitized = tags.map((tag) => tag.toLowerCase());
  // Some brands only have 1 category (like unhide)
  if (!categories) {
    return [];
  }

  const filteredCategories = categories.filter((categoryConfig) => {
    const categoryTags = categoryConfig.tags.map((tag) => tag.toLowerCase());
    return (
      categoryTags.some((categoryTag) => productType.toLowerCase().includes(categoryTag)) ||
      hasIntersection(tagsSanitized, categoryTags)
    );
  });

  return filteredCategories.map((category) => category.key);
}

/**
 * This is used to get how to format product item names. Note that this is used for both areas like
 * listing cards, but also used to show the products in an order during the listing flow.
 * @param {*} product The Shopify Product
 * @param {string} displayOption The display option type
 * @param {*} variant The Shopify Variant (optional)
 */
export function getProductDisplayTitle(product, displayOption, variant = {}) {
  let title;
  switch (displayOption) {
    case PRODUCT_DISPLAY_OPTIONS.TITLE:
      title = product?.title;
      break;
    case PRODUCT_DISPLAY_OPTIONS.COCLICO: {
      const colorTag = product?.tags?.find((tag) => tag.startsWith('colour-'));
      const color = colorTag?.split('colour-')?.[1];
      title = color ? `${product?.title} - ${color}` : product?.title;
      break;
    }
    case PRODUCT_DISPLAY_OPTIONS.ROUGH_AND_TUMBLE: {
      // Original product title format: `Custom ${bag name} - with price ${bag price}`
      const sanitizedProductTitle = product.title.split('-')[0];
      title = sanitizedProductTitle;
      break;
    }
    case PRODUCT_DISPLAY_OPTIONS.DOPPLE: {
      const vendor = product?.vendor;
      title = vendor ? `${vendor} - ${product?.title}` : product?.title;
      break;
    }
    case PRODUCT_DISPLAY_OPTIONS.TEEKI_BOUTIQUE: {
      // Handles "*Final Sale", "* Waitlist", etc cases.
      title = product?.title?.replace(/\*.*\b/g, '');
      break;
    }
    case PRODUCT_DISPLAY_OPTIONS.LAUDE_THE_LABEL: {
      // Removes "Sample x - "
      title = product?.title?.replace(/^Sample.*?- /, '');
      break;
    }
    case PRODUCT_DISPLAY_OPTIONS.KPOP_EXCHANGE: {
      const optionsToInclude = [
        'Style',
        'Item',
        'Character',
        'Group',
        'Version',
        'Option',
        'Member',
      ];
      const styleName = variant?.selectedOptions
        ?.filter((option) => optionsToInclude.includes(option.name))
        ?.map((filteredOption) => filteredOption.value);
      title = !isEmpty(styleName) ? `${product?.title} - ${styleName.join(' ')}` : product?.title;
      break;
    }
    case PRODUCT_DISPLAY_OPTIONS.ACE_AND_JIG: {
      // Replaces sample sale info in titles like: "sample sale | duster calliope | XS-XXL"
      const regex = /sample sale \| (.*) \| .*/;
      title = product?.title?.replace(regex, '$1');
      break;
    }
    default:
      title = product?.title;
  }

  return title?.trim();
}

const getDefaultStockImages = (shopifyProduct, shopifyProductVariant) => {
  const variantFeaturedImage = shopifyProductVariant?.image;
  const shopifyProductFeaturedImage = shopifyProduct?.featuredImage;
  const featuredImage = variantFeaturedImage || shopifyProductFeaturedImage;
  const imageSrcs =
    shopifyProduct?.images?.edges
      ?.filter((i) => {
        if (featuredImage) {
          // Filter out image if it duplicates the featuredImage (i.e. the product image IDs match).
          return featuredImage.id !== i.node?.id;
        }
        // Pass through all images if the featuredImage is unknown.
        return true;
      })
      .map((i) => i.node?.originalSrc) || [];

  // Make sure the featured image is first
  return [featuredImage?.originalSrc, ...imageSrcs];
};
/**
 * This logic decides which stock images to show on the frontend.
 * Keep core logic in sync with server/utils/shopify.ts.
 *
 * @param {*} shopifyProduct
 * @param {*} shopifyProductVariant
 * @param {*} stockImageOption
 */
export const getStockImages = (shopifyProduct, shopifyProductVariant, chooseStockImagesOption) => {
  let stockImages = [];
  const variantFeaturedImageSrc = shopifyProductVariant?.image?.originalSrc;

  // Translated from Unhide's product variant image logic
  if (chooseStockImagesOption === CHOOSE_STOCK_IMAGES_OPTIONS.UNHIDE) {
    const isVariantImage = (altText) => {
      const imgVariants = altText ? altText.split('~') : [];
      if (imgVariants.length === 1) {
        return imgVariants[0] === get(shopifyProductVariant, 'selectedOptions[0].value');
      }
      if (imgVariants.length === 2) {
        return (
          imgVariants[0] === get(shopifyProductVariant, 'selectedOptions[0].value') &&
          imgVariants[1] === get(shopifyProductVariant, 'selectedOptions[1].value')
        );
      }
      if (imgVariants.length === 3) {
        return (
          imgVariants[0] === get(shopifyProductVariant, 'selectedOptions[0].value') &&
          imgVariants[1] === get(shopifyProductVariant, 'selectedOptions[1].value') &&
          imgVariants[2] === get(shopifyProductVariant, 'selectedOptions[2].value')
        );
      }
      return false;
    };

    const images =
      shopifyProduct?.images?.edges
        ?.filter((i) => isVariantImage(i.node?.altText))
        .map((i) => i.node?.originalSrc) || [];

    stockImages = [variantFeaturedImageSrc, ...images];
  } else if (chooseStockImagesOption === CHOOSE_STOCK_IMAGES_OPTIONS.ETTITUDE) {
    // Ettitude puts the color in the alt text
    const images = shopifyProduct?.images?.edges || [];
    const color = shopifyProductVariant?.selectedOptions?.find(
      (option) => option.name === 'Color'
    )?.value;

    const filteredImageSrcs =
      images
        .filter((image) => image?.node?.altText?.startsWith(color))
        .map((i) => i.node?.originalSrc) || [];

    if (filteredImageSrcs.length !== 0) {
      stockImages = [variantFeaturedImageSrc, ...filteredImageSrcs];
    } else {
      stockImages = getDefaultStockImages(shopifyProduct, shopifyProductVariant);
    }
  } else if (chooseStockImagesOption === CHOOSE_STOCK_IMAGES_OPTIONS.ANIAN) {
    // Anian puts the color in the alt text
    const images = shopifyProduct?.images?.edges || [];
    const color = shopifyProductVariant?.selectedOptions?.find(
      (option) => option.name === 'Color'
    )?.value;

    const filteredImageSrcs =
      images
        .filter((image) => image?.node?.altText?.includes(color))
        .map((i) => i.node?.originalSrc) || [];

    if (filteredImageSrcs.length !== 0) {
      stockImages = [variantFeaturedImageSrc, ...filteredImageSrcs];
    } else {
      stockImages = getDefaultStockImages(shopifyProduct, shopifyProductVariant);
    }
  } else if (chooseStockImagesOption === CHOOSE_STOCK_IMAGES_OPTIONS.PORTLAND_LEATHER) {
    // Base Portland Leather stock photo selection off alt text.
    const color =
      shopifyProductVariant?.selectedOptions?.find((option) => option.name === 'Color')?.value ??
      'null';

    const images = shopifyProduct?.images?.edges || [];
    const filteredImageSrcs =
      images
        .filter((image) => {
          const altText = image.node?.altText;
          return (
            altText &&
            (altText.startsWith(`${color}*`) || altText.startsWith(`All Color: ${color}`))
          );
        })
        .map((i) => i.node?.originalSrc) || [];

    if (filteredImageSrcs.length !== 0) {
      stockImages = [variantFeaturedImageSrc, ...filteredImageSrcs];
    } else {
      stockImages = getDefaultStockImages(shopifyProduct, shopifyProductVariant);
    }
  } else {
    // Default
    stockImages = getDefaultStockImages(shopifyProduct, shopifyProductVariant);
  }

  // Make sure no duplicates and no undefined
  const filteredImages = uniq(stockImages.filter((i) => i));

  return filteredImages;
};

export const formatVariantOptionForDisplay = (option) =>
  startCase(option)
    .replace(':', '') // remove dangling :
    .split(/(\d+)/) // splits by text/numbers (ex: Color1 => ['Color', '1']) - Dopple specific
    .join(' ')
    .trim();

export const buildAdditionalProductQueryFromTagsAndProductTypes = (
  additionalProductQuery,
  blockedTags = [],
  blockedProductTypes = [],
  allowedTags = []
) => {
  const additionalProductQueryArray = [];
  if (additionalProductQuery !== '') {
    additionalProductQueryArray.push(additionalProductQuery);
  }

  for (let i = 0; i < blockedTags.length; i++) {
    let blockedTag = blockedTags[i];
    if (blockedTag === '') {
      blockedTag = '""';
    }
    additionalProductQueryArray.push(`-tag:"${blockedTag}"`);
  }
  for (let i = 0; i < blockedProductTypes.length; i++) {
    let blockedProductType = blockedProductTypes[i];
    if (blockedProductType === '') {
      blockedProductType = '""';
    }
    additionalProductQueryArray.push(`-product_type:"${blockedProductType}"`);
  }
  for (let i = 0; i < allowedTags.length; i++) {
    const allowedTag = allowedTags[i];
    additionalProductQueryArray.push(`tag:"${allowedTag}"`);
  }
  const newAdditionalProductQuery = `(${additionalProductQueryArray.join(' AND ')})`;

  return newAdditionalProductQuery;
};
