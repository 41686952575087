import axios from 'axios';
import { FEATURED_LISTING_IMAGE_TYPE } from '../../shopConfig/config';
import { ListingWithImages, OwnListingWithImages } from '../../types/sharetribe/listing';
import { Product, ShopifyProductVariant } from '../../types/shopify/product';
import { Feature, isFeatureEnabled } from '../featureFlags';
import { getStockImages } from '../shopifyHelpers';
import { getUploadcareImagePreviewUrl, ImageSource, isUploadcareImage } from '../uploadcare';
import { UploadcareFile } from '../../types/uploadcare/file';

interface ShouldFeatureListingStockPhotoInputs {
  firstStockImageSrc?: string;
  showStockImages: boolean;
  featuredListingImageType: string;
  firstSellerImageSrc: string | null;
}

export interface ListingImageParams {
  treetId: string;
  imageSource: ImageSource;
  shopifyProductVariant?: ShopifyProductVariant;
  shopifyProduct?: Product;
  uploadcareFeaturedImageSrc?: string;
  listingFirstImageSrc?: string;
  stockPhotoUrls?: string[];
  uploadcareFeaturedImage?: any[];
  listingFirstImage?: any;
}

export interface GetFeaturedImageInputs {
  showStockImages: boolean;
  featuredListingImageType: string;
  chooseStockImagesOption: string;
  listing?: ListingWithImages | OwnListingWithImages;
  listingImageParams?: ListingImageParams;
  imageRatio?: number;
}

interface AddSmartResizeOperationToUploadCareImageInputs {
  src: string;
  width?: number;
  imageRatio: number;
}

interface MaybeSmartResizeParams {
  src: string;
  treetId: string;
  imageRatio?: number;
}

export enum ListingImageType {
  Stock = 'STOCK',
  UserUploaded = 'USER_UPLOADED',
}

export const getStockImagesForListing = (
  listing:
    | ListingWithImages
    | OwnListingWithImages
    | { attributes: Partial<OwnListingWithImages['attributes']> },
  chooseStockImagesOption: string
): string[] => {
  const { stockPhotoUrls, shopifyProduct, shopifyProductVariant } =
    listing.attributes.publicData || {};

  if (!stockPhotoUrls) {
    return getStockImages(shopifyProduct, shopifyProductVariant, chooseStockImagesOption);
  }

  return stockPhotoUrls;
};

export const shouldFeatureListingStockPhoto = (params: ShouldFeatureListingStockPhotoInputs) => {
  // Show stock photo as featured listing image if it exists and the relevant configs are present,
  // or if there are no user uploaded photos (i.e. for Brand Direct items).
  const { firstStockImageSrc, showStockImages, featuredListingImageType, firstSellerImageSrc } =
    params;
  return (
    firstStockImageSrc &&
    showStockImages &&
    (featuredListingImageType === FEATURED_LISTING_IMAGE_TYPE.STOCK || !firstSellerImageSrc)
  );
};

export const getFirstSellerImageSrc = (
  shopId: string,
  imageSource?: ImageSource,
  uploadcareImageUrl?: string,
  listingImageUrl?: string
): string | null => {
  const isUploadcareEnabled =
    isFeatureEnabled(Feature.ImagesAPI, shopId) && imageSource === ImageSource.Uploadcare;
  return (
    (isUploadcareEnabled ? getUploadcareImagePreviewUrl(uploadcareImageUrl) : listingImageUrl) ||
    null
  );
};

export const getFeaturedListingImageParams = (
  listing?: ListingWithImages | OwnListingWithImages
): ListingImageParams | null => {
  if (!listing) return null;

  const {
    shopifyProduct,
    shopifyProductVariant,
    imageSource,
    images: uploadcareImages,
    domain: treetId,
    stockPhotoUrls,
  } = listing.attributes?.publicData || {};

  return {
    treetId,
    imageSource,
    shopifyProductVariant,
    shopifyProduct,
    uploadcareFeaturedImage: uploadcareImages?.[0],
    uploadcareFeaturedImageSrc: uploadcareImages?.[0]?.cdnUrl,
    listingFirstImage: listing.images?.[0],
    listingFirstImageSrc: listing.images?.[0]?.attributes?.variants?.default?.url,
    stockPhotoUrls,
  };
};

export const getFeaturedListingImageHelper = (params: GetFeaturedImageInputs) => {
  const {
    showStockImages,
    featuredListingImageType,
    chooseStockImagesOption,
    listing,
    listingImageParams,
  } = params;

  if (!listing && !listingImageParams) {
    return {};
  }

  const {
    shopifyProduct,
    shopifyProductVariant,
    imageSource,
    uploadcareFeaturedImageSrc,
    listingFirstImageSrc,
    treetId,
    listingFirstImage,
    uploadcareFeaturedImage,
    stockPhotoUrls,
  } = listingImageParams || getFeaturedListingImageParams(listing) || {};

  if (!treetId) {
    return {};
  }

  const firstStockImageSrc = stockPhotoUrls
    ? stockPhotoUrls[0]
    : getStockImages(shopifyProduct, shopifyProductVariant, chooseStockImagesOption)?.[0];

  const firstSellerImageSrc = getFirstSellerImageSrc(
    treetId,
    imageSource,
    uploadcareFeaturedImageSrc,
    listingFirstImageSrc
  );

  const shouldShowStockImage = shouldFeatureListingStockPhoto({
    firstStockImageSrc,
    showStockImages,
    featuredListingImageType,
    firstSellerImageSrc,
  });

  const isUploadcareEnabled =
    isFeatureEnabled(Feature.ImagesAPI, treetId) && imageSource === ImageSource.Uploadcare;

  const firstSellerImage = isUploadcareEnabled ? uploadcareFeaturedImage : listingFirstImage;

  return {
    firstSellerImage,
    firstStockImageSrc,
    firstSellerImageSrc,
    shouldShowStockImage,
    isUploadcareEnabled,
  };
};

export const isImageValid = (src: string) =>
  axios
    .get(src)
    .then(() => true)
    .catch(() => false);

export const getValidImages = async (imageSrcs: string[]) => {
  const validImgs = await Promise.all(
    imageSrcs.map(async (src) => {
      if (await isImageValid(src)) {
        return src;
      }
      return undefined;
    })
  );

  return validImgs.filter((img) => !!img) as string[];
};

export const getUploadcareImagesForListing = (
  listing:
    | ListingWithImages
    | OwnListingWithImages
    | { attributes: Partial<OwnListingWithImages['attributes']> }
) => {
  const uploadCareImages = listing?.attributes?.publicData?.images;
  return uploadCareImages.map((image: UploadcareFile) =>
    getUploadcareImagePreviewUrl(image.cdnUrl)
  );
};

export const getSharetribeImagesForListing = (listing: ListingWithImages | OwnListingWithImages) =>
  listing?.images?.map((image) => {
    const imageUrl =
      image?.attributes?.variants['scaled-medium']?.url ||
      image?.attributes?.variants?.landscape?.url ||
      image?.attributes?.variants?.default?.url;

    return imageUrl;
  });

export const addSmartResizeOperationToUploadcareImageUrl = ({
  src,
  width = 400, // default image width to 400 to to preserve better image quality for larger screen size
  imageRatio, // height over width
}: AddSmartResizeOperationToUploadCareImageInputs) => {
  const height = Math.round(width * imageRatio);

  return `${src}-/smart_resize/${width}x${height}/`;
};

export const maybeSmartResizeImage = ({ src, treetId, imageRatio }: MaybeSmartResizeParams) => {
  const isUploadcareSmartResize = isFeatureEnabled(Feature.UploadcareSmartResize, treetId);
  if (!isUploadcareSmartResize || !isUploadcareImage(src) || typeof imageRatio !== 'number') {
    return src;
  }
  return addSmartResizeOperationToUploadcareImageUrl({ src, imageRatio });
};
