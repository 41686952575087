import classNames from 'classnames';
import React, { FC } from 'react';
import { IconAdmin, IconAdminFilled, IconButton } from '..';
import { useIconColor } from '../../hooks/useIconColor';
import css from './AdminButton.module.css';

interface AdminButtonProps {
  isSelected: boolean;
  className?: string;
  iconClassName?: string;
  onClick?: () => void;
}

const AdminButton: FC<AdminButtonProps> = (props: AdminButtonProps) => {
  const { className, iconClassName, isSelected, onClick } = props;

  const { iconHoverColor } = useIconColor();
  const classes = classNames(css.root, className);

  let icon, hoverIcon;
  if (isSelected) {
    icon = <IconAdminFilled className={iconClassName} />;
    hoverIcon = <IconAdminFilled className={iconClassName} color={iconHoverColor} />;
  } else {
    icon = <IconAdmin className={iconClassName} />;
    hoverIcon = <IconAdmin className={iconClassName} color={iconHoverColor} />;
  }

  return <IconButton className={classes} icon={icon} hoverIcon={hoverIcon} onClick={onClick} />;
};

export default AdminButton;
