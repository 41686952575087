import React, { FC } from 'react';
import classNames from 'classnames';
import IconSocialMediaFacebook from './IconSocialMediaFacebook';
import IconSocialMediaInstagram from './IconSocialMediaInstagram';
import IconSocialMediaPinterest from './IconSocialMediaPinterest';
import IconSocialMediaTiktok from './IconSocialMediaTiktok';

import css from './IconSocialMedia.module.css';

export enum IconSocialMediaType {
  Facebook = 'FACEBOOK',
  Instagram = 'INSTAGRAM',
  Pinterest = 'PINTEREST',
  Tiktok = 'TIKTOK',
}

interface IconSocialMediaProps {
  type: IconSocialMediaType;
  rootClassName?: string;
  className?: string;
}

const IconSocialMedia: FC<IconSocialMediaProps> = (props: IconSocialMediaProps) => {
  const { rootClassName, className, type } = props;
  const classes = classNames(rootClassName || css.root, className);

  switch (type) {
    case IconSocialMediaType.Facebook: {
      return <IconSocialMediaFacebook className={classes} />;
    }
    case IconSocialMediaType.Instagram: {
      return <IconSocialMediaInstagram className={classes} />;
    }
    case IconSocialMediaType.Pinterest: {
      return <IconSocialMediaPinterest className={classes} />;
    }
    case IconSocialMediaType.Tiktok: {
      return <IconSocialMediaTiktok className={classes} />;
    }
    default:
      return null;
  }
};

export default IconSocialMedia;
