import React, { FC, ReactNode } from 'react';
import { Box } from '@material-ui/core';
import { useIconColor } from '../../hooks/useIconColor';

interface IconWithCountProps {
  icon: ReactNode;
  count?: number;
}

const IconWithCount: FC<IconWithCountProps> = (props: IconWithCountProps) => {
  const { icon, count } = props;

  const { iconColor } = useIconColor();

  return (
    <Box display="flex" alignItems="center" position="relative">
      {icon}
      {/* Use a static font here instead of brand's fonts */}
      <b
        style={{
          color: '#fff',
          fontSize: '9px',
          fontFamily: 'sofiapro, sans-serif',
          position: 'absolute',
          top: '-8px',
          right: '-8px',
          borderRadius: '50%',
          width: '16px',
          height: '16px',
          lineHeight: '15px', // Manually inspected
          background: iconColor,
        }}
      >
        {count}
      </b>
    </Box>
  );
};

export default IconWithCount;
