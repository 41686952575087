/* eslint-disable import/prefer-default-export */
import {
  BuyerInfo,
  FooterLogo,
  SellerInfo,
  TradeInInfoType,
} from '../../types/shopConfig/shopConfigV2';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import * as custom from '../filters/marketplace-custom-config';

export enum CreditPayoutOption {
  DiscountCode = 'DISCOUNT_CODE',
  GiftCard = 'GIFT_CARD',
  RiseAIGiftCard = 'RISE_AI_GIFT_CARD',
  DoppleGiftCard = 'DOPPLE_GIFT_CARD',
  BigCommerceGiftCard = 'BIGCOMMERCE_GIFT_CARD',
  YotpoLoyaltyPoints = 'YOTPO_LOYALTY_POINTS',
  TeamworkCommerceGiftCard = 'TEAMWORK_COMMERCE_GIFT_CARD',
  TeamworkCommerceGiftCardOldApi = 'TEAMWORK_COMMERCE_GIFT_CARD_OLD_API',
}

export const isGiftCardOption = (creditPayoutOption: CreditPayoutOption) =>
  [
    CreditPayoutOption.GiftCard,
    CreditPayoutOption.DoppleGiftCard,
    CreditPayoutOption.RiseAIGiftCard,
    CreditPayoutOption.BigCommerceGiftCard,
    CreditPayoutOption.TeamworkCommerceGiftCard,
    CreditPayoutOption.TeamworkCommerceGiftCardOldApi,
  ].includes(creditPayoutOption);

export const getDefaultBuyerSellerInfo = (
  creditPayoutPercentage: number,
  cashPayoutPercentage: number
) => ({
  [BuyerInfo.Browse]: {
    title: 'Browse',
    description: 'Browse our official collection of secondhand items.',
  },
  [BuyerInfo.Buy]: {
    title: 'Buy',
    description: 'Purchase from verified sellers across the country.',
  },
  [BuyerInfo.Verify]: {
    title: 'Verify',
    description: 'Verify items when they arrive to ensure quality is as advertised.',
  },
  [SellerInfo.PostIt]: {
    title: 'Post It (U.S. Only)',
    description:
      'Take a few pics, answer some questions, and submit for review. Please note this is currently only available for U.S. sellers.',
  },
  [SellerInfo.ShipIt]: {
    title: 'Ship It',
    description: 'After it’s sold, we’ll email you a prepaid shipping label to ship it.',
  },
  [SellerInfo.GetPaid]: {
    title: creditPayoutPercentage ? 'Bigger Payouts' : 'Get Paid',
    description: creditPayoutPercentage
      ? `Get up to a ${(creditPayoutPercentage * 100).toFixed(
          0
        )}% store credit payout when you sell.`
      : `Get up to a ${(cashPayoutPercentage * 100).toFixed(0)}% cash payout when you sell.`,
  },
});

export const getBrandDirectOnlyBuyerSellerInfo = (sellerName: string) => ({
  ...getDefaultBuyerSellerInfo(0, 0),
  [BuyerInfo.Buy]: {
    title: 'Buy',
    description: `Purchase verified items directly from ${sellerName}.`,
  },
});

export const getDefaultTradeInInfo = (shopName: string) => ({
  [TradeInInfoType.Submit]: {
    title: 'Submit',
    description: 'Answer a few easy questions about the quality of your item(s).',
  },
  [TradeInInfoType.Ship]: {
    title: 'Ship',
    description: `Print out a pre-paid shipping label and send your items to ${shopName}, where we’ll inspect each item.`,
  },
  [TradeInInfoType.Earn]: {
    title: 'Earn',
    description: `Once your items are approved, receive your credit to ${shopName}.`,
  },
});

export const getDefaultShopConfig = () => ({
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getSizeFilterConfig({ sizes: ['XS', 'S', 'M', 'L'] }),
  ],
  footerLogos: [FooterLogo.Brand, FooterLogo.Treet],
  additionalPayoutSettings: {},
  enabledAboutTreetPages: [],
});

export const getDefaultTradeInShopConfig = () => ({
  // Used for dispute/verification flow
  tradeInCreditPayoutOptions: [],
  additionalPayoutSettings: {},
  // TODO: pull out addTradeInItemCardCopy into a contentful field. This could in the future be
  // based off of trade-in payout option settings, rather than a copy field.
  addTradeInItemCardCopy: 'Earn brand credit for accepted pre-loved items',
});

export const getDefaultBrandDirectOnlyShopConfig = (sellerName: string) => ({
  buyerSellerInfo: getBrandDirectOnlyBuyerSellerInfo(sellerName),
});
