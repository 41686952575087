// Followed these steps: https://www.builder.io/c/docs/integrate-section-building
import { builder, BuilderComponent } from '@builder.io/react';
import React, { FC, useEffect, useState } from 'react';
import { BuilderResponse } from '../../types/builder/builder';
import { BuilderSections } from '../../util/builder';
import { getUrlSearchParams } from '../../util/urlHelpers';

builder.init(process.env.REACT_APP_BUILDER_IO_API_KEY as string);

interface BuilderSectionProps {
  sectionType: BuilderSections;
  sectionId: string;
  // See https://www.builder.io/c/docs/custom-actions#passing-data-down-with-code-builder-component-code
  sectionData?: Record<string, any>; // Data props
  sectionContext?: Record<string, any>; // Function props
  fetchDataFromBuilder?: (builderResponse: BuilderResponse['data']) => any;
}

const BuilderSection: FC<BuilderSectionProps> = (props: BuilderSectionProps) => {
  const { sectionType, sectionId, sectionData, sectionContext, fetchDataFromBuilder } = props;
  const [builderContentJson, setBuilderContentJson] = useState();

  useEffect(() => {
    // Preview = true will render unpublished content.
    const searchParams = getUrlSearchParams();
    const isPreview = searchParams.get('preview');

    const handleBuilderResponse = (builderResponse: any) => {
      setBuilderContentJson(builderResponse);
      if (fetchDataFromBuilder) {
        fetchDataFromBuilder(builderResponse?.data);
      }
    };

    builder
      .get(sectionType, {
        ...(isPreview && { options: { includeUnpublished: true } }),
        query: { id: sectionId },
      })
      .promise()
      .then(handleBuilderResponse);
  }, []);

  return builderContentJson ? (
    <BuilderComponent
      model={sectionType}
      content={builderContentJson}
      data={sectionData}
      context={sectionContext}
    />
  ) : null;
};

export default BuilderSection;
