import React, { FC } from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { Box, withStyles } from '@material-ui/core';
import TypographyWrapper, { TypographyWeight } from '../TypographyWrapper/TypographyWrapper';
import { defaultTreetStyles } from '../../shopConfig/config';
import { useIsMobile } from '../../hooks/useIsMobile';
import css from './ListingFeedbackModal.module.css';

interface ListingFeedbackToggleButtonProps {
  structuredText: { text: string; bold?: boolean }[];
  Icon: React.FC<{ className?: string }>;
  value: string;
  'aria-label': string;
  className?: string;
  style?: { [style: string]: string };
}

const inputStyles = {
  root: {
    '&.MuiToggleButton-root': {
      borderRadius: '30px',
      marginTop: '2px',
      marginBottom: '2px',
      borderWidth: '2px',
      borderColor: 'inherit',
      justifyContent: 'left',
      width: 'fit-content',
      paddingLeft: '8px',
      '@media (min-width: 768px)': {
        marginTop: '6px',
        marginBottom: '6px',
        padding: '4px 14px 4px 10px',
      },
    },
    '&:hover': {
      '&.Mui-selected': {
        color: defaultTreetStyles.gray10,
        backgroundColor: defaultTreetStyles.gray80,
      },
    },
    '&.Mui-selected': {
      color: defaultTreetStyles.gray10,
      backgroundColor: defaultTreetStyles.gray80,
      '& .MuiToggleButton-label p': {
        color: `${defaultTreetStyles.gray10}`,
      },
      '& .MuiToggleButton-label path': {
        fill: `${defaultTreetStyles.gray10}`,
      },
    },
  },
};

const CategoryToggleButton = withStyles(inputStyles)(ToggleButton);

const ListingFeedbackToggleButton: FC<ListingFeedbackToggleButtonProps> = (props) => {
  const { Icon, structuredText, value, ...rest } = props;
  const isMobile = useIsMobile();
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <CategoryToggleButton value={value} {...rest}>
      <Box display="flex" pr={isMobile ? 0 : 1}>
        <Icon className={isMobile ? css.smallIcon : ''} />
      </Box>
      <Box display="flex" flexDirection="row">
        {structuredText.map(({ text, bold }) => (
          <TypographyWrapper
            key={text}
            variant="body1"
            typographyOverrides={{
              align: 'left',
              display: 'inline',
              style: {
                ...(isMobile && { fontSize: '13px' }),
              },
            }}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...(bold ? { weight: TypographyWeight.Bold } : {})}
          >
            {text}&nbsp;
          </TypographyWrapper>
        ))}
      </Box>
    </CategoryToggleButton>
  );
};

export default ListingFeedbackToggleButton;
