import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { BuilderSections } from '../../util/builder';

const spellSizes = ['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL', '3XL', 'OS'];

export const spellShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({
      sizes: spellSizes,
    }),
  ],
  builderConfig: {
    sections: {
      [BuilderSections.LandingPageContent]: '7bf5addc71044c368c93c0d0945b569b',
      [BuilderSections.ListingCardDetails]: '559825b2dbcd46efab9616fba3bec35a',
    },
  },
};
