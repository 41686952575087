export enum SearchNavBarSections {
  Search = 'SEARCH',
  Filter = 'FILTER',
  Sort = 'SORT',
}

export enum SearchFilterType {
  // Treet V1
  SelectSingleFilter = 'SelectSingleFilter',
  SelectMultipleFilter = 'SelectMultipleFilter',
  PriceFilter = 'PriceFilter',
  KeywordFilter = 'KeywordFilter',

  // Treet V2
  Checkbox = 'CHECKBOX',
  Sort = 'SORT',
}

export enum FilterId {
  Category = 'category',
  Color = 'color',
  Condition = 'condition',
  Inseam = 'inseam',
  IsBrandDirect = 'isBrandDirect',
  Keyword = 'keyword',
  Price = 'price',
  Size = 'size',
  Style = 'style',
  Gender = 'gender',
  Decade = 'decade',
}

export enum FrenzySortOptions {
  Newest = 'newest',
  BestMatch = 'best match',
  LowestPrice = 'price asc',
  HighestPrice = 'price desc',
}

export type GenericFilterOption = {
  key: string;
  label: string;
  shortLabel?: string;
  subLabel?: string;
  description?: string;
  disabled?: boolean;
  count?: number; // Used to indicate # of results for this filter option value
};

export type SortOption = {
  key: string;
  label: string;
  longLabel?: string;
  disabled?: boolean;
};

export type Filter = {
  id: FilterId;
  label: string;
  type: string;
  group: string;
  queryParamNames: string[];
  config: {
    options?: GenericFilterOption[];
    searchMode?: string;
    shouldHideNoResultOptions?: boolean;
  };
  searchNavBarSection?: string;
  shouldUseTreetOptions?: boolean;
};

export type SortConfig = {
  id?: string;
  type?: SearchFilterType;
  searchNavBarSection?: SearchNavBarSections;
  active: boolean;
  queryParamName: string;
  relevanceKey?: string;
  conflictingFilters: string[];
  options: GenericFilterOption[];
};
