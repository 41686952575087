import Decimal from 'decimal.js';
import fetch from 'node-fetch';
import { types as sdkTypes, transit } from './sdkLoader';
import { apolloClient } from '../apollo';
import sharetribeConfig from '../sharetribeConfig';
import { apiBaseUrl, getSubdomain, isProd } from './envHelpers';
import {
  ShopByHostnameDocument,
  ShopByTreetIdDocument,
} from '../types/apollo/generated/types.generated';

// Only use this method if you have to – normally you can access this info through the store.
export const getShop = async () => {
  if (isProd) {
    const hostname = (window.location?.hostname || '').replace('www.', '');
    const queryShopByHostnameResponse = await apolloClient.query({
      query: ShopByHostnameDocument,
      variables: { hostname },
    });

    const shop = queryShopByHostnameResponse.data?.shopByHostname;
    if (!shop) throw new Error(`Cannot find shop for hostname ${hostname}`);

    return shop;
  }

  const treetId = getSubdomain();
  const queryShopByTreetIdResponse = await apolloClient.query({
    query: ShopByTreetIdDocument,
    variables: { treetId },
  });

  const shop = queryShopByTreetIdResponse.data?.shop;
  if (!shop) throw new Error(`Cannot find shop for treetId ${treetId}`);

  return { ...shop, treetId };
};

// Application type handlers for JS SDK.
//
// NOTE: keep in sync with `typeHandlers` in `server/api-util/sdk.js`
export const typeHandlers = [
  // Use Decimal type instead of SDK's BigDecimal.
  {
    type: sdkTypes.BigDecimal,
    customType: Decimal,
    writer: (v) => new sdkTypes.BigDecimal(v.toString()),
    reader: (v) => new Decimal(v.value),
  },
];

const serialize = (data) =>
  transit.write(data, { typeHandlers, verbose: sharetribeConfig.sdk.transitVerbose });

const deserialize = (str) => transit.read(str, { typeHandlers });

const handleResponse = async (res, throwError = true) => {
  if (res.status >= 400) {
    const { status, statusText } = res;
    const contentType = res.headers.get('content-type');

    let errorResponse;
    if (contentType && contentType.indexOf('application/json') !== -1) {
      errorResponse = await res.json();
    } else {
      errorResponse = await res.text();
    }

    const defaultErrorMessage = errorResponse?.error || 'Local API request failed';
    const e = new Error(defaultErrorMessage);
    e.apiResponse = errorResponse;
    e.status = status;
    e.statusText = statusText;
    if (throwError) {
      throw e;
    }
    // Silently fail
    return null;
  }

  const contentTypeHeader = res.headers.get('Content-Type');
  const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;
  if (contentType === 'application/transit+json') {
    return res.text().then(deserialize);
  }
  if (contentType === 'application/json') {
    return res.json();
  }
  return res.text();
};

// TODO (sonia-y|TREET-1566): Refactor this to be a hook so that we can inject the origin in instead of passing it in
const post = (path, body, origin, throwError = true) => {
  const url = `${apiBaseUrl(origin)}${path}`;
  const options = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/transit+json',
    },
    body: serialize(body),
  };
  return fetch(url, options).then(async (res) => handleResponse(res, throwError));
};

const put = (path, body, origin) => {
  const url = `${apiBaseUrl(origin)}${path}`;
  const options = {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/transit+json',
    },
    body: serialize(body),
  };
  return fetch(url, options).then(async (res) => handleResponse(res));
};

const get = (path, origin) => {
  const url = `${apiBaseUrl(origin)}${path}`;
  const options = {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/transit+json',
    },
  };
  return fetch(url, options).then(async (res) => handleResponse(res));
};

// Fetch order line items from the local API endpoint.
// See `server/api/order-line-items.ts` to see what data should
// be sent in the body.
export const orderLineItems = (body) => post('/api/order-line-items', body);

// Handle initiating checkout from the local API endpoint.
//
// See `server/api/initiate-checkout.ts` to see what data should
// be sent in the body.
export const initiateCheckout = (body) => post('/api/initiate-checkout', body);

// Handle submitting checkout from the local API endpoint.
//
// See `server/api/submit-checkout.ts` to see what data should
// be sent in the body.
export const submitCheckout = (body) => post('/api/submit-checkout', body);

// Handle finishing checkout from the local API endpoint.
//
// See `server/api/finish-checkout.ts` to see what data should
// be sent in the body.
export const finishCheckout = (body) => post('/api/finish-checkout', body);

// Create a trade in order
//
// See `server/api/create-trade-in-order.ts` to see what data should
// be sent in the body.
export const createTradeInOrder = (body) => post('/api/create-trade-in-order', body);

// Create shipping label.
//
// See `server/api/checkout/create-shipping-label-for-bundle.ts` to see what data should be
// sent in the body.
export const createShippingLabelForBundle = (body) =>
  post('/api/create-shipping-label-for-bundle', body);

// Update the availability of listings.
//
// See `server/api/update-listings-availability.ts` to see what data should be
// sent in the body.
export const updateListingsAvailability = (body) => post('/api/update-listings-availability', body);

// Fetches listings with open listings first followed by sold.
//
// See `server/api/fetch-listings.ts` to see what data should be sent
// in the body. The listings must be fetched from the backend because we use
// the integration SDK to fetch listings that have been sold.
export const fetchListings = (body) => post('/api/fetch-listings', body);

// Fetches sold listings.
//
// See `server/api/fetch-sold-listings.ts` to see what data should be sent
// in the body. The sold listings must be fetched from the backend because we use
// the integration SDK to fetch sold listings.
export const fetchSoldListings = (body, origin) => post('/api/fetch-sold-listings', body, origin);

// Redeem voucher from Voucherify.
//
// See `server/api/redeem-voucher.ts` to see what data should be
// sent in the body.
export const redeemVoucher = (body) => post('/api/redeem-voucher', body);

// Validate voucher from Voucherify.
//
// See `server/api/validate-voucher.ts` to see what data should be
// sent in the body.
export const validateVoucher = (body) => post('/api/validate-voucher', body);

// Retrieves search results
export const search = (body, origin) => post('/api/search', body, origin);

// Retrieves recommended listings
export const recommendedListings = (body) => post('/api/recommended-listings', body);

// Validate address is well-formed through Shippo
// See `server/api/validate-address.ts` to see what data should be
// sent in the body.
export const validateAddress = (body) => post('/api/validate-address', body);

// Update transaction's ship by date and transition to extend-shipping
export const updateTransactionShipping = (body) => post('/api/update-transaction-shipping', body);

// Adds users to a Sendgrid contact list
export const subscribeEmail = (body) => post('/api/add-contact', body);

// Gets all groups from which the current user has been unsubscribed to
// on Sendgrid
export const getSuppressions = () => get('/api/suppressions');

// Removes users from a Sendgrid unsubscribe group
export const deleteSuppression = (body) => post('/api/delete-suppression', body);

// Upload data to airtable
export const uploadToAirtable = (body) => post('/api/upload-to-airtable', body);

// Used to auto-approve listing types
export const approveListings = (body) => post('/api/approve-listing', body);

// Used to set inventory for a listing
export const setInventory = (body) => post('/api/set-inventory', body);

// Updates credit payout for a bundle
export const updateBundlePayout = (body) => post('/api/update-bundle-payout', body);

// Queues abandoned bag email
export const queueAbandonedBagEmail = (body) => post('/api/queue-abandoned-bag-email', body);

// Queues updated favorite listings email
export const queueUpdateFavoriteListings = (body) =>
  post('/api/queue-update-favorites-email', body);

// Queues buyer dispute email
export const queueBuyerDisputeEmail = (body) => post('/api/queue-buyer-dispute-email', body);

// Updates shipping status for bundle transactions
export const updateShippingStatus = (body) => post('/api/update-shipping-status', body);

// Fetch own listings
export const fetchOwnListings = (body) => post('/api/fetch-own-listings', body);

// Update listing. Only accepts specific listing params.
export const updateListing = (body) => post('/api/update-listing', body);

// Create stripe account.
export const createStripeAccount = (body) => post('/api/create-stripe-account', body);

// Retrieve stripe account.
export const retrieveStripeAccount = (body) => post('/api/retrieve-stripe-account', body);

// Update stripe account.
export const updateStripeAccount = (body) => post('/api/update-stripe-account', body);

// Create a stripe account link that Treet can redirect the user
// to for them to finish the Connect Onboarding flow.
// See https://stripe.com/docs/api/account_links/create
export const createStripeAccountLink = (body) => post('/api/create-stripe-account-link', body);

export const fetchConversionRates = () => get('/api/fetch-conversion-rates');

export const getShopifyOrders = (body) => post('/api/shopify/fetch-orders', body);

export const fetchShopifyProductVariantWithProductById = (body) =>
  post('/api/shopify/fetch-product-variant-with-product-by-id', body);

// Gets the compressed draft listing saved server side in redis.
export const getDraftListing = (listingFlowId) => get(`/api/draft-listings/${listingFlowId}`);

// Updates the compressed draft listing values saved server side in redis.
export const updateDraftListing = (listingFlowId, body) =>
  put(`/api/draft-listings/${listingFlowId}`, body);

// Enqueues a job to find and remove invalid images for a listing.
export const removeInvalidImages = (listingId) => get(`/api/remove-invalid-images/${listingId}`);

// Tracks a heap event server-side. Currently only works for authed user events.
export const trackHeapEvent = (body) => post(`/api/track-heap-event`, body, undefined, false);

// Queues job to create reports for given shop.
export const queueGenerateReportsForShopJob = (body) =>
  post('/api/queue-generate-reports-for-shop-job', body);

// Fetches all Shopify variants
export const fetchShopifyVariantByQuery = (body) =>
  post(`/api/shopify/fetch-variant-by-query`, body);

// Fetches Shopify product variant by the variant ID.
export const fetchVariantById = (body) => post('/api/shopify/fetch-variant-by-id', body);

// Fetches Shopify order by order number.
export const fetchOrderByOrderNumber = (body) =>
  post(`/api/shopify/fetch-order-by-order-number`, body);

// Fetches Shopify product variants by product ID.
export const fetchVariantsByProductId = (body) =>
  post('/api/shopify/fetch-variants-by-product-id', body);

// Fetches Shopify product by ID.
export const fetchProductById = (body) => post('/api/shopify/fetch-product-by-id', body);

// Fetches Shopify products by tag.
export const fetchProductsByTag = (body) => post('/api/shopify/fetch-products-by-tag', body);
