/* eslint-disable import/prefer-default-export */
import { Conditions, getConditionsFilterConfig } from '../filters/condition';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getSizeFilterConfigFromOptions } from '../filters/size';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { BuilderSections } from '../../util/builder';

const galialahavSizeOptions = [
  { key: 'Custom', label: 'Custom' },
  { key: 'US 0', label: 'US 0 (EU 32)' },
  { key: 'US 2', label: 'US 2 (EU 34)' },
  { key: 'US 4', label: 'US 4 (EU 36)' },
  { key: 'US 6', label: 'US 6 (EU 38)' },
  { key: 'US 8', label: 'US 8 (EU 40)' },
  { key: 'US 10', label: 'US 10 (EU 42)' },
  { key: 'US 12', label: 'US 12 (EU 44)' },
  { key: 'US 14', label: 'US 14 (EU 46)' },
  { key: 'US 16', label: 'US 16 (EU 48)' },
  { key: 'US 18', label: 'US 18 (EU 50)' },
];

const galialahavConditionOptions = [
  {
    key: Conditions.NewWithoutTags,
    label: 'New & Unworn',
    description:
      'Your item is new and has never been worn. When applicable, you are required to submit a photo of the hang tags. Recommended resale price: 85% of the original retail price.',
  },
  {
    key: Conditions.Excellent,
    label: 'Excellent',
    description:
      'Your item has only been lightly used and has been extremely well maintained. Example: Worn once for a wedding or event, no stains or visible tearing, might have been cleaned and steamed according to GL instructions. Recommended resale price: 70% of the original retail price.',
  },
  {
    key: Conditions.Good,
    label: 'Good',
    description:
      'You’ve worn this item and it’s well-maintained. There may be some quirks like a small stain, a button missing, or a loose thread, but it’s overall in good shape. You will need to take pictures of any quirks the item has. Recommended resale price: 55% of the original retail price.',
  },
];

export const galialahavShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getBrandDirectFilterConfig(),
    getConditionsFilterConfig({ options: galialahavConditionOptions }),
    getSizeFilterConfigFromOptions({ options: galialahavSizeOptions }),
  ],
  builderConfig: {
    sections: {
      [BuilderSections.LandingPageContent]: '5b2fdd67cdfb4ea68c6ca7023facde6e',
    },
  },
};
