import { Box } from '@material-ui/core';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { IconSecure, TypographyWrapper } from '../../components';
import CheckoutError from '../../containers/CheckoutPage/CheckoutError';
import PaymentFields from './PaymentFields';
import ShippingAddressFields from './ShippingAddressFields';
import { CountryCodeAndName } from '../../util/countryCodes';
import StripeExpressCheckoutSection from './StripeExpressCheckoutSection';
import { CheckoutPageState } from '../../containers/CheckoutPage/CheckoutPage.duck';
import IncompleteAddressErrorMessage from './IncompleteAddressErrorMessage';

interface StripePaymentFormDesktopProps {
  defaultPaymentMethod: any;
  error?: string;
  form: any;
  handleChangePaymentMethod: (paymentMethod: string) => void;
  handleShippingCountryChange: (value: string) => void;
  handleStripeElementRef: (el: any) => void;
  allowedShipToCountryCodes: CountryCodeAndName[];
  isCardValueValid: boolean;
  isLoading: boolean;
  isSubmitting: boolean;
  selectedPaymentMethod: string;
  shouldEnableSeel: boolean;
  isMultiSellerCheckout: boolean;
  isStripeExpressCheckoutEnabled: boolean;
  handleExpressCheckoutButtonClick: () => void;
  onConfirm: (values: any) => void;
  onShippingAddressChange: (values: any) => void;
  shouldDisplayIncompleteAddressErrorMessage: boolean;
}

const StripePaymentFormDesktop: FC<StripePaymentFormDesktopProps> = (
  props: StripePaymentFormDesktopProps
) => {
  const {
    defaultPaymentMethod,
    error,
    form,
    handleChangePaymentMethod,
    handleShippingCountryChange,
    handleStripeElementRef,
    allowedShipToCountryCodes,
    isCardValueValid,
    isLoading,
    isSubmitting,
    selectedPaymentMethod,
    shouldEnableSeel,
    isMultiSellerCheckout,
    isStripeExpressCheckoutEnabled,
    handleExpressCheckoutButtonClick,
    onConfirm,
    onShippingAddressChange,
    shouldDisplayIncompleteAddressErrorMessage,
  } = props;

  const { isExpressCheckoutError } = useSelector<any>(
    (state) => state.CheckoutPage
  ) as CheckoutPageState;

  return (
    <>
      {!isMultiSellerCheckout && (
        <StripeExpressCheckoutSection
          isStripeExpressCheckoutEnabled={isStripeExpressCheckoutEnabled}
          handleExpressCheckoutButtonClick={handleExpressCheckoutButtonClick}
          onConfirm={onConfirm}
          onShippingAddressChange={onShippingAddressChange}
          isMultiSellerCheckout={false}
        />
      )}
      <TypographyWrapper variant="h2">Ship To</TypographyWrapper>
      {shouldDisplayIncompleteAddressErrorMessage && <IncompleteAddressErrorMessage />}
      {(isMultiSellerCheckout || (!isMultiSellerCheckout && !isExpressCheckoutError)) && (
        <CheckoutError />
      )}
      <ShippingAddressFields
        form={form}
        allowedShipToCountryCodes={allowedShipToCountryCodes}
        handleShippingCountryChange={handleShippingCountryChange}
      />
      {isMultiSellerCheckout && (
        <StripeExpressCheckoutSection
          isStripeExpressCheckoutEnabled={isStripeExpressCheckoutEnabled}
          handleExpressCheckoutButtonClick={handleExpressCheckoutButtonClick}
          onConfirm={onConfirm}
          onShippingAddressChange={onShippingAddressChange}
          isMultiSellerCheckout
        />
      )}
      {shouldEnableSeel && (
        <Box py={1}>
          <TypographyWrapper variant="body1">
            <div id="seel-ra-widget-root" />
          </TypographyWrapper>
        </Box>
      )}
      <Box display="flex" flexDirection="row">
        <TypographyWrapper variant="h2">Checkout With Card</TypographyWrapper>
        <Box mx={1}>
          <IconSecure width="22" height="22" />
        </Box>
      </Box>
      <PaymentFields
        defaultPaymentMethod={defaultPaymentMethod}
        error={error}
        handleChangePaymentMethod={handleChangePaymentMethod}
        handleStripeElementRef={handleStripeElementRef}
        isCardValueValid={isCardValueValid}
        isLoading={isLoading}
        isSubmitting={isSubmitting}
        selectedPaymentMethod={selectedPaymentMethod}
      />
    </>
  );
};

export default StripePaymentFormDesktop;
