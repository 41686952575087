import {
  Box,
  ClickAwayListener,
  Divider,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import capitalize from 'lodash/capitalize';
import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import isNil from 'lodash/isNil';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { defaultTreetStyles } from '../../shopConfig/config';
import { getOwnListingsById } from '../../containers/ManageSalesPage/ManageSalesPage.duck';
import {
  BundleStatus,
  CoreAddress,
  CountryCode,
  Participant,
  useCreditCodeByBundleId,
} from '../../types/apollo/generated/types.generated';
import { BundleInfo } from '../../types/models/bundle';
import { OwnListing } from '../../types/sharetribe/listing';
import { getBundleDisplayNumber, getBundleStatusLabel } from '../../util/bundles';
import { PayoutOptions } from '../../util/constants';
import BundleActions from '../BundleActions/BundleActions';
import { getPayoutValues } from '../ManageBundlesContainer/manageBundlesContainerUtils';
import {
  ConditionalWrapper,
  ExternalLink,
  IconArrowRight,
  IconPurchaseProtection,
  InlineTextButton,
  NamedLink,
  TypographyWrapper,
} from '..';
import { useBrandCountryConfig } from '../../hooks/useCountryConfig';
import { useCurrentUserPermissions } from '../../hooks/useUserPermissions';
import { getFulfillmentMethod } from '../../util/helpers';
import { useShopConfigV2 } from '../../hooks/shopConfig';
import { FulfillmentMethod } from '../../types/shopConfig/shopConfigV2';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { formatDollarAmountAsCurrency } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import EditShippingAddressModal from './EditShippingAddressModal';
import { CurrentUser } from '../../types/sharetribe/currentUser';
import {
  buildClaimUrl,
  SEEL_RETURN_ASSURANCE,
  SEEL_RETURN_WINDOW_DAYS,
} from '../../util/seelHelpers';
import { pluralize } from '../../util/strings';
import css from './BundlePanel.module.css';
import { calculateLineItemTotal } from '../../util/lineItems';
import { CheckoutLineItem } from '../../types/models/lineItem';
import { TypographyWeight } from '../TypographyWrapper/TypographyWrapper';
import { ModalType } from '../../ducks/modal.duck';
import { useActiveModal } from '../../hooks/useActiveModal';
import { Uuid } from '../../types/sharetribe/uuid';
import {
  itemHasBeenCanceled,
  itemHasBeenDelivered,
  itemHasBeenVerified,
} from '../../util/bundleItem';

const { UUID } = sdkTypes;

interface ReturnInsuranceButtonProps {
  bundle: BundleInfo;
}

const ReturnInsuranceButton: FC<ReturnInsuranceButtonProps> = (props) => {
  const { bundle } = props;

  const currentUser = useSelector<any>((state) => state.user.currentUser) as
    | CurrentUser
    | undefined;

  const deliveredAt = bundle.fulfillment?.deliveredAt;
  const returnByDate = deliveredAt && moment.utc(deliveredAt).add(SEEL_RETURN_WINDOW_DAYS, 'd');
  const currentDate = moment.utc();
  const numDaysRemaining =
    (returnByDate && Math.max(0, Math.round(returnByDate.diff(currentDate, 'days', true)))) || 0;
  const hasReturnExpired = !returnByDate || currentDate >= returnByDate;
  const bundleId = bundle.id;

  return (
    <ConditionalWrapper
      condition={!hasReturnExpired}
      wrapper={(children) => (
        <ExternalLink
          href={buildClaimUrl(currentUser?.attributes.email || '', bundleId)}
          className={css.externalLink}
        >
          {children}
        </ExternalLink>
      )}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        color={hasReturnExpired ? defaultTreetStyles.gray40 : 'inherit'}
      >
        <Box display="flex" alignItems="center">
          <IconPurchaseProtection color="inherit" />
          <Box display="flex" flexDirection="column" ml={{ xs: 1, sm: 2 }}>
            <TypographyWrapper
              variant="body1"
              typographyOverrides={{
                style: {
                  fontWeight: 'bold',
                  color: 'inherit',
                },
              }}
            >
              Protected by {SEEL_RETURN_ASSURANCE}
            </TypographyWrapper>
            <TypographyWrapper
              variant="body1"
              typographyOverrides={{
                style: { color: 'inherit' },
              }}
            >
              {`${pluralize('day', numDaysRemaining, true)} remaining`}
            </TypographyWrapper>
            <Typography
              variant="body1"
              style={{
                color: 'inherit',
              }}
              className={css.link}
            >
              Start A Return
            </Typography>
          </Box>
        </Box>
        <IconArrowRight color="inherit" />
      </Box>
    </ConditionalWrapper>
  );
};

interface ShippingAddressProps {
  isBrandDirectBundle: boolean;
  shippingAddress: CoreAddress;
  shipFromCountry: CountryCode;
  onEditShippingAddressSubmit: (values: any, addressId: string, onSuccess: () => void) => void;
}

const ShippingAddress: FC<ShippingAddressProps> = (props) => {
  const { shippingAddress, shipFromCountry, onEditShippingAddressSubmit, isBrandDirectBundle } =
    props;

  const { isModalOpen, openModal, closeModal } = useActiveModal(ModalType.ShippingAddress);

  return (
    <Box p={2}>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box display="flex" justifyContent="center" flexShrink={0}>
          <Typography variant="body1" display="inline" style={{ fontWeight: 'bold' }}>
            Shipping Address:&nbsp;
          </Typography>
          <Typography variant="body1" display="inline">
            {shippingAddress.addressLine1}...
          </Typography>
        </Box>
        <Box pl={2} display="flex" justifyContent="center">
          <InlineTextButton onClick={openModal}>
            <Typography
              variant="body2"
              display="inline"
              style={{
                color: defaultTreetStyles.gray40,
                textDecoration: 'underline',
                textUnderlineOffset: '2px',
              }}
            >
              Edit
            </Typography>
          </InlineTextButton>
        </Box>
      </Box>
      <EditShippingAddressModal
        isBrandDirectBundle={isBrandDirectBundle}
        isOpen={isModalOpen}
        shippingAddress={shippingAddress}
        shipFromCountry={shipFromCountry}
        onClose={closeModal}
        onSave={(values: any, addressId: string) =>
          onEditShippingAddressSubmit(values, addressId, closeModal)
        }
      />
    </Box>
  );
};

interface OtherBundlesTooltipTitleProps {
  bundle: BundleInfo;
  otherBundlesInOrder: BundleInfo[];
  setIsOtherBundlesTooltipOpen: (a: boolean) => void;
}

const OtherBundlesTooltipTitle: FC<OtherBundlesTooltipTitleProps> = (props) => {
  const { bundle, otherBundlesInOrder, setIsOtherBundlesTooltipOpen } = props;

  const intl = useIntl();
  const { currencyConfig } = useBrandCountryConfig();

  const numOtherBundles = otherBundlesInOrder.length;

  const orderTotal =
    calculateLineItemTotal((bundle.order?.lineItems || []) as CheckoutLineItem[]) / 100;

  return (
    <Box px={1} py={3}>
      <TypographyWrapper variant="body1">
        This order was processed at the same time as other orders:
      </TypographyWrapper>
      <br />
      {otherBundlesInOrder.map((b) => (
        <div key={b.id} onClick={() => setIsOtherBundlesTooltipOpen(false)} className={css.link}>
          <NamedLink name="ManagePurchasePage" params={{ id: b.id }}>
            <TypographyWrapper variant="body1">#{getBundleDisplayNumber(b.id)}</TypographyWrapper>
          </NamedLink>
        </div>
      ))}
      <br />
      <TypographyWrapper variant="body1" typographyOverrides={{ display: 'inline' }}>
        {`Total across ${numOtherBundles + 1} orders: `}
      </TypographyWrapper>
      <TypographyWrapper
        variant="body1"
        typographyOverrides={{ display: 'inline' }}
        weight={TypographyWeight.Bold}
      >
        {intl.formatNumber(orderTotal, currencyConfig)}
      </TypographyWrapper>
    </Box>
  );
};

const OtherBundlesTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[3],
    fontSize: 11,
  },
}))(Tooltip);

interface OtherBundlesInOrderProps {
  bundle: BundleInfo;
  otherBundlesInOrder: BundleInfo[];
}

const OtherBundlesInOrder: FC<OtherBundlesInOrderProps> = (props) => {
  const { bundle, otherBundlesInOrder } = props;
  const [isOtherBundlesTooltipOpen, setIsOtherBundlesTooltipOpen] = useState(false);
  const numOtherBundles = otherBundlesInOrder.length;

  return (
    <ClickAwayListener onClickAway={() => setIsOtherBundlesTooltipOpen(false)}>
      <OtherBundlesTooltip
        interactive
        open={isOtherBundlesTooltipOpen}
        onClose={() => setIsOtherBundlesTooltipOpen(false)}
        PopperProps={{
          disablePortal: true,
        }}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={
          <OtherBundlesTooltipTitle
            bundle={bundle}
            otherBundlesInOrder={otherBundlesInOrder}
            setIsOtherBundlesTooltipOpen={setIsOtherBundlesTooltipOpen}
          />
        }
        placement="bottom"
      >
        <div onClick={() => setIsOtherBundlesTooltipOpen((prev) => !prev)} className={css.link}>
          <TypographyWrapper variant="body1">
            {`Processed with ${numOtherBundles} other
              ${pluralize('order', numOtherBundles)}`}
          </TypographyWrapper>
        </div>
      </OtherBundlesTooltip>
    </ClickAwayListener>
  );
};

interface BundlePanelProps {
  bundle: BundleInfo;
  otherBundlesInSameOrder?: BundleInfo[];
  participant: Participant;
  customerName?: string;
  onVerifyClick?: (bundle: BundleInfo) => void;
  onDisputeClick?: (bundle: BundleInfo) => void;
  onGetPaidClick?: (bundle: BundleInfo) => void;
  onMarkAsFulfilledClick?: (bundle: BundleInfo) => void;
  onMarkAsDeliveredClick?: (bundle: BundleInfo) => void;
  onEditShippingAddressSubmit?: (values: any, addressId: string, onSuccess: () => void) => void;
}

const BundlePanel: FC<BundlePanelProps> = (props: BundlePanelProps) => {
  const {
    bundle,
    otherBundlesInSameOrder,
    customerName,
    participant,
    onVerifyClick,
    onDisputeClick,
    onGetPaidClick,
    onMarkAsFulfilledClick,
    onMarkAsDeliveredClick,
    onEditShippingAddressSubmit,
  } = props;

  const intl = useIntl();
  const { isBrand } = useCurrentUserPermissions();
  const { defaultBrandFulfillmentMethod, additionalPayoutSettings = {} } = useShopConfigV2();
  const brandCountryConfig = useBrandCountryConfig();
  const { creditCodeHelperText } = additionalPayoutSettings;
  const { bundleItems } = bundle;

  // Check if one of the listings has chosen payout option already
  const firstOwnListingId = {
    uuid: bundleItems[0]?.listing.sharetribeListingId,
  } as Uuid;
  const rootState = useSelector<any>((state) => state) as any;
  const ownListings = getOwnListingsById(rootState, [firstOwnListingId]) as OwnListing[];
  const payoutOption = ownListings?.[0]?.attributes?.privateData?.payoutOption as
    | PayoutOptions
    | undefined;

  const { data: creditCodeResponse } = useCreditCodeByBundleId({
    variables: {
      bundleId: bundle.id,
    },
    skip: participant !== Participant.Seller || payoutOption !== PayoutOptions.Credit,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const creditCode = creditCodeResponse?.bundle?.creditCode;

  const trackingNumber = bundle.fulfillment?.trackingNumber;
  const trackingUrl = bundle.fulfillment?.trackingURL;

  const returnTrackingNumber = bundle.returnFulfillment?.trackingNumber;
  const returnTrackingUrl = bundle.returnFulfillment?.trackingURL;

  const verifiedBundleItems = bundleItems.filter((bundleItem) => itemHasBeenVerified(bundleItem));

  const payoutValues = getPayoutValues(bundle, intl, brandCountryConfig, rootState);

  // Actual credit payout issued
  const issuedCredit =
    creditCode && formatDollarAmountAsCurrency(intl, creditCode.amount, creditCode.currency);

  const isBundleOpen = bundle.status === BundleStatus.Open;
  const listings = getListingsById(
    rootState,
    bundle.listingIds?.map((listingId) => new UUID(listingId)) || []
  );
  const fulfillmentMethod = getFulfillmentMethod(listings, defaultBrandFulfillmentMethod);
  const canEditShippingAddress =
    isBundleOpen && fulfillmentMethod === FulfillmentMethod.Treet && isNil(bundle.fulfillment);
  const bundleShipFromCountry = listings[0]?.attributes.publicData?.shipFromCountry;

  const areAllListingsBrandDirect = listings.every(
    (listing) => !!listing.attributes.publicData?.isBrandDirect
  );

  const isReturnInsurancePurchased = !!bundle.lineItems.find((li) => !!li.returnInsuranceLineItem);
  const areBundleItemsInReturnableState =
    bundleItems.every(
      (bundleItem) => itemHasBeenDelivered(bundleItem) || itemHasBeenCanceled(bundleItem)
    ) && bundleItems.some((bundleItem) => itemHasBeenDelivered(bundleItem));
  const canReturnItems = isReturnInsurancePurchased && areBundleItemsInReturnableState;

  const formattedCreditPayout = () => {
    // Payout values calculated based on transactions,
    // show these values until the payout has been issued & recorded.
    if (payoutValues.creditInBrandShopifyCurrency) {
      return ` ${payoutValues.credit} Credit (issued as ${
        issuedCredit || payoutValues.creditInBrandShopifyCurrency
      })`;
    }

    return ` ${issuedCredit || payoutValues.credit} Credit`;
  };

  const formattedProceeds = () => {
    if (payoutOption && payoutValues[payoutOption] && !isEmpty(verifiedBundleItems)) {
      return payoutOption === PayoutOptions.Credit
        ? formattedCreditPayout()
        : ` ${payoutValues[payoutOption]} ${capitalize(payoutOption)}`;
    }
    return null;
  };

  return (
    <div className={css.root}>
      {/* ORDER # */}
      <Box display="flex" justifyContent="space-between" p={2}>
        <h4>
          <TypographyWrapper variant="body1">
            <b>Order: </b>#{getBundleDisplayNumber(bundle.id)}
          </TypographyWrapper>
          {/* Processed with Other Bundles */}
          {!!otherBundlesInSameOrder?.length && (
            <OtherBundlesInOrder bundle={bundle} otherBundlesInOrder={otherBundlesInSameOrder} />
          )}
        </h4>
      </Box>
      <Divider />
      {/* STATUS */}
      <Box p={2}>
        <h4>
          <TypographyWrapper variant="body1">
            <b>Status: </b>
            {getBundleStatusLabel(participant, bundle)}
          </TypographyWrapper>
        </h4>
        <BundleActions
          bundle={bundle}
          participant={participant}
          onVerifyClick={onVerifyClick}
          onDisputeClick={onDisputeClick}
          onGetPaidClick={onGetPaidClick}
          onMarkAsFulfilledClick={onMarkAsFulfilledClick}
          onMarkAsDeliveredClick={onMarkAsDeliveredClick}
        />
      </Box>
      {/* RETURN INSURANCE */}
      {canReturnItems && participant === Participant.Buyer && (
        <>
          <Divider />
          <Box p={2}>
            <ReturnInsuranceButton bundle={bundle} />
          </Box>
        </>
      )}
      {canEditShippingAddress && !isNil(bundle.order) && onEditShippingAddressSubmit && (
        <>
          <Divider />
          <ShippingAddress
            isBrandDirectBundle={areAllListingsBrandDirect}
            shippingAddress={bundle.order.shippingAddress}
            shipFromCountry={bundleShipFromCountry}
            onEditShippingAddressSubmit={onEditShippingAddressSubmit}
          />
        </>
      )}
      {/* TRACKING NUMBER */}
      {trackingUrl && (
        <>
          <Divider />
          <Box p={2}>
            <h4>
              <TypographyWrapper variant="body1">
                <Box display={{ sm: 'flex' }}>
                  <b>Tracking Number:&nbsp;</b>
                  <a href={trackingUrl} target="_blank" rel="noopener noreferrer">
                    <Typography variant="body1" className={css.link}>
                      {trackingNumber}
                    </Typography>
                  </a>
                </Box>
              </TypographyWrapper>
            </h4>
          </Box>
        </>
      )}
      {returnTrackingUrl && bundle.status !== BundleStatus.Completed && (
        <>
          <Divider />
          <Box p={2}>
            <h4>
              <TypographyWrapper variant="body1">
                <b>Return Tracking Number:&nbsp;</b>
                <a href={returnTrackingUrl} target="_blank" rel="noopener noreferrer">
                  <Typography variant="body1" className={css.link}>
                    {returnTrackingNumber}
                  </Typography>
                </a>
              </TypographyWrapper>
            </h4>
          </Box>
        </>
      )}
      {/* CUSTOMER NAME FOR BRANDS */}
      {customerName && isBrand && (
        <>
          <Divider />
          <Box p={2}>
            <h4>
              <TypographyWrapper variant="body1">
                <b>Customer Name: </b>
                {customerName}
              </TypographyWrapper>
            </h4>
          </Box>
        </>
      )}
      {/* PAYOUT OPTION */}
      {formattedProceeds() && (
        <>
          <Divider />
          <Box p={2}>
            <h4>
              <TypographyWrapper variant="body1">
                <>
                  <b>Proceeds: </b>
                  {formattedProceeds()}
                </>
              </TypographyWrapper>
            </h4>
          </Box>
        </>
      )}
      {/* CREDIT CODE */}
      {!!creditCode?.code && participant === Participant.Seller && (
        <>
          <Divider />
          <Box p={2}>
            <h4>
              <TypographyWrapper variant="body1">
                <b>Credit Code: </b>
                {creditCode.code}
              </TypographyWrapper>
            </h4>
            {creditCodeHelperText && (
              <TypographyWrapper
                variant="body1"
                typographyOverrides={{ style: { fontSize: '12px', marginTop: '5px' } }}
              >
                <i>{creditCodeHelperText}</i>
              </TypographyWrapper>
            )}
          </Box>
        </>
      )}
      <Divider />
    </div>
  );
};

export default BundlePanel;
