/* eslint-disable import/prefer-default-export */
import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig, getDefaultTradeInShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { Conditions, getConditionsFilterConfig } from '../filters/condition';

const carilohaSizeOptions = [
  'XS',
  'S',
  'S/M',
  'M',
  'L',
  'L/XL',
  'XL',
  'XXL',
  'Crib',
  'Twin',
  'Twin XL',
  'Standard',
  'Full',
  'Full/Queen',
  'Queen',
  'King',
  'King/Cal King',
  'Cal King',
  'One Size',
  'Hand Towel',
  'Bath Towel',
  'Bath Sheet',
];

const carilohaConditions = [
  {
    key: Conditions.NewWithTags,
    label: 'New With Packaging',
    description:
      'Your item has never been used or worn and is still in the original packaging with hangtags.',
  },
  {
    key: Conditions.Excellent,
    label: 'Excellent Condition',
    description: 'Your item has only been lightly used and has been extremely well maintained.',
  },
  {
    key: Conditions.Good,
    label: 'Well Loved',
    description:
      'The item has been used frequently. It may have a small quirk like a button missing, or a loose thread.',
  },
];

export const carilohaShopConfig = {
  ...getDefaultShopConfig(),
  ...getDefaultTradeInShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig({ options: carilohaConditions }),
    getSizeFilterConfig({ sizes: carilohaSizeOptions }),
  ],
};
