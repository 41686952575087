import axiosOriginal from 'axios';
import adapter from 'axios/lib/adapters/xhr';
import { isEmpty } from 'lodash';
import { apiBaseUrl } from '../util/envHelpers';
import { storableError } from '../util/errors';
import { handle } from '../util/helpers';
import * as log from '../util/log';
import { getUrlSearchParams } from '../util/urlHelpers';

// Axios was having adapter not fonud issues during ssr call
// Reference: https://github.com/axios/axios/issues/2968
const axios = axiosOriginal.create({ adapter });

// ================ Action types ================ //

export const FETCH_SHOP_CONFIG_REQUEST = 'app/initial/FETCH_SHOP_CONFIG_REQUEST';
export const FETCH_SHOP_CONFIG_SUCCESS = 'app/initial/FETCH_SHOP_CONFIG_SUCCESS';
export const FETCH_SHOP_CONFIG_ERROR = 'app/initial/FETCH_SHOP_CONFIG_ERROR';

export const FETCH_GENERAL_SHOP_CONFIG_REQUEST = 'app/initial/FETCH_GENERAL_SHOP_CONFIG_REQUEST';
export const FETCH_GENERAL_SHOP_CONFIG_SUCCESS = 'app/initial/FETCH_GENERAL_SHOP_CONFIG_SUCCESS';
export const FETCH_GENERAL_SHOP_CONFIG_ERROR = 'app/initial/FETCH_GENERAL_SHOP_CONFIG_ERROR';

// ================ Reducer ================ //
const initialState = {
  treetId: null,
  shopId: null,
  canonicalRootUrl: null,
  origin: null,
  userAgent: null,
  defaultCurrency: null,
  primaryLocale: null,
  currencyConversionRates: null,
  shopConfig: null,
  fetchShopConfigInProgress: false,
  fetchShopConfigError: null,
  generalShopConfig: null,
  fetchGeneralShopConfigInProgress: false,
  fetchGeneralShopConfigError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_SHOP_CONFIG_REQUEST:
      return {
        ...state,
        shopConfig: null,
        fetchShopConfigError: null,
        fetchShopConfigInProgress: true,
      };
    case FETCH_SHOP_CONFIG_SUCCESS: {
      return {
        ...state,
        shopConfig: payload,
        fetchShopConfigInProgress: false,
      };
    }
    case FETCH_SHOP_CONFIG_ERROR:
      return { ...state, fetchShopConfigError: payload, fetchShopConfigInProgress: false };
    case FETCH_GENERAL_SHOP_CONFIG_REQUEST:
      return {
        ...state,
        generalShopConfig: null,
        fetchGeneralShopConfigError: null,
        fetchGeneralShopConfigInProgress: true,
      };
    case FETCH_GENERAL_SHOP_CONFIG_SUCCESS: {
      return {
        ...state,
        generalShopConfig: payload,
        fetchGeneralShopConfigInProgress: false,
      };
    }
    case FETCH_GENERAL_SHOP_CONFIG_ERROR:
      return {
        ...state,
        generalShopConfig: {}, // Setting as empty so we don't try to refetch
        fetchGeneralShopError: payload,
        fetchGeneralShopInProgress: false,
      };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const fetchShopConfigRequest = () => ({
  type: FETCH_SHOP_CONFIG_REQUEST,
});

export const fetchShopConfigSuccess = (payload) => ({
  type: FETCH_SHOP_CONFIG_SUCCESS,
  payload,
});

export const fetchShopConfigError = (payload) => ({
  type: FETCH_SHOP_CONFIG_ERROR,
  payload,
  error: true,
});

export const fetchGeneralShopConfigRequest = () => ({
  type: FETCH_GENERAL_SHOP_CONFIG_REQUEST,
});

export const fetchGeneralShopConfigSuccess = (payload) => ({
  type: FETCH_GENERAL_SHOP_CONFIG_SUCCESS,
  payload,
});

export const fetchGeneralShopConfigError = (payload) => ({
  type: FETCH_GENERAL_SHOP_CONFIG_ERROR,
  payload,
  error: true,
});

// ================ Helpers ================ //
const getAdditionalParamsString = (shouldShowPreview) => {
  const urlSearchParams = getUrlSearchParams();

  const isPreview = shouldShowPreview || urlSearchParams.has('preview');
  const shouldBustCache = urlSearchParams.has('bustCache');

  const additionalParams = new URLSearchParams({
    ...(isPreview ? { preview: true } : {}),
    ...(shouldBustCache ? { bustCache: true } : {}),
  }).toString();

  const additionalParamsString = isEmpty(additionalParams) ? '' : `?${additionalParams}`;
  return additionalParamsString;
};

// ================ Thunks ================ //
export const fetchShopConfig = (shouldShowPreview) => async (dispatch, getState) => {
  // Origin will be passed in if this is being called from a ssr context
  const { treetId, origin } = getState().initial;

  dispatch(fetchShopConfigRequest());

  const additionalParamsString = getAdditionalParamsString(shouldShowPreview);

  const [response, error] = await handle(
    axios.get(`${apiBaseUrl(origin)}/api/shopConfigs/${treetId}${additionalParamsString}`)
  );

  if (error) {
    error.message = `[Fetch Shop Config] ${error.message}`;
    console.error(error);
    const e = error.error || storableError(error);
    log.error(error, 'fetch-shop-config-from-server-failed', e);
    dispatch(fetchShopConfigError(e));
    throw error;
  }

  return dispatch(fetchShopConfigSuccess(response.data));
};

export const fetchGeneralShopConfig = (shouldShowPreview) => async (dispatch, getState) => {
  // Origin will be passed in if this is being called from a ssr context
  const { origin } = getState().initial;

  dispatch(fetchGeneralShopConfigRequest());

  const additionalParamsString = getAdditionalParamsString(shouldShowPreview);

  const [response, error] = await handle(
    axios.get(`${apiBaseUrl(origin)}/api/generalShopConfig/${additionalParamsString}`)
  );

  if (error) {
    error.message = `[Fetch General Shop Config] ${error.message}`;
    console.error(error);
    const e = error.error || storableError(error);
    log.error(error, 'fetch-general-shop-config-from-server-failed', e);
    dispatch(fetchGeneralShopConfigError(e));
    throw error;
  }

  const generalShopConfig = response.data?.data?.generalShopConfig || {};

  return dispatch(fetchGeneralShopConfigSuccess(generalShopConfig));
};

// ================ Selectors ================ //

export const getTreetId = (state) => {
  const { treetId } = state.initial;
  return treetId;
};
