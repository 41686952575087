import React from 'react';
import { useIconColor } from '../../../hooks/useIconColor';
import { CustomIconType, useCustomIcon } from '../../../hooks/useCustomIcon';
import CustomIcon from '../../CustomIcon/CustomIcon';

interface IconUserProps {
  className?: string;
  color?: string;
}

const IconUser: React.FC<IconUserProps> = (props) => {
  const { className, color } = props;

  const customUserIcon = useCustomIcon(CustomIconType.User);
  const { iconColor } = useIconColor();

  if (customUserIcon) {
    return <CustomIcon url={customUserIcon.icon.url} altText="user" className={className} />;
  }

  const fillColor = color || iconColor;

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        fill={fillColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 13C17.6569 13 19 11.6569 19 10C19 8.34315 17.6569 7 16 7C14.3431 7 13 8.34315 13 10C13 11.6569 14.3431 13 16 13ZM16 15C18.7614 15 21 12.7614 21 10C21 7.23858 18.7614 5 16 5C13.2386 5 11 7.23858 11 10C11 12.7614 13.2386 15 16 15Z"
      />

      <path
        fill={fillColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.12639 21.7126C8.95787 21.8693 8.95787 22.1307 9.12639 22.2874C10.944 23.9767 13.3544 25 16 25C18.6456 25 21.056 23.9767 22.8736 22.2874C23.0421 22.1307 23.0421 21.8693 22.8736 21.7126C21.056 20.0233 18.6456 19 16 19C13.3544 19 10.944 20.0233 9.12639 21.7126ZM7.76479 20.2477C6.74507 21.1955 6.74507 22.8045 7.76479 23.7523C9.93489 25.7693 12.8259 27 16 27C19.1741 27 22.0651 25.7693 24.2352 23.7523C25.2549 22.8045 25.2549 21.1955 24.2352 20.2477C22.0651 18.2307 19.1741 17 16 17C12.8259 17 9.93489 18.2307 7.76479 20.2477Z"
      />
    </svg>
  );
};

export default IconUser;
