/* eslint-disable import/prefer-default-export */
export enum BuilderSections {
  // Customizes LandingPageV2, space between the topbar and search results
  LandingPageContent = 'landing-page-content',
  // Customizes AboutPage hero section, space between the topbar and about navbar
  AboutPageHeroContent = 'about-page-hero-content',
  // Customizes About Page info tab content, space between the navbar in the middle of the page
  // and the footer
  AboutPageInfoTabContent = 'about-page-info-tab-content',
  // Customizes About Page navbar
  AboutPageNavbarContent = 'about-page-navbar-content',
  // Used to hackily get the data associated with this builder section and not actually to
  // render a configured component
  ListingCard = 'listing-card',
  // Customizes listing card details below the listing card image
  ListingCardDetails = 'listing-card-details',
  // Customizes ListingPage space below the topbar
  PDPContent = 'pdp-content',
  // Customizes ListingPage details section
  ListingPageTitleAndVariantDetailsContent = 'listing-page-title-and-variant-details-content',
  // Customizes footer at the bottom of the landing and about pages
  FooterContent = 'footer-content',
  // Customizes topbar for desktop
  TopbarContent = 'topbar-content',
  // Customizes topbar for mobile
  TopbarMobileContent = 'topbar-mobile-content',
}
