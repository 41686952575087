import React from 'react';
import PropTypes from 'prop-types';
import { DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import { Button } from '..';
import css from './VerifyModal.module.css';
import DialogWithCloseButton from '../DialogWithCloseButton/DialogWithCloseButton';

const VerifyModal = (props) => {
  const { open, onClose, onSubmit, inProgress } = props;

  return (
    <DialogWithCloseButton scroll="paper" open={open} onClose={onClose} titleText="Verify Item">
      <DialogContent dividers>
        <DialogContentText>
          <h4>
            By verifying this order, you are confirming the item you received is as described in the
            original post.
          </h4>
          <h4>
            Please note, if you do not verify the order within 72 hours, it will be automatically
            verified and the Seller will receive their funds.
          </h4>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          className={css.actionButton}
          disabled={inProgress}
          inProgress={inProgress}
          onClick={onSubmit}
        >
          Verify
        </Button>
      </DialogActions>
    </DialogWithCloseButton>
  );
};

VerifyModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  inProgress: PropTypes.bool,
};

export default VerifyModal;
